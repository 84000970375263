<template>
    <layout-default :action-header="false">
        <div class="home-page">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="title">
                            <h1>Actions and Project Tracker</h1>
                            <p>Select an option to start tracking of actions or projects</p>
                        </div>
                    </div>
                </div>

                <div class="main-options">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="option-container">
                                <h3>Actions</h3>
                                <p>Short term actions for several teams from several medical areas</p>

                                <router-link to="/actions" class="card-button">Go to actions</router-link>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="option-container">
                                <h3>Projects</h3>
                                <p>Long term projects for several teams from several medical areas</p>

                                <router-link to="/projects" class="card-button">Go to projects</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout-default>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import LayoutDefault from '../Layouts/LayoutDefault.vue';

@Component({
    name: 'home',
    components: {
        'layout-default': LayoutDefault
    }
})

export default class Home extends Vue {
    @Prop() myProp!: string;
}
</script>

<style lang="less">

.home-page {
    padding: 35px 0 0;

    .title {
        text-align: center;
        margin-bottom: 101px;

        h1 {
            font-weight: 600;
            font-size: 32px;
            line-height: 32px;
            color: #1055A1;
        }

        p {
            font-weight: 300;
            font-size: 20px;
            line-height: 20px;
            color: #6F8294;
        }
    }

    .option-container {
        padding: 80px 65px 105px;
        background: #F2F2F2;
        border-radius: 4px;
        text-align: center;

        h3 {
            font-weight: 600;
            font-size: 32px;
            line-height: 32px;
            color: #1055A1;
            margin-bottom: 15px;
        }

        p {
            font-size: 20px;
            line-height: 20px;
            color: #6F8294;
            margin-bottom: 48px;
            font-weight: 400;
        }

        .card-button {
            background: #54A4FC;
            border-radius: 5px;
            padding: 15px;
            text-align: center;
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            color: #fff;
            display: inline-block;

            &:hover {
                background: #1055a1;
            }
        }
    }
}

</style>

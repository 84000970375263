
import { BaseEntity } from '../Models/BaseEntity';
import { ReassignContactModel } from '../Models/Contact';
import ApiRequestor from './ApiRequestor';

export class EntityService {
    public static async Edit(endpoint: string, request: BaseEntity): Promise<any> {
        return await ApiRequestor.put(`Edit${endpoint}/${request.id}`, request);
    }

    public static async ReassignActionAndUnarchive(request: ReassignContactModel, actionId: string) {
        return await ApiRequestor.post(`ReassignResponsibleToActionAndUnarchive?id=${actionId}`, request);
    }

    public static async ReassignProjectAndUnarchive(request: ReassignContactModel, projectId: string) {
        return await ApiRequestor.post(`ReassignResponsibleToProjectAndUnarchive?id=${projectId}`, request);
    }

    public static async ReassignActions(request: ReassignContactModel, listId: string) {
        return await ApiRequestor.post(`ReassignResponsibleToActions?listId=${listId}`, request);
    }

    public static async ReassignProjects(request: ReassignContactModel, listId: string) {
        return await ApiRequestor.post(`ReassignResponsibleToProjects?listId=${listId}`, request);
    }
}

<template>
    <div class="multi-people-picker-container">
        <div style="font-weight:700;">{{title}}</div>
        <div class="members-container">
            <div class="picker" v-if="!readOnly">
                <people-picker :options="options" :user="user" @change-model="selectUser" :append-to-body="false"></people-picker>
            </div>
            <div class="people-list">
                <div v-for="item in users" class="person-item me-3">
                    <user-picture :user-id="item.id" :user-name="item.ownerName" :is-profile-image-uploaded="item.isOwnerImageUploaded"
                                  :user-type="item.type" :is-name-visible="true"></user-picture>
                    <button type="button" title="Clear Selected" aria-label="Clear Selected" v-if="!readOnly" class="person-item-delete ms-2 vs__clear" @click="deleteUser(item)"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path></svg></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { User } from '../../Models/User';
import PeoplePicker from './PeoplePicker.vue';
import UserPicture from '../UserPicture/UserPicture.vue';


@Component({
    name: 'multi-people-picker',
    template: '#multi-people-picker-id',
    components: {
        'people-picker': PeoplePicker,
        'user-picture': UserPicture,
    }
})

export default class MultiPeoplePicker extends Vue {
    @Prop({ default: (): User[] => [], type: Array }) options!: User[];
    @Prop({ default: (): User[] => [], type: Array }) users!: User[];
    @Prop({ default: 'Members', type: String }) title!: string;
    @Prop({ default: false, type: Boolean }) readOnly!: boolean;

    private user: User = {} as User;

    deleteUser(user: User) {
        this.$emit('delete-member', user);
    }

    confirmDeleteUser(user: User) {
        const index = this.users.indexOf(user);

        if (index > -1) {
            this.users.splice(index, 1);
        }
    }

    selectUser(user: User) {
        if (!user) return;

        if (user.id && this.users.find((t: User) => t.id == user.id) == null ) {
            this.users.push(user);
        }
        this.user = {} as User;
        this.$emit('update-members', this.users);
    }


    mounted(): void {
    }
}
</script>

<template>
    <div class="modal fade  viewItemModal  view-item-list-modal" id="viewItemModal" tabindex="-1"
         aria-hidden="true" data-bs-backdrop="static" ref="listModal">
        <div class="modal-dialog custom-xl-modal">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="title">
                        <h5 class="modal-title">View {{ pageTitle }}</h5>
                    </div>
                    <a href="#" ref="closeBtn" class="close-modal" data-bs-dismiss="modal" aria-label="Close">Close <i class="fas fa-times"></i></a>
                </div>
                <div class="modal-body">
                    <div class="add-item-form" style="display: block;">
                        <div class="row col-lg-12">
                            <div class="form-group" style="max-width: 400px;">
                                <label for="actionListName">Title</label>

                                <div class="display-item">
                                    {{record.name}}
                                </div>
                            </div>
                            <div class="form-group" style="max-width: 150px;">
                                <label for="">Color</label>
                                <div class="color-point-container">
                                    <div tabindex="-1" class="vue-swatches">
                                        <div class="vue-swatches__trigger__wrapper" style="cursor: default;">
                                            <div class="vue-swatches__trigger" style="width: 42px; height: 42px; border-radius: 50%;" :style="styleObject">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Owner -->
                            <div class="form-group" style="max-width: 350px;">
                                <label for="owner">Owner</label>
                                <div class="add-user">
                                    <user-picture :user-name="record.ownerName" :user-id="record.ownerId" :user-type="record.type"
                                                  :is-profile-image-uploaded="record.isOwnerImageUploaded" :is-name-visible="true"></user-picture>
                                </div>
                            </div>
                            <!-- is private -->
                            <div class="form-group" style="max-width: 150px" id="isArchivedContainerId">
                                <label for="isPrivate">Private List</label>
                                <div class="form-check form-switch">
                                    <input type="checkbox" class="form-check-input" v-model="record.isPrivate" name="isPrivate" id="isPrivate" data-toggle="toggle" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="row col-lg-12 form-group" style="display: block;" v-if="record.isPrivate">
                            <div class="add-user" style="display: block;">
                                <multi-people-picker :read-only="true" :users="record.members"></multi-people-picker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="modal-footer-left-container">
                    </div>
                    <div class="modal-footer-rigt-container">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

import VSwatches from 'vue-swatches';

import { BaseListEntity } from '../../Models/BaseListEntity';
import MultiPeoplePicker from '../PeoplePicker/MultiPeoplePicker.vue';
import UserPicture from '../UserPicture/UserPicture.vue';

@Component({
    name: 'view-list-modal',
    template: '#view-list-modal-id',
    components: {
        'v-swatches': VSwatches,
        'user-picture': UserPicture,
        'multi-people-picker': MultiPeoplePicker
    },
})

export default class ViewListModal extends Vue {
    @Prop({ default: '', type: String }) pageTitle!: string;
    @Prop() record!: BaseListEntity;


    get styleObject() {
        return {
            backgroundColor: '#' + this.record.colour
        };
    }

    mounted() {
        const me = this;
        $(<Element>this.$refs.listModal).on('hidden.bs.modal', function () {
            if (me.$route.query.id) {
                me.$router.push({ name: <string>me.$route.name }).catch((er) => er);
            }
        });
    }
}
</script>

<template>
    <layout-default :page-title="pageTitle" :tabs-in-header="false" :show-search="false">
        <div class="main-table-page">
            <div class="main">
                <div class="ajax-loading" v-if="loading"></div>
                <div v-else class="table-container">
                    <entity-list-table table-titles="project list"
                                       v-bind:table-data="projectLists"></entity-list-table>
                </div>
            </div>
        </div>
    </layout-default>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';

import { ProjectList } from '../Models/ProjectList';

import LayoutDefault from '../Layouts/LayoutDefault.vue';
import EntityListTable from '../Components/EntityListTable.vue';
import { TabNames } from '../Models/Enums';
import ApiRequestor from '../Services/ApiRequestor';

@Component({
    name: 'project-lists',
    template: '#project-lists-id',
    components: {
        'layout-default': LayoutDefault,
        'entity-list-table': EntityListTable,
    },
    async created() {
        await this.$store.commit('setLoading', true);
    }
})

export default class ProjectLists extends Vue {
    pageTitle = 'Project List';
    projectLists: ProjectList[] = [];

    get loading(): boolean {
        return this.$store.state.loading;
    }

    @Watch('$store.state.isRefreshingProjectLists') onIsRefreshingChanged(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.getProjectLists();
        }
    }

    async getProjectLists(): Promise<void> {
        this.$store.dispatch('setLoading', true);
        await ApiRequestor.get('GetOwnerProjectLists')
            .then((res) => {
                this.projectLists = res.data;
                this.$store.dispatch('setEntityLength', res.data.length);
                this.$store.dispatch('setProjectLists', res.data);
                this.$store.dispatch('setRefreshProjectLists', false);

            })
            .catch((e) => {
                console.log(e.message);
                this.$store.dispatch('setRefreshProjectLists', false);

            });

        await this.$store.commit('setLoading', false);
    }

    async mounted(): Promise<void> {
        await this.getProjectLists();
    }

    beforeCreate() {
        this.$store.dispatch('setActiveTab', TabNames.activeList);
    }

    async created(): Promise<void> {
        await this.$store.commit('setLoading', true);
    }
}
</script>

<template>
    <div class="modal addItemModal addActionsModal fade add-item-modal add-action-modal" ref="viewActionModal" id="viewActionModal" name="viewActionModal" tabindex="-1"
         aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog custom-xl-modal">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="title">
                        <h5 class="modal-title">View Action</h5>
                    </div>
                    <a href="#" ref="closeBtn" class="close-modal" data-bs-dismiss="modal"
                       aria-label="Close">Close <i class="fas fa-times"></i></a>
                </div>

                <div class="modal-body">
                    <!-----------FORM----------------->
                    <form action="" class="add-item-form" @submit.prevent="">
                        <!--            Name-->
                        <div class="form-group" style="max-width: 294px">
                            <label for="actionName">Actions</label>
                            <div>
                                {{record.name}}
                            </div>
                        </div>

                        <!--            Comments-->
                        <div class="form-group" style="max-width: 294px">
                            <label for="comment">Comments</label>
                            <div>
                                <template v-if="record.comments && record.comments.length">
                                    <span class="actions-comment">
                                        {{ record.comments[0].text }} <br>
                                        <bs-button toggle-target="'commentsList'+index" v-if="record.comments.length > 1">More comments ({{ record.comments.length - 1 }})</bs-button>
                                    </span>
                                    <bs-collapse :id="'commentsList'+index">
                                        <ul class="actions-comment">
                                            <li v-for="(comment, i) in record.comments.slice(1)" :key="'comment-' + i">
                                                <p> {{ comment.text }}</p>
                                                <span>{{ comment.createdBy }} - {{ $moment(comment.createdOn).format("DD-MMM-YY")}}</span>

                                            </li>
                                        </ul>
                                    </bs-collapse>
                                </template>
                            </div>
                        </div>

                        <!--            Responsible User-->
                        <div class="form-group" style="max-width: 170px">
                            <label for="responsibleUser">Responsibility</label>

                            <div class="add-user">
                                <user-picture :user-id="record.ownerId" :user-name="record.ownerName" :is-profile-image-uploaded="record.isOwnerImageUploaded"
                                              :user-type="record.ownerType"></user-picture>
                            </div>
                        </div>

                        <!--            Select List-->
                        <div class="form-group" style="max-width: 135px">
                            <label>List</label>

                            <div class="category-list jdi-tooltip" :style="'background: #' + record.list.colour">
                                {{truncate(record.list.name)}}
                                <span class="jdi-tooltiptext">{{record.list.name}}</span>
                            </div>
                        </div>

                        <!--            Date Picker-->
                        <div class="form-group" style="max-width: 155px">
                            <label>Target date</label>
                            <div>{{$moment(record.targetDate).format("DD-MMM-YY")}}</div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <div class="modal-footer-left-container">
                        <div v-if="record.createdBy" class="jdi-label">Created by {{record.createdBy}} on {{$moment(record.createdOn).format("DD-MMM-YY")}} at {{$moment(record.createdOn).format("HH:mm")}} </div>
                        <div v-if="record.modifiedBy" class="jdi-label">Modified by {{record.modifiedBy}} on {{$moment(record.modifiedOn).format("DD-MMM-YY")}} at {{$moment(record.modifiedOn).format("HH:mm")}} </div>
                    </div>
                    <div class="modal-footer-rigt-container">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';

import UserPicture from '../UserPicture/UserPicture.vue';
import ReadMore from '../ReadMore.vue';
import { BaseEntity, BaseEntityDefault } from '../../Models/BaseEntity';
import BsButton from '../BsButton';
import BsCollapse from '../BsCollapse';

@Component({
    name: 'view-action-modal',
    template: '#view-action-modal-id',
    components: {
        'user-picture': UserPicture,
        'read-more': ReadMore,
        'bs-button': BsButton,
        'bs-collapse': BsCollapse,
    },
})

export default class ViewActionModal extends Vue {
    private record: BaseEntity = BaseEntityDefault;
    private readonly index = '_ViewActionModal';

    @Watch('$store.state.viewRegisterItem') onContactHasBeenCreatedChanged(newVal: BaseEntity, oldVal: BaseEntity) {
        if (newVal && newVal.id) {
            this.record = this.$store.state.viewRegisterItem;
        }
        else {
            this.record = BaseEntityDefault;
        }
    }

    truncate(str: string): string {
        if (!str) return '';

        if (str.length > 8) {
            return str.substring(0, 8) + '...';
        }
        return str;
    }

    mounted(): void {
        const me = this;
        $(<Element>this.$refs.viewActionModal).on('hidden.bs.modal', function () {
            if (me.$route.query.id) {
                me.$router.push({ name: 'actions' }).catch((er) => er);
            }
        });
    }
}
</script>

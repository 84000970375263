<template>
    <div class="list-table">
        <!-- Actions List-->
        <div v-if="tableTitles == 'action list'" class="medmodus-table-container">
            <div class="empty-data" v-if="this.filteredActiveData.length < 1">
                <i class="far fa-clipboard"></i>
                <h3>
                    No action lists found
                </h3>
                <a href=".addItemModal" class="add-button jdi-green-button" data-bs-toggle="modal">
                    <span class="add-icon-container">
                        <i class="fas fa-plus"></i>
                    </span>New Action List
                </a>
            </div>
            <table v-else class="medmouds-table action-list-table">
                <tbody>
                    <tr>
                        <th>
                            <a href="#" class="table-title" :class="{
                active: order.columnName === 'List Name',
                asc: order.direction === 'asc',
                desc: order.direction === 'desc',
              }" @click.prevent="sortTable(['name'], 'string', 'List Name')"><span>List Name</span> <i class="fas fa-chevron-down"></i></a>
                        </th>
                        <th>
                            <a href="#" class="table-title" :class="{
                active: order.columnName === 'On Track',
                asc: order.direction === 'asc',
                desc: order.direction === 'desc',
              }" @click.prevent="sortTable(['onTrack'], 'number', 'On Track')"><span>On Track</span> <i class="fas fa-chevron-down"></i></a>
                        </th>
                        <th>
                            <a href="#" class="table-title" :class="{
                active: order.columnName === 'Expire today',
                asc: order.direction === 'asc',
                desc: order.direction === 'desc',
              }" @click.prevent="sortTable(['expireToday'], 'number', 'Expire today')"><span>Expire today</span> <i class="fas fa-chevron-down"></i></a>
                        </th>
                        <th>
                            <a href="#" class="table-title" :class="{
                active: order.columnName === 'Delayed',
                asc: order.direction === 'asc',
                desc: order.direction === 'desc',
              }" @click.prevent="sortTable(['delayed'], 'number', 'Delayed')"><span>Delayed</span> <i class="fas fa-chevron-down"></i></a>
                        </th>
                        <th>
                            <a href="#" class="table-title" :class="{
                active: order.columnName === 'Creation date',
                asc: order.direction === 'asc',
                desc: order.direction === 'desc',
              }" @click.prevent="
                sortTable(['createdOn'], 'string', 'Creation date')
              "><span>Creation date</span> <i class="fas fa-chevron-down"></i></a>
                        </th>
                    </tr>
                    <tr v-for="(row, index) in filteredActiveData" :key="index">
                        <td>
                            <div class="category-title">
                                <span class="color-indicator" :style="'background: #' + row.colour"></span>{{ row.name }} ({{ row.actionsCount }})
                            </div>
                        </td>
                        <td>
                            <span class="d-block text-center on-track">
                                {{
                row.onTrack
                                }}
                            </span>
                        </td>
                        <td>
                            <span class="d-block text-center expire-today">
                                {{
                row.expireToday
                                }}
                            </span>
                        </td>
                        <td>
                            <span class="d-block text-center delayed">
                                {{
                row.delayed
                                }}
                            </span>
                        </td>
                        <td>
                            <span>{{ $moment(row.createdOn).format("D-MMM-YY") }}</span>
                        </td>
                        <td>
                            <div class="options-item" v-if="row.isOwner">
                                <a href="#addItemModal" @click="editRegister(row.id, 'ActionList')" data-bs-toggle="modal"><i class="far fa-edit fontawesome-icon"></i></a>
                                <a v-if="row.actionsCount === 0" href="#deleteItemModal" data-bs-toggle="modal" @click="deleteRegister(row.id, index, 'ActionList')"><i class="far fa-trash-alt fontawesome-icon"></i></a>
                            </div>
                            <div class="options-item" v-else>
                                <a href="#viewItemModal" @click="viewRegister(row.id, 'ActionList')" data-bs-toggle="modal"><i class="far fa-edit fontawesome-icon"></i></a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <add-or-edit-list-modal :edit-record="editRecord" page-title="Action List " endpoint="ActionList" :users="users" ref="editActionListModal"></add-or-edit-list-modal>
            <view-list-modal :record="editRecord" page-title="Action List"></view-list-modal>
        </div>
        <!-- FIN  Actions List -->
        <!-- Projects List-->
        <div v-if="tableTitles == 'project list'" class="medmodus-table-container">
            <div class="empty-data" v-if="this.filteredActiveData.length < 1">
                <i class="far fa-clipboard"></i>
                <h3>
                    No project lists found
                </h3>
                <a href=".addItemModal" class="add-button jdi-green-button" data-bs-toggle="modal">
                    <span class="add-icon-container">
                        <i class="fas fa-plus"></i>
                    </span>
                    New Project List
                </a>
            </div>
            <table v-else class="medmouds-table action-list-table">
                <tbody>
                    <tr>
                        <th>
                            <a href="#" class="table-title" :class="{
                active: order.columnName === 'List Name',
                asc: order.direction === 'asc',
                desc: order.direction === 'desc',
              }" @click.prevent="sortTable(['name'], 'string', 'List Name')"><span>List Name</span> <i class="fas fa-chevron-down"></i></a>
                        </th>
                        <th>
                            <a href="#" class="table-title" :class="{
                active: order.columnName === 'On Track',
                asc: order.direction === 'asc',
                desc: order.direction === 'desc',
              }" @click.prevent="sortTable(['onTrack'], 'number', 'On Track')"><span>On Track</span> <i class="fas fa-chevron-down"></i></a>
                        </th>
                        <th>
                            <a href="#" class="table-title" :class="{
                active: order.columnName === 'Expire today',
                asc: order.direction === 'asc',
                desc: order.direction === 'desc',
              }" @click.prevent="sortTable(['expireToday'], 'number', 'Expire today')"><span>Expire today</span> <i class="fas fa-chevron-down"></i></a>
                        </th>
                        <th>
                            <a href="#" class="table-title" :class="{
                active: order.columnName === 'Delayed',
                asc: order.direction === 'asc',
                desc: order.direction === 'desc',
              }" @click.prevent="sortTable(['delayed'], 'number', 'Delayed')"><span>Delayed</span> <i class="fas fa-chevron-down"></i></a>
                        </th>
                        <th>
                            <a href="#" class="table-title" :class="{
                active: order.columnName === 'Creation date',
                asc: order.direction === 'asc',
                desc: order.direction === 'desc',
              }" @click.prevent="
                sortTable(['createdOn'], 'string', 'Creation date')
              "><span>Creation date</span> <i class="fas fa-chevron-down"></i></a>
                        </th>
                    </tr>
                    <tr v-for="(row, index) in filteredActiveData" :key="index">
                        <td>
                            <div class="category-title">
                                <span class="color-indicator" :style="'background: #' + row.colour"></span>
                                {{ row.name }} ({{row.projectsCount}})
                            </div>
                        </td>
                        <td>
                            <span class="d-block text-center on-track">
                                {{
                row.onTrack
                                }}
                            </span>
                        </td>
                        <td>
                            <span class="d-block text-center expire-today">
                                {{
               row.expireToday
                                }}
                            </span>
                        </td>
                        <td>
                            <span class="d-block text-center delayed">
                                {{
                row.delayed
                                }}
                            </span>
                        </td>
                        <td>
                            <span>{{ $moment(row.createdOn).format("D-MMM-YY") }}</span>
                        </td>
                        <td>
                            <div class="options-item" v-if="row.isOwner">
                                <a href="#addItemModal" @click="editRegister(row.id, 'ProjectList')" data-bs-toggle="modal"><i class="far fa-edit fontawesome-icon"></i></a>
                                <a v-if="row.projectsCount === 0" href="#deleteItemModal" data-bs-toggle="modal" @click="deleteRegister(row.id, index, 'ProjectList')"><i class="far fa-trash-alt fontawesome-icon"></i></a>
                            </div>
                            <div class="options-item" v-else>
                                <a href="#viewItemModal" @click="viewRegister(row.id, 'ProjectList')" data-bs-toggle="modal"><i class="far fa-edit fontawesome-icon"></i></a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <add-or-edit-list-modal :edit-record="editRecord" page-title="Project List " endpoint="ProjectList" :users="users" ref="editProjectListModal"></add-or-edit-list-modal>
            <view-list-modal :record="editRecord" page-title="Project List"></view-list-modal>
        </div>
        <!-- FIN  Projects List -->
        <!-- Modals-->
        <delete-modal v-bind:table-index="indexToDelete" v-bind:register-id="registerId" v-bind:delete-endpoint="deleteEndpoint"></delete-modal>

    <notification v-if="isNotificationDisplayed" message="The record you tried to access could not be found. It may have been deleted, or you might not have access to it."></notification>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

import { TableItem } from '../Models/TableItem';
import { Order } from '../Models/Order';
import { Pagination } from '../Models/Pagination';
import { DirectionReference } from '../Models/Enums';

import { TableUtils } from '../Utils/TableUtils';

import DeleteModal from '../Components/Modals/DeleteModal.vue';
import AddOrEditListModal from '../Components/Modals/AddOrEditListModal.vue';
import ViewListModal from '../Components/Modals/ViewListModal.vue';
import { DeleteRegisterItem } from '../Models/BaseEntity';
import { UsersService } from '../Services/UsersService';
import { User } from '../Models/User';
import { JdiState } from '../Store/Types';
import { BaseListEntity } from '../Models/BaseListEntity';
import Notification from '../Components/Notification/Notification.vue';

@Component({
    name: 'entity-list-table',
    template: '#entity-list-table-id',
    components: {
        'delete-modal': DeleteModal,
        'add-or-edit-list-modal': AddOrEditListModal,
        'view-list-modal': ViewListModal,
        'notification': Notification,
    }
})

export default class EntityListTable extends Vue {
    @Prop() tableData!: BaseListEntity[];
    @Prop() tableTitles!: string;
    @Prop({ default: true }) showListFilters!: boolean;
    @Prop() lists!: [];

    private order: Order = {
        direction: 'asc',
        type: 'string',
        column: ['title'],
        columnName: '',
    };
    private users: User[] = [];
    private date: Date = new Date();
    private deleteEndpoint = 'Action';
    private editRecord: BaseListEntity = {} as BaseListEntity;
    private isEditing = false;
    private registerId = '';
    private commentsToShow = 1;
    private listSelectOpen = false;
    private listOptionSelected = 'View more lists';
    private filterApplied = false;
    private filterSelected: TableItem[] = [];
    private tableDataFilteredByList: TableItem[] = [];
    private indexToDelete = 0;
    private pagination: Pagination = {
        pageNumber: 1,
        pageSize: 5,
    };
    private isNotificationDisplayed = false;

    get filteredActiveData(): TableItem[] {
        return this.tableData;
    }

    sortTable(column: string[], type: string, columnName: string, staticDirection: string): void {
        const sameColumnName = this.order.columnName === columnName;
        const currentDirection = this.order.direction as keyof typeof DirectionReference;
        const direction: string = staticDirection?.length ?
            staticDirection :
            sameColumnName ? DirectionReference[currentDirection] : 'asc';
        const dir: number = direction === 'desc' ? -1 : 1;

        switch (type) {
            case 'string':
                this.tableData.sort((a, b) => TableUtils.sortTableItemsByName(a, column, b, dir));
                this.tableDataFilteredByList.sort((a, b) => TableUtils.sortTableItemsByName(a, column, b, dir));
                break;

            case 'date':
                this.tableData.sort((a, b) => TableUtils.sortTableItemsByDate(a, column, b, dir));
                this.tableDataFilteredByList.sort((a, b) => TableUtils.sortTableItemsByDate(a, column, b, dir));
                break;

            case 'number':
                this.tableData.sort((a, b) => TableUtils.sortTableItemsByNumber(a, column, b, dir));
                this.tableDataFilteredByList.sort((a, b) => TableUtils.sortTableItemsByNumber(a, column, b, dir));
                break;

            default:
        }
        this.order = {
            direction: direction ? direction : 'asc',
            type,
            column,
            columnName,
        };
    }


    filterTableData(): TableItem[] {
        const activeTable: BaseListEntity[] = [...this.tableData];
        const arrayFiltered: BaseListEntity[] = [];
        this.filterSelected.forEach((list) => {
            const tableDataFiltered: BaseListEntity[] = activeTable.filter((item: any) => item.list && item.list.id === list.id);
            arrayFiltered.concat(tableDataFiltered);
        });

        return arrayFiltered.reduce((acc: BaseListEntity[], val: BaseListEntity) => acc.concat(val), []);
    }

    editRegister(id: string, endpoint: string): void {
        if (!this.$route.query.id || id != this.$route.query.id) {
            this.$router.push({ path: this.tableTitles.includes('action') ? 'action-lists' : 'project-lists', query: { id } });
        }
        const record = this.tableData.find((item) => item.id == id) as BaseListEntity;
        this.editRecord = JSON.parse(JSON.stringify(record));
        this.$store.dispatch('setEdit', true);
    }

    viewRegister(id: string, endpoint: string): void {
        const record = this.tableData.find((item) => item.id == id) as BaseListEntity;
        const owner = this.users.find((u) => u.id == record.ownerId) as User;
        this.editRecord = record;
        this.editRecord.ownerId = <string>owner.id;
        this.editRecord.ownerName = owner.ownerName;
        this.editRecord.ownerType = owner.type;
        this.editRecord.isOwnerImageUploaded = owner.isOwnerImageUploaded;
        this.editRecord.isView = true;
        if (!this.$route.query.id || id != this.$route.query.id) {
            this.$router.push({ path: this.tableTitles.includes('action') ? 'action-lists' : 'project-lists', query: { id } });
        }
    }

    deleteRegister(id: string, index: number, deleteEndpoint: string): void {
        this.$store.dispatch('setDeleteRegisterItem', { registerId: id, deleteEndpoint, index } as DeleteRegisterItem);
    }

    open(id: string) {
        const list: BaseListEntity[] = this.tableTitles.includes('action') ? (<JdiState>this.$store.state).actionLists : (<JdiState>this.$store.state).projectLists;
        const item: BaseListEntity = <BaseListEntity>(list.find((t: BaseListEntity) => t.id == id));
        if (item) {
            const owner = this.users.find((u) => u.id == item.ownerId) as User;
            this.editRecord = JSON.parse(JSON.stringify(item));
            this.editRecord.ownerId = <string>owner.id;
            this.editRecord.ownerName = owner.ownerName;
            this.editRecord.ownerType = owner.type;
            this.editRecord.isOwnerImageUploaded = owner.isOwnerImageUploaded;
            this.editRecord.isView = !this.editRecord.isOwner;
            this.$store.dispatch('setEdit', true);
            if (this.editRecord.isOwner) {
                $('#addItemModal').modal('show');
            }
            else {
                $('#viewItemModal').modal('show');
            }
        }
        else {
            this.isNotificationDisplayed = true;
        }
    }

    mounted(): void {
        this.init();
    }

    async init() {
        // this code is supposed to open an item (action/project) by its URL
        const res = await UsersService.GetUsers();
        if (res.status) {
            this.users = res.data as User[];
        }
        if (this.$route.query.id) {
            this.open(<string>this.$route.query.id);
        }
    }
}
</script>


import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
    name: 'bs-button',
    render: function (this: BsButton, h) {
        return h(
            'button',
            {
                staticClass: 'btn',
                class: { [`btn-${this.variant}`]: this.variant },
                attrs: { type: 'button' },
                on: {
                    click: () => {
                        this.collapsed = !this.collapsed;
                        this.onCollapsedChanged();
                    }
                },
            },
            this.$slots.default);
    }
})

export default class BsButton extends Vue {
    @Prop({ type: String, default: 'secondary' }) private variant!: string;
    @Prop({ type: String }) private toggleTarget!: string;

    private collapsed = true;

    onCollapsedChanged() {
        // emit toggle event on the root, indicating target to toggle
        this.$root.$emit('bs-toggle', this.toggleTarget);
    }
}

<template>
    <div class="contacts-page">
        <layout-default page-title="Contacts">
            <div class="main-table-page" style="padding-top: 180px;">
                <div class="main">
                    <div class="ajax-loading" v-if="loading"></div>
                    <div v-else class="table-container">
                        <div class="medmodus-table-container">
                            <div class="tab-content" id="contactsTabContent">
                                <div class="tab-pane fade" :class="{'active show': !isArchived}" id="activeContacts" role="tabpanel">
                                    <div class="empty-data" v-if="paginatedActiveResults.length < 1" style="margin-top: 115px;">
                                        <i class="far fa-clipboard"></i>
                                        <h3>
                                            No contacts found
                                        </h3>
                                        <a href="#addContactModal" class="add-button jdi-green-button" data-bs-toggle="modal">
                                            <span class="add-icon-container">
                                                <i class="fas fa-plus"></i>
                                            </span>New Contact
                                        </a>
                                    </div>
                                    <div v-if="paginatedActiveResults.length > 0">
                                        <contacts-table :table-data="paginatedActiveResults" :is-archived-table="false" ref="contactsTable"></contacts-table>

                                        <div class="load-more" v-if="paginatedActiveResults.length < filteredActiveData.length">
                                            <a href="" @click.prevent="loadMore">Load more contacts</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" :class="{'active show': isArchived}" id="archivedContacts" role="tabpanel">
                                    <div class="empty-data" v-if="paginatedArchivedResults.length < 1" style="margin-top: 115px;">
                                        <i class="far fa-clipboard"></i>
                                        <h3>
                                            No contacts found
                                        </h3>
                                    </div>
                                    <div v-if="paginatedArchivedResults.length > 0">
                                        <contacts-table :table-data="paginatedArchivedResults" :is-archived-table="true" ref="archivedContactsTable"></contacts-table>
                                        <div class="load-more" v-if="paginatedArchivedResults.length < filteredArchivedData.length">
                                            <a href="" @click.prevent="loadMore">Load more contacts</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <add-contact-modal ref="contactModal"></add-contact-modal>
            <notification v-if="isNotificationDisplayed" message="The record you tried to access could not be found. It may have been deleted, or you might not have access to it."></notification>
        </layout-default>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Contact } from '../Models/Contact';

import LayoutDefault from '../Layouts/LayoutDefault.vue';
import ContactsTable from '../Components/Tables/ContactsTable.vue';
import { Pagination } from '../Models/Pagination';
import { ContactsState } from '../Store/Types';
import { TabNames } from '../Models/Enums';
import { ContactsService } from '../Services/ContactsService';
import AddContactModal from '../Components/Modals/AddContactModal.vue';
import Notification from '../Components/Notification/Notification.vue';

@Component({
    name: 'Contacts',
    template: '#contacts-id',
    components: {
        'layout-default': LayoutDefault,
        'contacts-table': ContactsTable,
        'add-contact-modal': AddContactModal,
        'notification': Notification,
    },
    async created() {
        await this.$store.commit('setLoading', true);
    }
})

export default class Contacts extends Vue {
    private Contacts: Contact[] = [];
    private isArchived = false;

    private pagination: Pagination = {
        pageNumber: 1,
        pageSize: 5,
    };

    private isNotificationDisplayed = false;

    @Watch('$store.state.hasContactBeenCreated') onIsRefreshingChanged(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.$store.dispatch('setLoading', true);
            this.getOwners();
            this.getContacts();
            this.isArchived = this.$store.state.activeTab == TabNames.archivedList;
        }
    }

    get contactsState(): ContactsState {
        return this.$store.state;
    }

    get loading(): boolean {
        return this.$store.state.loading;
    }

    get paginatedActiveResults(): Contact[] {
        return this.filteredActiveData.slice(0, this.pagination.pageNumber * this.pagination.pageSize);
    }

    get paginatedArchivedResults(): Contact[] {
        return this.filteredArchivedData.slice(0, this.pagination.pageNumber * this.pagination.pageSize);
    }

    get filteredArchivedData(): Contact[] {
        return this.searchData.filter((item: Contact) => item.archived);
    }

    get filteredActiveData(): Contact[] {
        return this.searchData.filter((item: Contact) => !item.archived);
    }

    get searchData(): Contact[] {
        return this.Contacts.filter((item) => {
            const searchFilter = this.$store.state.search;
            return item.name.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1;
        });
    }

    async getContacts() {
        try {
            const res = await ContactsService.GetContacts();
            this.Contacts = res.data as Contact[];
            await this.$store.dispatch('setEntityLength', this.Contacts.length);
            await this.$store.dispatch('setRefreshContacts', false);
            await this.$store.dispatch('setLoading', false);
            this.checkRecordUrl();
        }
        catch (e: any) {
            await this.$store.dispatch('setRefreshContacts', false);
            await this.$store.dispatch('setLoading', false);
            await this.$store.dispatch('setContacts', []);

            console.log(e.message);
        }
    }

    checkRecordUrl() {
        if (this.$route.query.id) {
            this.open(<string>this.$route.query.id);
        }
    }

    open(id: string) {
        const item: Contact = this.Contacts.find((t) => t.id == id) as Contact;

        if (item) {
            if (!item.archived) {
                (<ContactsTable>this.$refs.contactsTable).editContact(id);
            }
            else {
                (<ContactsTable>this.$refs.archivedContactsTable).editContact(id);
            }
            $((<Vue>this.$refs.contactModal).$el).modal('show');
        }
        else {
            this.isNotificationDisplayed = true;
        }
    }

    async getOwners() {
        try {
            const res = await ContactsService.GetOwners();
            await this.$store.dispatch('setOwners', res.data);

        }
        catch (e: any) {
            await this.$store.dispatch('setOwners', []);
            console.log(e.message);
        }
    }

    loadMore(): void {
        this.pagination.pageNumber++;
    }

    beforeCreate() {
        this.$store.dispatch('setActiveTab', TabNames.activeList);
    }

    mounted(): void {
        this.getContacts();
        this.getOwners();
    }

}
</script>

<style lang="less">
.contacts-page {
    .lists-menu {
        margin-top: 5px;
    }

    .reassign-contact-modal {
        .v-select {
            min-width: 200px;
            padding-left: 10px;
        }

        .modal-footer {
            justify-content: right;
        }
    }

    td {
        word-break: break-all;
    }
}
</style>

<template>

  <div id="app" class="jdi-app">
    <router-view></router-view>
  </div>

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
export default class App extends Vue {
}
</script>

<style lang="less">

#app {
    padding: 0 12px;
    overflow: auto;
    padding-right: 2px;
    height: calc(~"100vh - 110px");
    margin: 75px 0 20px 0;
}

.jdi-app {
    font-family: 'Open Sans', sans-serif;

    .dji-module {
        a:hover {
            text-decoration: none;
        }

        button:focus {
            outline: none;
        }
    }

    .jdi-tooltip {
        position: relative;
        display: inline-block;
    }

    .jdi-tooltip .jdi-tooltiptext {
        visibility: hidden;
        min-width: 120px;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 100;
        bottom: 125%;
        left: 50%;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
        word-break: keep-all;
    }

    .jdi-tooltip .jdi-tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
    }

    .jdi-tooltip:hover .jdi-tooltiptext {
        visibility: visible;
        opacity: 1;
    }

    .table-container {
        max-width: 1208px;
        margin: 0 auto;
    }

    .medmouds-table {
        width: 100%;
    }

    .list-table {
        .on-track {
            color: #6f8294;
        }

        .expire-today {
            color: #ffa04c;
        }

        .delayed {
            color: #e95a6c;
        }

        .option-selected {
            .active {
                color: rgb(0, 153, 51);
            }

            .delayed {
                color: rgb(255, 203, 91);
            }

            .stopped {
                color: rgb(233, 90, 108);
            }
        }

        .filter-bar-fixed {
            left: 0;
            width: 100%;
            top: 214px;
            z-index: 10;
            background-color: #fff;
        }

        .filter-bar {
            max-width: 1208px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .table-filter {
                display: flex;
                align-items: flex-start;

                .selected-lists-badges {
                    li {
                        margin-bottom: 10px;
                    }
                }

                ul {
                    flex-wrap: wrap;
                    padding: 0;
                    list-style: none;
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;
                    margin-right: 10px;

                    &.filter-option-list {
                        padding: 6px 0;
                        min-width: 180px;
                        max-height: 300px;
                        overflow: auto;

                        li {
                            padding: 6px 15px;
                            margin-right: 0;
                        }
                    }

                    li {
                        margin-right: 10px;

                        .filter-button {
                            padding: 10px 20px;
                            background: #2c86c6;
                            border-radius: 25px;
                            display: flex;
                            align-items: center;
                            color: #fff;
                            font-size: 14px;
                            line-height: 16px;
                        }

                        .filter-quit {
                            background: transparent;
                            padding: 0;
                            display: inline-block;
                            margin-left: 15px;

                            i {
                                color: #fff;
                                font-size: 14px;
                            }
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            .date {
                height: 44px;
                display: flex;
                align-items: center;

                span {
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 20px;
                    color: #6f8294;
                }

                .fontawesome-icon {
                    display: inline-block;
                    margin-left: 10px;
                }
            }
        }

        .actions-comment {
            button {
                color: #54A4FC;
                font-weight: 700;
                background: transparent;
                border: none;
                padding: 0;
            }
        }

        th,
        td {
            padding: 25px 30px;
            vertical-align: baseline;
        }

        .project-table {
            th,
            td {
                padding: 15px;
            }

            ol {
                padding-left: 10px;
            }
        }

        .table-title {
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            min-width: 70px;
            color: #000;

            span {
                flex-shrink: 0;
            }

            i {
                color: #1055a1;
                margin-left: 6px;
                flex-shrink: 0;
                transform: rotate(-90deg);
            }

            &.active {
                &.asc {
                    i {
                        transform: rotate(180deg);
                    }
                }

                &.desc {
                    i {
                        transform: rotate(0deg);
                    }
                }
            }
        }

        td {
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #6f8294;
        }

        tr:nth-child(even) {
            background: #f3f5f7;
        }

        .check-item {
            display: flex;
            align-items: center;

            a {
                margin-right: 15px;

                &:hover {
                    cursor: pointer;
                }

                .fa-check-circle {
                    font-size: 20px;
                    color: #6f8294;


                    &.complete {
                        color: #009933;
                    }
                }
            }
        }

        .options-item {
            display: flex;
            align-items: center;

            a {
                height: 20px;
                display: inline-block;

                img {
                    height: 100%;
                }
            }

            a:first-child {
                margin-right: 20px;
            }
        }

        .responsible-user {
            display: flex;
            align-items: center;


            .name-acronym {
                border-radius: 50%;
                background: #6f8294;
                width: 33px;
                height: 33px;
                font-size: 14px;
                line-height: 19px;
                color: #fff;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 13px;

                &.user-picture {
                    background-size: 100%;
                }
            }
        }

        .category-list {
            background: #2c86c6;
            border-radius: 7px;
            padding: 4px 10px;
            color: #fff;
            display: inline-block;
            cursor: default;
        }

        .category-title {
            display: flex;
            align-items: center;

            .color-indicator {
                width: 17px;
                height: 17px;
                border-radius: 50%;
                background: #ddd;
                display: inline-block;
                margin-right: 18px;
            }

            font-size: 14px;
            line-height: 19px;
            color: #1055A1;
            font-weight: 700;
        }

        .work-status {
            color: #fff;
            text-align: left;
            padding: 4px 12px;
            border-radius: 7px;
            font-weight: 300;
            font-size: 20px;
            line-height: 16px;

            &.active {
                i {
                    color: #009933;
                }
            }

            &.delayed {
                i {
                    color: #ffcb5b;
                }
            }

            &.stopped {
                i {
                    color: #e95a6c;
                }
            }
        }
    }

    .list-table .filter-bar .table-filter .jdi-custom-select.show-more-link {
        .jdi-select-box {
            font-size: 14px;
            line-height: 19px;
            color: #1055a1;
            border: none;
            padding: 0;
        }

        .options-list {
            display: block;
            z-index: 2;

            &.hidden-list {
                visibility: hidden;
            }
        }

        .filter-used {
            opacity: 0.3;
            pointer-events: none;
            cursor: no-drop;
        }
    }

    .load-more {
        text-align: center;
        margin: 40px 0 0 0;

        a {
            border: 1px solid #54A4FC;
            padding: 10px 20px;
            border-radius: 5px;
            color: #54A4FC;
        }
    }

    .empty-data {
        text-align: center;

        i {
            font-size: 134px;
            color: #1055A1;
            margin-bottom: 50px;
        }

        h3 {
            font-size: 32px;
            line-height: 32px;
            font-family: "Open Sans";
            margin-bottom: 37px;
            font-weight: 600;
            color: #1055A1;
        }
    }

    .add-button {
        display: inline-flex;
        padding: 15px;
        align-items: center;
        border-radius: 5px;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;

        .add-icon-container {
            margin-right: 15px;
            border-radius: 50%;
            border: 2px solid #fff;
            position: relative;
            width: 20px;
            height: 20px;

            i {
                font-size: 10px;
                color: #fff;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    // defining this class because it no longer exists in BS5
    .form-group {
        margin-bottom: 1rem;
    }

    .add-item-modal .add-item-form .form-group .text-area-container {
        textarea {
            resize: none;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 9px;
            right: 9px;
            color: #A0A0A0;
            font-size: 13px;
            line-height: 16px;
            font-style: italic;
        }

        &.measure-text-area:after {
            content: '120';
        }

        &.issues-text-area:after {
            content: '200';
        }

        &.nextSteps-text-area:after {
            content: '160';
        }

        &.field-set {
            textarea {
                height: auto;
            }

            &:after {
                display: none;
            }
        }
    }

    .input-hidden {
        visibility: hidden;
    }

    .input-no-border {
        border: none !important;
        padding-left: 0 !important;
    }

    img {
        vertical-align: top;
    }

    a {
        color: #54A4FC;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    .fontawesome-icon {
        color: #6F8294;
        font-size: 20px;
    }

    .jdi-green-button {
        background: #00A31A;
        color: #fff;

        &:hover {
            background: #156d23;
            color: #fff;
        }

        &.active {
            background: #156d23;
        }

        &.disabled {
            cursor: default;
            background: #68706a;
        }
    }

    .add-item-modal, .modal {
        .required-field:after {
            content: ' *';
            color: #dc3545;
            font-weight: 300;
        }

        textarea {
            border: 1px solid #1055A1;
            border-radius: 5px;
            padding: 11px 10px;
            max-height: 112px;
        }

        .close-modal {
            font-size: 16px;
            line-height: 22px;
            color: #6F8294;
            display: flex;
            align-items: center;

            i {
                margin-left: 11px;
                font-size: 16px;
            }
        }

        .modal-header {
            padding: 29px 41px 21px;
            align-items: flex-start;
        }

        .modal-content {
            border: none;
            border-radius: 6px;
        }

        .modal-body {
            padding: 20px 41px 60px;
        }

        .modal-footer {
            padding: 28px 41px 36px;
            justify-content: space-between;
        }

        .title {
            .modal-title {
                font-weight: 600;
                font-size: 24px;
                line-height: 24px;
                color: #1055A1;
            }

            p {
                font-size: 14px;
                line-height: 20px;
                color: #1055A1;
            }
        }

        .add-item-form {
            margin-bottom: 8px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .form-group {
                width: 100%;

                .msg-error {
                    margin-top: 8px;
                    font-size: 12px;
                    color: #dc3545;
                }

                .vd-picker__input {
                    width: 90%;

                    .vd-picker__input-icon {
                        align-self: unset;
                        margin-top: 0;
                    }

                    input {
                        border: none;
                        padding-left: 0;
                        font-size: 14px !important;
                        line-height: 19px;
                    }
                }

                .vs--single .vs__selected {
                    position: absolute;
                    left: 0;
                    z-index: 2;
                    top: 50%;
                    transform: translateY(-50%);
                    margin-top: 0;
                    font-size: 14px;
                    line-height: 19px;
                }

                input:not([type="checkbox"]) {
                    width: 90%;
                    background: #FFFFFF;
                    border: 1px solid #1055A1;
                    padding: 11px 14px;
                    border-radius: 5px !important; // if not important, the rule gets overridden by BS5 styles
                    font-size: 14px;
                    line-height: 19px;
                    transition: all .3s ease;

                    &.vs__search {
                        border: none;
                    }

                    &.date {
                        width: 50%;
                        margin: 0 5px 0 0;
                    }
                }

                label {
                    display: block;
                    margin-bottom: 12px;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 19px;
                    color: #000;

                    &.inputValue {
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 400;
                        padding: 12px 0;
                    }
                }

                .list-option-selected {
                    display: inline-block;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 16px;
                    border-radius: 7px;
                    padding: 4px 15px;
                    color: #fff;
                }
            }

            .add-user {
                display: flex;
                align-items: center;
                position: relative;

                .add-icon-container {
                    margin-right: 8px;
                    flex-shrink: 0;
                    width: 30px;
                    height: 30px;
                    position: relative;
                    display: inline-block;
                    border-radius: 50%;
                    border: 2px solid #009933;
                    margin-bottom: 0;
                    cursor: pointer;

                    i {
                        font-size: 14px;
                        color: #009933;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .jdi-custom-select {
                    width: 100%;
                }

                .options-list {
                    width: 100%;
                }

                .users-select {
                    border: none;
                    padding-left: 0;
                    padding-right: 0;
                }

                .name-acronym {
                    text-transform: uppercase;
                    margin-right: 8px;
                }

                .vue-simple-suggest {
                    input {
                    }
                }

                .add-user-dropdown-menu {
                    min-width: 133px;
                    list-style: none;
                    padding: 8px 0 8px;
                    position: absolute;
                    left: 0;
                    top: 45px;
                    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
                    border-radius: 5px;
                    background: #fff;

                    .add-user-dropdown-list-item {
                        padding: 8px 24px;
                        font-size: 14px;
                        line-height: 16px;
                        color: #6F8294;

                        &:hover {
                            color: #000;
                        }
                    }
                }
            }

            .users-select {
                .vs__actions {
                    .vs__clear, .vs__spinner {
                        display: none;
                    }
                }

                .vs__dropdown-toggle {
                    border: none;
                    padding-bottom: 0;
                }

                .vs__dropdown-menu {
                    box-shadow: 0 0 4px rgba(0, 0, 0, .25);
                    border-radius: 5px;
                    background: #fff;
                    border: none;
                    padding: 8px 0;
                }

                .vs__dropdown-option {
                    padding: 8px 24px;
                    font-size: 14px;
                    color: #6F8294;
                    line-height: 1.5;
                }

                .vs__dropdown-option--highlight {
                    color: #000;
                    background: none;
                }
            }

            .jdi-search-select {
                .select-list-container {
                    position: relative;

                    .list-select {
                        .vs__actions {
                            .vs__clear, .vs__spinner {
                                display: none;
                            }
                        }

                        .vs__selected-options {
                            padding-left: 0;
                        }

                        input.vs__search {
                            padding: 11px 10px;
                            font-size: 14px;
                            line-height: 19px;
                            margin-top: 0;
                        }

                        .vs__dropdown-toggle {
                            border: 1px solid #1055A1;
                            border-radius: 5px;
                            padding-bottom: 0;
                        }

                        .vs__dropdown-menu {
                            box-shadow: 0 0 4px rgba(0, 0, 0, .25);
                            border-radius: 5px;
                            background: #fff;
                            border: none;
                            padding: 8px 0;
                            top: 100%;
                            min-width: 210px;
                        }

                        .vs__dropdown-option {
                            padding: 8px 24px;
                            font-size: 14px;
                            color: #6F8294;
                            line-height: 1.5;
                        }

                        .vs__dropdown-option--highlight {
                            color: #000;
                            background: none;
                        }
                    }

                    i {
                        position: absolute;
                        right: 14px;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: 2;
                        color: #6F8294;
                    }
                }

                .result-select {
                    font-weight: 300;
                    border-radius: 7px;
                    padding: 4px 10px;
                    color: #fff;
                    display: inline-flex;
                    align-items: center;
                    max-width: 100%;

                    span {
                        font-size: 14px;
                        line-height: 16px;
                        color: #fff;
                        margin-right: 5px;
                        display: inline-block;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    i {
                        color: #fff;
                        font-size: 12px;
                    }
                }
            }
        }

        .add-item-form .form-group .add-new-item-link {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 19px;
            color: #009933;

            &:hover {
                cursor: pointer;
            }

            .add-icon-container {
                margin-right: 4.67px;
                width: 20px;
                height: 20px;
                position: relative;
                display: inline-block;
                border-radius: 50%;
                border: 2px solid #009933;

                i {
                    font-size: 10px;
                    color: #009933;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        .modal-dialog.custom-xl-modal {
            max-width: 1276px;
        }

        .save-modal-button {
            display: inline-block;
            border-radius: 5px;
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            padding: 15px;

            &.disabled {
                pointer-events: none;
                background: #BFBFBF;
            }
        }

        .category-list {
            background: #2C86C6;
            border-radius: 7px;
            padding: 4px 15px;
            color: #fff;
            display: inline-block;
        }
    }

    .responsible-user {
        display: flex;
        align-items: center;

        .user-name {
            font-size: 14px;
            line-height: 19px;
        }
    }

    .name-acronym {
        border-radius: 50%;
        background: #6F8294;
        width: 33px;
        height: 33px;
        font-size: 14px;
        line-height: 19px;
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        flex-shrink: 0;
    }

    .jdi-custom-select {
        position: relative;
        height: 44px;
        display: block;
        align-items: center;

        .jdi-select-box {
            border: 1px solid #1055A1;
            border-radius: 5px;
            padding: 11.5px 14px;
            font-size: 14px;
            line-height: 19px;
            color: #000;
            min-width: 133px;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
        }

        .options-list {
            min-width: 133px;
            list-style: none;
            padding: 8px 0 8px;
            position: absolute;
            left: 0;
            top: 45px;
            box-shadow: 0 0 4px rgba(0, 0, 0, .25);
            border-radius: 5px;
            background: #fff;
            z-index: 10;

            li {
                padding: 8px 24px;

                a {
                    font-size: 14px;
                    line-height: 16px;
                    color: #6F8294;

                    &:hover {
                        color: #000;
                    }
                }
            }
        }
    }

    .delete-item-modal {
        .modal-body {
            padding: 98px 40px 50px;

            .close-modal {
                display: flex;
                align-items: center;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: #6f8294;
                position: absolute;
                right: 25px;
                top: 20px;

                i {
                    margin-left: 11px;
                    display: inline-block;
                    font-size: 16px;
                }
            }

            h3 {
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
                text-align: center;
                color: #6f8294;
                margin-bottom: 23px;
            }

            p {
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #6f8294;
                margin-bottom: 31px;
            }

            .buttons-container {
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                    background: #b90e43;
                    border-radius: 5px;
                    padding: 13px 15px;
                    color: #ffffff;
                    margin: 0 5px;

                    &.outline-button {
                        color: #54a4fc;
                        background: #ffffff;
                        border: 1px solid #54a4fc;
                    }
                }
            }
        }
    }

    .actions-comments {
        list-style: none;
        padding: 0 0 0 20px;
        margin-bottom: 0;

        li {
            font-size: 14px;
            line-height: 19px;
            padding-top: 28px;

            p {
                font-size: 14px;
                line-height: 19px;
                color: #000;
                margin-bottom: 3px;
            }

            span {
                font-size: 12px;
                line-height: 16px;
                color: #1055A1;
                font-style: italic;
            }
        }
    }

    .add-item-modal.add-project-modal {
        .modal-body {
            padding-bottom: 75px;
        }

        textarea {
            font-size: 14px;
            line-height: 19px;
            color: #6f8294;
        }

        .add-item-form .add-user .add-user-dropdown-menu .add-user-dropdown-list-item {
            padding: 8px 10px;
        }

        .new-user {
            color: #3C9738;
        }
    }

    .add-item-modal.add-action-modal {
        .new-user {
            color: #3C9738;
        }
    }


    .option-selected {
        .active {
            i {
                color: #009933;
            }
        }

        .delayed {
            i {
                color: #ffcb5b;
            }
        }

        .stopped {
            i {
                color: #e95a6c;
            }
        }
    }

    .status-color-indicator {
        margin-right: 9px;
        font-size: 13px;
    }

    .add-item-modal .add-item-form .add-user {
        input {
            padding: 12.5px 0;
            border: none;
            outline: none;
            margin-top: 0;
            font-size: 14px;
            line-height: 19px;
        }

        .user-name {
            color: #000;
        }

        .responsible-user {
            height: 44px;
        }

        .name-acronym {
            margin-right: 5px;
        }
    }

    .date-passed {
        color: #da5f6d;
    }

    .people-picker-container {
        display: flex;

        &.new-user {
            .vs__selected {
                color: #3C9738;
            }
        }

        .no-selected-item .v-select {
            > div {
                border: 0px;
                display: flex;
                flex-direction: row-reverse;
            }

            .vs__clear {
                display: none;
            }
        }

        .v-select {
            &.vs--open .vs__open-indicator {
                transform: none;
            }


            > div {
                border: 0px;
                padding: 0px;
            }


            .vs__actions {
                padding: 0px;
            }

            ul {
                min-width: 300px;
                overflow-x: hidden;

                li {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 280px;
                    white-space: nowrap;
                }
            }
        }
    }

    .vs__actions {
        cursor: pointer;
    }

    .confirmation-item-modal {
        .modal-body {
            padding: 40px;
        }

        .modal-footer {
            padding: 20px 40px;
        }
    }

    .confirmation-message {
        font-size: 18px;
    }

    input.date {
        height: 41px;
    }

    .multi-people-picker-container {
        .people-list {
            max-height: 220px;
            display: flex;
            flex-wrap: wrap;
            overflow-y: auto;
        }

        .picker {
            min-width: 60px;
        }

        .members-container {
            display: flex;
        }

        .person-item {
            display: flex;
        }
    }

    .no-items .vs__dropdown-menu {
        display: none;
    }

    .notification-container {
        position: fixed;
        bottom: 85px;
        right: 20px;
        z-index: 999;
    }

    .people-picker-icon {
        display: inline-block;
        width: 15px;
        margin-right: 5px;
        color: #1055a1;
        text-align: center;
    }

    .people-notification-container {
        position: absolute;
        z-index: 999;
    }

    .jdi-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #6f8294;
    }

    .actions-comment {
        button {
            color: #54A4FC;
            font-weight: 700;
            background: transparent;
            border: none;
            padding: 0;
        }
    }

    .form-group input:not([type="checkbox"])[disabled] {
        border-color: transparent !important;
    }

    .disabled-toggle label {
        background-color: #e6eaf2;

        &:after, &:before {
            background-color: #e6eaf2;
        }
    }
}

</style>

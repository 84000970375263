<template>
    <div>
        <div class="modal fade addItemModal addProjectListModal addActionListModal add-item-modal add-item-list-modal" id="addItemModal" tabindex="-1"
             aria-hidden="true" data-bs-backdrop="static" ref="listModal">
            <div class="modal-dialog custom-xl-modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="title">
                            <template v-if="isEditing">
                                <h5 class="modal-title">Edit {{ pageTitle }}</h5>
                                <p>
                                    Use the input fields below to edit the existing {{ pageTitle.toLowerCase() }}
                                </p>
                            </template>
                            <template v-else>
                                <h5 class="modal-title">Add new {{ pageTitle }}</h5>
                                <p>Use the input fields below to add a new {{ pageTitle.toLowerCase() }}</p>
                            </template>
                        </div>
                        <a href="#" ref="closeBtn" class="close-modal" data-bs-dismiss="modal" aria-label="Close"
                           @click="cleanForm()">Close <i class="fas fa-times"></i></a>
                    </div>
                    <div class="modal-body">
                        <form action="" class="add-item-form" style="display:block;">
                            <div class="row col-lg-12">
                                <div class="form-group" style="max-width: 400px;" @click="focusOnClick">
                                    <label for="actionListName">Title</label>
                                    <input v-model="recordTitle" placeholder="Write the name of the list" type="text"
                                           id="actionListName" maxlength="100">
                                    <div class="msg-error" v-if="!$v.recordTitle.required &amp;&amp; $v.recordTitle.$dirty">
                                        Field is required
                                    </div>

                                </div>

                                <div class="form-group" style="max-width: 150px;">
                                    <label for="">Color</label>

                                    <div class="color-point-container">
                                        <v-swatches v-model="colour" :swatches="swatches" row-length="6" shapes="circles"
                                                    show-border="" popover-x="left"></v-swatches>
                                    </div>
                                </div>

                                <!-- Owner -->
                                <div class="form-group" :class="{ 'form-group--error': $v.owner.$error }"
                                     style="max-width: 350px;" @click="focusOnClick" v-on:keydown.enter="onEnter($event)">
                                    <label for="owner" class="required-field">Owner</label>

                                    <div style="position: relative;">
                                        <div class="add-user">
                                            <people-picker :options="users" :user="owner" @change-model="selectOwner" :ignore-contact-workflow="true" ref="peoplePicker"></people-picker>
                                        </div>
                                        <div class="people-notification-container">
                                            <notification :dismiss-count="0" variant="danger" container-class message="Only an existing user can be assigned as a list owner." ref="notification"></notification>
                                        </div>
                                    </div>
                                    <div class="msg-error" v-if="!$v.owner.required &amp;&amp; $v.owner.$dirty">
                                        Field is required
                                    </div>
                                </div>

                                <!-- is private -->
                                <div class="form-group" style="max-width: 150px" id="isArchivedContainerId">
                                    <label for="isPrivate" class="required-field">Private List</label>
                                    <div class="form-check form-switch" :class="{'disabled-toggle': isPrivateToggleDisabled}">
                                        <input type="checkbox" class="form-check-input" v-model="isPrivate" name="isPrivate" id="isPrivate" data-toggle="toggle" @change="onIsPrivateChanged" :disabled="isPrivateToggleDisabled">
                                    </div>
                                </div>
                            </div>
                            <div class="row col-lg-12 form-group" style="display: block;" v-if="isPrivate">
                                <div class="add-user" style="display: block;">
                                    <multi-people-picker :options="users" @update-members="updateMembers" :users="selectedMembers" @delete-member="deleteMember" ref="multiPeoplePicker"></multi-people-picker>
                                </div>
                                <div class="msg-error" v-if="$v.selectedMembers.$invalid &amp;&amp; $v.selectedMembers.$dirty">
                                    Field is required
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <div class="modal-footer-left-container" v-if="!isCleared">
                            <div v-if="editRecord.createdBy" class="jdi-label">Created by {{editRecord.createdBy}} on {{$moment(editRecord.createdOn).format("DD-MMM-YY")}} at {{$moment(editRecord.createdOn).format("HH:mm")}} </div>
                            <div v-if="editRecord.modifiedBy" class="jdi-label">Modified by {{editRecord.modifiedBy}} on {{$moment(editRecord.modifiedOn).format("DD-MMM-YY")}} at {{$moment(editRecord.modifiedOn).format("HH:mm")}} </div>
                        </div>
                        <div class="modal-footer-rigt-container">
                            <a href="#" v-if="!isLoading" class="save-modal-button jdi-green-button" v-on:click.prevent="saveListHandler">
                                Save
                                to {{ pageTitle }}
                            </a>
                            <div v-else class="text-center">
                                <div class="spinner-border text-secondary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <reassign-contact-modal :modal-body="reassignText" name="userToReassign?.ownerName" ref="reassignModal" :ok-callback="reassignListRecords" @on-save="confirmDeleteMember" :assign-to-me="assignToMe"></reassign-contact-modal>
        <confirmation-modal message="You are about to switch this list from public to private. If you choose to proceed, all users that are assigned to a record in this list will be made members. Are you sure you want to continue?" :confirm="confirmOfMakingListPrivate" :cancel="cancelOfMakingListPrivate" :visible-close-button="false" :disabled-confirm-button="disabledConfirmButton" ref="confirmationModal"></confirmation-modal>
    </div>

</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { required, minLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import VSwatches from 'vue-swatches';

import { BaseListEntity, ListMembersDetails, MemberAssignmentDetails } from '../../Models/BaseListEntity';
import { BaseEntity } from '../../Models/BaseEntity';
import componentNavigationMixins from '../../mixins/componentNavigationMixins';
import PeoplePicker from '../PeoplePicker/PeoplePicker.vue';
import MultiPeoplePicker from '../PeoplePicker/MultiPeoplePicker.vue';
import { User } from '../../Models/User';
import { ListsService } from '../../Services/ListsService';
import ReassignContactModal from './ReassignContacts/ReassignContactModal.vue';
import { EntityService } from '../../Services/EntityService';
import { ContactToCreateFromEntity, ReassignContactModel } from '../../Models/Contact';
import Notification from '../Notification/Notification.vue';
import ConfirmationModal from './Confirmation/ConfirmationModal.vue';

declare const CurrentUserId: string;

@Component({
    mixins: [validationMixin, componentNavigationMixins],
    name: 'add-or-edit-list-modal',
    template: '#add-or-delete-list-modal-id',

    components: {
        'v-swatches': VSwatches,
        'people-picker': PeoplePicker,
        'multi-people-picker': MultiPeoplePicker,
        'reassign-contact-modal': ReassignContactModal,
        'notification': Notification,
        'confirmation-modal': ConfirmationModal,
    },
    validations: {
        recordTitle: { required },
        owner: { required },
        selectedMembers: { required, minLength: minLength(1) }
    }
})

export default class AddOrEditListModal extends Vue {
    @Prop({ default: '', type: String }) pageTitle!: string;
    @Prop({ default: '', type: String }) endpoint!: string;
    @Prop() editRecord!: BaseListEntity;
    @Prop() users!: User[];

    @Watch('isEditing') onIsEditingChanged(newVal: boolean, oldVal: boolean) {
        this.setActionData();
    }

    @Watch('editRecord') onRecordChanged(newVal: BaseEntity, oldVal: BaseEntity) {
        if (newVal && newVal.id) {
            this.isCleared = false;
        }

        if (!oldVal || (newVal && oldVal && newVal.id !== oldVal.id)) {
            this.setActionData();
            if (this.pageTitle.toLocaleLowerCase().includes('project')) {
                this.getListDetails(newVal.id);
            }
            else {
                this.getListDetails('', newVal.id);
            }
        }
    }

    @Watch('$store.state.contactToCreateFromEntity') onContactToCreateChanged(newVal: ContactToCreateFromEntity, oldVal: ContactToCreateFromEntity) {
        if (newVal && newVal.shouldBeCreated && newVal.shouldBeCreated != oldVal.shouldBeCreated) {
            (<Notification>this.$refs.notification).showNotification(5);
            this.$store.dispatch('setNewContactCreation', { shouldBeCreated: false } as ContactToCreateFromEntity);
        }
    }

    private assignToMe = true;
    private membersAssignement: MemberAssignmentDetails[] = [];
    private selectedMembers: User[] = [];
    private owner: User = {} as User;
    private isPrivate = false;
    private recordTitle = '';
    private recordId = '';
    private picked: Date = new Date();
    private colour = '#009933';
    private swatches: string[] = [
        '#4768F8',
        '#92A5F8',
        '#C7D1FB',
        '#F85377',
        '#F997AD',
        '#FCCBD6',
        '#FF8254',
        '#FEB39A',
        '#FED9CC',
        '#BC81DE',
        '#D6B3E9',
        '#EBD9F4',
        '#6ABD26',
        '#A8DA81',
        '#D3ECC0',
        '#E7C420',
        '#F0DC7F',
        '#F7EDBE',
        '#F84747',
        '#F99092',
        '#FCC7C8',
        '#39C1C1',
        '#8BDAD9',
        '#C2EAEA',
        '#30B7F6',
        '#86D5F8',
        '#C1E9FB',
        '#65C465',
        '#A4DCA5',
        '#D1EDD1',
        '#F09516',
        '#F5BE78',
        '#F9DFBB',
        '#886AEA',
        '#B8A6F0',
        '#DBD2F8',
    ];
    private userToReassign: User = {} as User;
    private isLoading = false;
    private disabledConfirmButton = false;
    private isCleared = false;

    get reassignText() {
        return `This user has ${this.isActionList() ? 'actions' : 'projects'} currently assigned to them on this list. Please reassign them to another person before continuing:`;
    }

    get isEditing() {
        return this.$store.state.isEditing;
    }

    get isPrivateToggleDisabled() {
        return this.isEditing && (!this.recordTitle || this.recordTitle.length == 0);
    }

    isActionList() {
        return this.pageTitle.toLocaleLowerCase().includes('action');
    }

    selectOwner(user: User) {
        this.owner = user;
    }

    setActionData() {
        if (this.isEditing) {
            this.recordId = this.editRecord.id;
            this.recordTitle = this.editRecord.name;
            this.colour = `#${this.editRecord.colour}`;
            this.owner = this.users.find((u) => u.id == this.editRecord.ownerId) as User;
            this.isPrivate = this.editRecord.isPrivate;
            this.selectedMembers = this.editRecord.members || [];
        }
        else {
            this.cleanForm();
        }
    }

    saveListHandler() {
        const request: BaseListEntity = {
            name: this.recordTitle,
            colour: this.colour.split('#').join(''),
            ownerId: <string>this.owner?.id,
            isPrivate: this.isPrivate,
            id: this.recordId,
            members: this.isPrivate ? this.selectedMembers : []
        };

        this.$v.$touch();
        if (!this.$v.$invalid || (this.$v.$invalid && !this.isPrivate && !this.$v.owner.$invalid && !this.$v.recordTitle.$invalid)) {
            this.saveList(request);
        }
    }

    validateAddList(request: any) {
        for (const prop in request) {
            if (request[prop] == undefined || request[prop] == '') {
                return false;
            }
        }
        return true;
    }

    updateMembers(users: User[]) {
        this.selectedMembers = users;
    }

    async reassignListRecords(model: ReassignContactModel) {
        if (this.isActionList()) {
            return await EntityService.ReassignActions(model, this.editRecord.id);
        }
        else {
            return await EntityService.ReassignProjects(model, this.editRecord.id);
        }
    }

    async checkForExistingListMembers() {
        let response;
        this.isLoading = true;
        if (this.isActionList()) {
            response = await ListsService.CheckForExistingListMembers(undefined, this.editRecord.id);
        }
        else {
            response = await ListsService.CheckForExistingListMembers(this.editRecord.id);
        }

        if (response && response.data && response.data.success) {
            this.$v.$touch();
            const element = this.$refs && (<Vue>this.$refs.confirmationModal).$el;
            this.disabledConfirmButton = this.$v.owner.$invalid || this.$v.recordTitle.$invalid;
            $(element).modal('show');

        }
        this.isLoading = false;
    }


    deleteMember(user: User) {
        this.userToReassign = user;

        const assignment = this.membersAssignement.find((a) => a.id == user.id);
        if (assignment && assignment.hasAssignedItems) {
            this.$store.dispatch('setReassignContact', user);
            this.assignToMe = CurrentUserId == user.id ? false : this.selectedMembers.some((t) => t.id == CurrentUserId);
            const element = this.$refs && (<Vue>this.$refs.reassignModal).$el;
            $(element).modal('show');
        }
        else {
            this.confirmDeleteMember();
        }
    }

    confirmDeleteMember() {
        (<MultiPeoplePicker>this.$refs.multiPeoplePicker).confirmDeleteUser(this.userToReassign);
    }

    async saveList(request: BaseListEntity) {
        if (this.isEditing) {
            await ListsService.EditList(this.endpoint, request)
                .then((res) => {
                    if (res.data.success) {
                        (this.$refs.closeBtn as HTMLElement).click();
                        this.$store.dispatch(`setRefresh${this.endpoint}s`, true);
                        this.$router.push({ name: <string>this.$route.name }).catch((er) => er);

                    }
                    else {
                        alert(res.data.message);
                        this.$store.dispatch(`setRefresh${this.endpoint}s`, true);
                    }
                })
                .catch((e) => console.log(e.message));
        }
        else {
            await ListsService.CreateList(this.endpoint, request)
                .then((res) => {
                    if (res.data.success) {
                        (this.$refs.closeBtn as HTMLElement).click();
                        this.$store.dispatch(`setRefresh${this.endpoint}s`, true);
                        this.$router.push({ name: <string>this.$route.name }).catch((er) => er);
                    }
                    else {
                        alert(res.data.message);
                    }
                })
                .catch((e) => console.log(e.message));
        }
    }

    async getListDetails(projectListId?: string, actionListId?: string) {
        try {
            const res = await ListsService.GetListDetails(projectListId, actionListId);
            await this.$store.dispatch('setOwners', (<ListMembersDetails>res.data).owners);
            this.membersAssignement = (<ListMembersDetails>res.data).members;
        }
        catch (e: any) {
            await this.$store.dispatch('setOwners', []);
            console.log(e.message);
        }
    }

    async confirmOfMakingListPrivate() {
        const request: BaseListEntity = {
            name: this.recordTitle,
            colour: this.colour.split('#').join(''),
            ownerId: <string>this.owner?.id,
            isPrivate: this.isPrivate,
            id: this.recordId,
            members: this.isPrivate ? this.selectedMembers : []
        };

        this.saveList(request);
    }

    async cancelOfMakingListPrivate() {
        this.isPrivate = false;
    }

    cleanForm() {
        // the following assignment caused a Vue warning in the console, so we have to use isCleared flag instead
        //this.editRecord = {} as BaseListEntity;
        this.isCleared = true;

        this.recordId = '';
        this.recordTitle = '';
        this.owner = {} as User;
        this.isPrivate = false;
        this.selectedMembers = [] as User[];
        this.$store.dispatch('setEdit', false);
        this.$v.$reset();
        (<Notification>this.$refs.notification) && (<Notification>this.$refs.notification).showNotification(0);
        if (!this.editRecord.isView) {
            this.$router.push({ name: <string>this.$route.name }).catch((er) => er);
        }
    }

    onIsPrivateChanged() {
        if (this.isPrivate && this.editRecord.id) {
            this.checkForExistingListMembers();
        }
    }

    mounted() {
        const me = this;
        $(<Element>this.$refs.listModal).on('hidden.bs.modal', function () {
            me.cleanForm();
        });
    }
}
</script>

import { Vue, Prop, Component } from 'vue-property-decorator';

@Component({
    name: 'bs-form-input',
    render: function (this: BsTextInput, h) {
        return h(
            'input',
            {
                staticClass: 'form-control',
                attrs: {
                    type: 'text',
                    value: this.value,
                },
                /*on: {
                    input: (event: Event) => {
                        this.$emit('input', (<HTMLInputElement>event.target).value);
                    }
                }*/
            }
        );
    },
})

export default class BsTextInput extends Vue {
    @Prop() private value!: string;

}

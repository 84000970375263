<template>
    <div class="list-table">
        <table class="medmouds-table contacts-table">
            <tbody>
                <tr>
                    <th>
                        <a href="#" class="table-title" :class="{
        active: order.columnName === 'contacts',
        asc: order.direction === 'asc',
        desc: order.direction === 'desc',
        }" @click.prevent="sortTable(['name'], 'string', 'contacts', '')">
                            <span>Name</span>
                            <i class="fas fa-chevron-down"></i>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="table-title" :class="{
        active: order.columnName === 'Comments',
        asc: order.direction === 'asc',
        desc: order.direction === 'desc',
        }" @click.prevent="
        sortTable(['comments', 0, 'text'], 'string', 'Comments', '')
        "><span>Email</span> <i class="fas fa-chevron-down"></i></a>
                    </th>
                    <th v-if="!isArchivedTable"></th>
                </tr>

                <tr v-for="(contact, index) in tableData" :key="'contact-' + index">
                    <td style="min-width: 300px;">
                        <div class="check-item">
                            <a href="#" class="jdi-tooltip"
                               @click.prevent="archiveContact(contact.id, !isArchivedTable)">
                                <i class="far fa-check-circle" v-if="!isArchivedTable"></i>
                                <span class="jdi-tooltiptext" v-if="!isArchivedTable">Archive this contact</span>

                                <i class="far fa-check-circle complete" v-if="isArchivedTable"></i>
                                <span class="jdi-tooltiptext" v-if="isArchivedTable">Unarchive this contact</span>
                            </a>
                            <span>{{ contact.name }}</span>
                        </div>
                    </td>

                    <td style="min-width: 200px;">
                        <span>{{ contact.email }}</span>
                    </td>


                    <td v-if="!isArchivedTable" style="width: 100px;">
                        <div class="options-item">
                            <a href="#addContactModal" @click="editContact(contact.id)"
                               data-bs-toggle="modal" class="me-3"><i class="far fa-edit fontawesome-icon"></i></a>

                            <a href="#reassignContactModal" data-bs-toggle="modal" class="me-3"
                               @click="reassignContact(contact.id)">
                                <i class="far fa-solid fa-people-arrows-left-right fontawesome-icon"></i>
                            </a>

                            <a href="#reassignContactModal" data-bs-toggle="modal"
                               @click="deleteContact(contact.id, index)">
                                <i class="far fa-trash-alt fontawesome-icon"></i>
                            </a>
                        </div>
                    </td>
                    <td v-else style="width: 100px;">
                        <div class="options-item">
                            <a href="#addContactModal" @click="editContact(contact.id)"
                               data-bs-toggle="modal" class="me-3"><i class="far fa-edit fontawesome-icon"></i></a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- Modals-->
        <reassign-contact-modal :contact-name="contactToEdit.name"></reassign-contact-modal>
    </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import BaseTable from './BaseTable';
import { Contact } from '../../Models/Contact';
import DeleteModal from '../Modals/DeleteModal.vue';
import { ContactsService } from '../../Services/ContactsService';
import ReassignContactModal from '../Modals/ReassignContacts/ReassignContactModal.vue';

@Component({
    name: 'contacts-table',
    template: '#contacts-table-id',
    components: {
        'delete-modal': DeleteModal,
        'reassign-contact-modal': ReassignContactModal,
    }
})

export default class ContactsTable extends BaseTable {
    @Prop({ default: true, type: Boolean }) isArchivedTable!: boolean;
    @Prop() tableData!: Contact[];
    private contactToEdit: Contact = {} as Contact;

    async archiveContact(id: string, isArchived: boolean): Promise<void> {
        const contact: Contact = this.tableData.find((d) => d.id == id) as Contact;
        contact.archived = isArchived;
        const res = await ContactsService.Edit(contact);
        if (res.data.success) {
            this.$store.dispatch('setRefreshContacts', true);
        }
        else {
            alert(res.data.message);
        }
        this.getOwners();
    }

    async getOwners() {
        try {
            const res = await ContactsService.GetOwners();
            await this.$store.dispatch('setOwners', res.data);
        }
        catch (e: any) {
            await this.$store.dispatch('setOwners', []);
            console.log(e.message);
        }
    }

    editContact(id: string) {
        this.contactToEdit = this.getContact(id);
        this.$store.dispatch('setEditContact', this.contactToEdit);
        if (!this.$route.query.id || id != this.$route.query.id) {
            this.$router.push({ name: <string>this.$route.name, query: { id } });
        }
    }

    reassignContact(id: string) {
        this.contactToEdit = this.getContact(id);
        this.$store.dispatch('setReassignContact', this.contactToEdit);
    }

    deleteContact(id: string) {
        this.contactToEdit = this.getContact(id);
        this.$store.dispatch('setDeleteContact', this.contactToEdit);
    }

    getContact(id: string): Contact {
        return this.tableData.find((c) => c.id == id) as Contact;
    }
}
</script>

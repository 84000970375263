<template>
    <layout-default :page-title="pageTitle">
        <div class="main-table-page">
            <div class="main">
                <div class="ajax-loading" v-if="loading"></div>
                <div v-else class="table-container">
                    <entity-table :table-data="projects" :lists="projectLists"
                                  table-titles="projects"></entity-table>
                </div>
            </div>
        </div>
    </layout-default>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';

import { Project } from '../Models/Project';
import { ProjectList } from '../Models/ProjectList';

import LayoutDefault from '../Layouts/LayoutDefault.vue';
import EntityTable from '../Components/EntityTable.vue';
import { TabNames } from '../Models/Enums';
import ApiRequestor from '../Services/ApiRequestor';

@Component({
    name: 'projects',
    template: '#projects-id',
    components: {
        'layout-default': LayoutDefault,
        'entity-table': EntityTable
    },
    async created() {
        await this.$store.commit('setLoading', true);
    }
})

export default class Projects extends Vue {
    private pageTitle = 'Projects';
    private projects: Project[] = [];
    private projectLists: ProjectList[] = [];

    @Watch('$store.state.isRefreshingProjects') onIsRefreshingChanged(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.getProjects();
        }
    }

    getProjects(): void {
        this.$store.dispatch('setLoading', true);

        ApiRequestor.get('GetProjects')
            .then((res) => {
                this.projects = res.data;
                this.getProjectLists();
                this.$store.dispatch('setProjects', res.data);
                this.$store.dispatch('setEntityLength', this.projects.length);
            })
            .catch((e) => {
                console.log(e.message);
                this.$store.dispatch('setLoading', false);
            });
    }

    async getProjectLists(): Promise<void> {
        await ApiRequestor.get('GetOwnerProjectLists')
            .then((res) => {
                this.projectLists = res.data;
                this.$store.dispatch('setRefreshProjects', false);
            })
            .catch((e) => {
                this.$store.dispatch('setRefreshProjects', false);
                console.log(e.message);
            });
        await this.$store.commit('setLoading', false);
    }

    mounted(): void {
        this.$store.dispatch('actionSetSelectedListFromFilter', '');
        this.getProjects();
    }

    beforeCreate() {
        // the following approach can be used to open proper tab based on the route
        //this.$store.dispatch('setActiveTab', this.$route.params.state == 'archived' ? TabNames.archivedList : TabNames.activeList);
        this.$store.dispatch('setActiveTab', TabNames.activeList);
    }

    get loading(): boolean {
        return this.$store.state.loading;
    }
}
</script>


export enum ProjectStatus {
    Green = 1,
    Amber = 2,
    Red = 3
}

export enum AssignType {
    User = 0,
    Contact = 1
}

export enum DirectionReference {
    asc = 'desc',
    desc = 'asc'
}

export enum TabNames {
    activeList = 1,
    archivedList = 2
}

export enum ContactWorkflow {
    disabled = 1,
    enabled = 2
}


import Vue from "vue";
import VueRouter, { RouteConfig } from 'vue-router';

import Home from '../Views/Home.vue';
import Actions from '../Views/Actions.vue';
import Project from '../Views/Projects.vue';
import ActionLists from '../Views/ActionLists.vue';
import ProjectLists from '../Views/ProjectLists.vue';
import Contacts from '../Views/Contacts.vue';

Vue.use(VueRouter);

declare const JdiTenantName: string;
declare const IsContactsDisabled: boolean;

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/actions',
        name: 'actions',
        component: Actions
    },
    // TODO: we can implement a route for the list of archived projects and specific project GUID
    // path: '/projects/:state(archived)/:id(.{36})?',
    {
        path: '/projects',
        name: 'projects',
        component: Project
    },
    {
        path: '/action-lists',
        name: 'Action Lists',
        component: ActionLists
    },
    {
        path: '/project-lists',
        name: 'Project Lists',
        component: ProjectLists
    },
];

if (!IsContactsDisabled) {
    routes.push(
        {
            path: '/contacts',
            name: 'Contacts',
            component: Contacts
        });
}
else {
    routes.push(
        {
            path: '/contacts',
            redirect: (to) => {
                return { path: '/', query: { } };
            }
        });
}

const router = new VueRouter({
    base: '/' + JdiTenantName,
    mode: 'history',
    routes
});

export default router;


import type { AxiosResponse } from 'axios';
import { Contact, ReassignContactModel } from '../Models/Contact';
import ApiRequestor from './ApiRequestor';

export class ContactsService {
    public static async Edit(request: Contact) {
        return await ApiRequestor.put(`EditContact/${request.id}`, request);
    }

    public static async Add(request: Contact) {
        return await ApiRequestor.post('AddContact', request);
    }

    // TODO: declare return type for every GET
    public static async GetContacts(): Promise<AxiosResponse<Contact[]>> {
        return await ApiRequestor.get('GetContacts');
    }

    public static async ReassignContact(request: ReassignContactModel) {
        return await ApiRequestor.post('ReassignContact', request);
    }

    public static async DeleteContact(request: ReassignContactModel) {
        return await ApiRequestor.post('DeleteContact', request);
    }

    public static async GetOwners(projectListId?: string, actionListId?: string) {
        const params = projectListId && projectListId.length > 0 ? `?projectListId=${projectListId}` : actionListId && actionListId.length > 0 ? `?actionListId=${actionListId}` : '';
        return await ApiRequestor.get(`GetOwners${params}`);
    }
}

<template>
    <div class="dji-module layout-default">
        <div class="dji-module layout-default">
            <main-header :title="pageTitle" :tabs-in-header="tabsInHeader" :show-add-new-item="showAddNewItem" :show-search="showSearch" v-if="actionHeader"></main-header>
            <slot></slot>
            <main-footer :title="pageTitle"></main-footer>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import MainHeader from './MainHeader.vue';
import MainFooter from './MainFooter.vue';

@Component({
    name: 'layout-default',
    components: {
        'main-header': MainHeader,
        'main-footer': MainFooter
    }
})
export default class LayoutDefault extends Vue {
    @Prop({ default: true }) actionHeader!: boolean;
    @Prop({ default: '' }) pageTitle!: string;
    @Prop({ default: true }) tabsInHeader!: boolean;
    @Prop({ default: true }) showListFilter!: boolean;
    @Prop({ default: true, type: Boolean }) showAddNewItem!: boolean;
    @Prop({ default: true, type: Boolean }) showSearch!: boolean;

    private currentPage = '';

    goToActionPage(): void {
        const path = 'Actions';
        if (this.$route.path !== path) {
            this.$router.push({ path: path })
                .catch(() => { });
        }
    }

    goToProjectPage(): void {
        const path = 'Projects';
        if (this.$route.path !== path) {
            this.$router.push({ path: path })
                .catch(() => { });
        }
    }

    mounted(): void {
        document.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.altKey && e.code === 'KeyA') {
                e.preventDefault();
                this.goToActionPage();
            }
            if (e.altKey && e.code === 'KeyP') {
                e.preventDefault();
                this.goToProjectPage();
            }
        });

        setInterval(() => {
            if (this.currentPage != location.href) {
                this.currentPage = location.href;

                //if (location.href.toLowerCase().endsWith('jdi/index')) {
                //    location.href = location.href + "#/";
                //}

                const pageName = this.getPageName(location.hash);
                console.log('Page Name: ' + pageName);
                this.updateBreadCrumb(pageName);
            }
        }, 500);
    }

    getPageName(pageHash: string): string {
        if (pageHash.indexOf('actions') >= 0) {
            return 'Actions';
        }
        else if (pageHash.indexOf('projects') >= 0) {
            return 'Projects';
        }
        else if (pageHash.indexOf('action-lists') >= 0) {
            return 'Action Lists';
        }
        else if (pageHash.indexOf('project-lists') >= 0) {
            return 'Project Lists';
        }
        else {
            return 'Home';
        }
    }

    updateBreadCrumb(pageName: string) {
        $('#bcModuleLinks').replaceWith('<li id="bcModuleLinks"><span>' + pageName + '</span></li>');
    }
}

</script>

<style lang="less">

.layout-default {
    position: relative;
    /*min-height: 100vh;*/
}

.main-table-page {
    padding: 150px 0 125px;
}

</style>

<template>
    <div>
        <table class="medmouds-table project-table">
            <tbody>
                <tr>
                    <th>
                        <a href="#" class="table-title" :class="{
        active: order.columnName === 'Title',
        asc: order.direction === 'asc',
        desc: order.direction === 'desc',
        }" @click.prevent="sortTable(['name'], 'string', 'Title','')">
                            <span>Title</span>
                            <i class="fas fa-chevron-down"></i>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="table-title" :class="{
        active: order.columnName === 'Lead',
        asc: order.direction === 'asc',
        desc: order.direction === 'desc',
        }" @click.prevent="sortTable(['ownerName'], 'string', 'Lead','')">
                            <span>Lead</span>
                            <i class="fas fa-chevron-down"></i>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="table-title" :class="{
        active: order.columnName === 'Measure',
        asc: order.direction === 'asc',
        desc: order.direction === 'desc',
        }" @click.prevent="sortTable(['measure'], 'string', 'Measure','')">
                            <span>Measure</span>
                            <i class="fas fa-chevron-down"></i>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="table-title" :class="{
        active: order.columnName === 'Status',
        asc: order.direction === 'asc',
        desc: order.direction === 'desc',
        }" @click.prevent="
        sortTable(['status', 'statusName'], 'string', 'Status','')
        "><span>Status</span> <i class="fas fa-chevron-down"></i></a>
                    </th>
                    <th>
                        <a href="#" class="table-title" :class="{
        active: order.columnName === 'Issues',
        asc: order.direction === 'asc',
        desc: order.direction === 'desc',
        }" @click.prevent="sortTable(['issues'], 'string', 'Issues','')">
                            <span>Issues</span>
                            <i class="fas fa-chevron-down"></i>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="table-title" :class="{
        active: order.columnName === 'List',
        asc: order.direction === 'asc',
        desc: order.direction === 'desc',
        }" @click.prevent="sortTable(['list', 'name'], 'string', 'List','')">
                            <span>List</span>
                            <i class="fas fa-chevron-down"></i>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="table-title" :class="{
        active: order.columnName === 'Next Steps',
        asc: order.direction === 'asc',
        desc: order.direction === 'desc',
        }" @click.prevent="
        sortTable(['nextSteps'], 'string', 'Next Steps','')
        "><span>Next Steps</span> <i class="fas fa-chevron-down"></i></a>
                    </th>
                    <th v-if="!isArchivedTable">
                        <a href="#" class="table-title" :class="{
        active: order.columnName === 'Date',
        asc: order.direction === 'asc',
        desc: order.direction === 'desc',
        }" @click.prevent="sortTable(['nextStepDate'], 'date', 'Date','')">
                            <span>Date</span>
                            <i class="fas fa-chevron-down"></i>
                        </a>
                    </th>
                    <th v-if="isArchivedTable">
                        <a href="#" class="table-title" :class="{
    active: order.columnName === 'Close Date',
    asc: order.direction === 'asc',
    desc: order.direction === 'desc',
    }" @click.prevent="sortTable(['modifiedOn'], 'date', 'Close Date')">
                            <span>Close Date</span> <i class="fas fa-chevron-down"></i>
                        </a>
                    </th>

                </tr>

                <tr v-for="(project, index) in tableData" :key="'project-' + index">
                    <td>
                        <div class="check-item" style="width: 130px">
                            <a href="#" class="jdi-tooltip"
                               @click.prevent="archiveProject(project.id, !isArchivedTable)">
                                <i class="far fa-check-circle" v-if="!isArchivedTable"></i>
                                <span class="jdi-tooltiptext" v-if="!isArchivedTable">Archive this project</span>

                                <i class="far fa-check-circle complete" v-if="isArchivedTable"></i>
                                <span class="jdi-tooltiptext" v-if="isArchivedTable">Unarchive this project</span>
                            </a>
                            <span>{{ project.name }}</span>
                        </div>
                    </td>

                    <td width="160px">
                        <user-picture :user-id="project.ownerId" :user-name="project.ownerName" :is-profile-image-uploaded="project.isOwnerImageUploaded"
                                      :user-type="project.ownerType"></user-picture>
                    </td>

                    <td>
                        <div>{{ project.measure }}</div>
                    </td>

                    <td>
                        <div class="work-status" :class="{active: project.status === 1, delayed: project.status === 2, stopped: project.status === 3 }">
                            <span v-if="project.status === 1"><i class="fas fa-circle"></i></span>
                            <span v-else-if="project.status === 2"><i class="fas fa-triangle"></i></span>
                            <span v-else-if="project.status === 3"><i class="fas fa-diamond"></i></span>
                        </div>
                    </td>

                    <td>
                        <template v-if="project.issues">
                            <read-more more-str="View more" :text="project.issues" link="#" less-str="View less"
                                       :max-chars="50"></read-more>
                        </template>
                    </td>

                    <td style="width: 130px;">
                        <div class="category-list jdi-tooltip" :style="'background: #' + project.list.colour">
                            {{truncate(project.list.name )}}
                            <span class="jdi-tooltiptext">{{project.list.name}}</span>
                        </div>
                    </td>

                    <td>
                        <read-more more-str="View more" :text="project.nextSteps" link="#" less-str="View less"
                                   :max-chars="50"></read-more>
                    </td>

                    <td v-if="!isArchivedTable">
                        <span>{{ $moment(project.nextStepDate).format("DD-MMM-YY") }}</span>
                    </td>
                    <td v-if="isArchivedTable">
                        <span>{{ $moment(project.modifiedOn).format("DD-MMM-YY") }}</span>
                    </td>

                    <td v-if="!isArchivedTable">
                        <div class="options-item">
                            <a href="#addProjectModal" @click="editProject(project.id)"
                               data-bs-toggle="modal"><i class="far fa-edit fontawesome-icon"></i></a>
                            <a href="#deleteItemModal" data-bs-toggle="modal"
                               @click="deleteProject(project.id, index)">
                                <i class="far fa-trash-alt fontawesome-icon"></i>
                            </a>
                        </div>
                    </td>
                    <td v-else>
                        <div class="options-item">
                            <a href="#viewProjectModal" @click="viewProject(project.id)"
                               data-bs-toggle="modal"><i class="far fa-edit fontawesome-icon"></i>
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <reassign-contact-modal @on-save="confirm" :ok-callback="reassignProject" modal-title="Reassign Record" modal-body="This archived project was asigned to a user who is no longer a member of this list. Please reassign this project to another person before proceeding." :assign-to-me="assignToMe" ref="reassignModal"></reassign-contact-modal>
    </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { BaseEntity } from '../../Models/BaseEntity';
import BaseTable from './BaseTable';
import UserPicture from '../UserPicture/UserPicture.vue';
import { DeleteRegisterItem } from '../../Models/BaseEntity';
import ReadMore from '../ReadMore.vue';
import { EntityService } from '../../Services/EntityService';
import { User } from '../../Models/User';
import ReassignContactModal from '../Modals/ReassignContacts/ReassignContactModal.vue';
import { ReassignContactModel } from '../../Models/Contact';
declare const CurrentUserId: string;

@Component({
    name: 'projects-table',
    template: '#projects-table-id',
    components: {
        'user-picture': UserPicture,
        'read-more': ReadMore,
        'reassign-contact-modal': ReassignContactModal,
    }
})

export default class ProjectsTable extends BaseTable {
    @Prop({ default: true, type: Boolean }) isArchivedTable!: boolean;
    @Prop() tableData!: BaseEntity[];
    private endpoint = 'Project';
    private ActionIdToUnarchive = '';
    private assignToMe = true;

    async archiveProject(id: string, isArchived: boolean) {
        try {
            this.ActionIdToUnarchive = id;

            const item: BaseEntity = this.tableData.find((item) => item.id == id) as BaseEntity;
            const request = { ...item };
            request.archived = isArchived;

            const result = await EntityService.Edit(this.endpoint, request);

            if (result.data.success) {
                this.$store.dispatch(`setRefreshProjects`, true);
                this.$store.dispatch('actionSetSelectedListFromFilter', this.$store.state.filterLists);
                item.archived = isArchived;
            }
            else if (!result.data.success && result.data.code == 1 && !isArchived) {
                const user: User = {
                    id: request.ownerId,
                    ownerName: request.ownerName,
                };
                await this.$store.dispatch('setOwners', result.data.data);
                await this.$store.dispatch('setReassignContact', user);
                this.assignToMe = CurrentUserId == user.id ? false : (result.data.data && result.data.data.some((t: User) => t.id == CurrentUserId));
                const element = this.$refs && (<Vue>this.$refs.reassignModal).$el;
                $(element).modal('show');
            }
            else {
                alert(result.data.message);
            }
        }
        catch (e) {
            alert('Something went wrong. Please try later.');
        }
    }

    async reassignProject(model: ReassignContactModel) {
        return await EntityService.ReassignProjectAndUnarchive(model, this.ActionIdToUnarchive);
    }

    async confirm() {
        this.$store.dispatch(`setRefreshProjects`, true);
    }

    editProject(id: string) {
        this.changeUrl('projects', id);
        this.editRegister(this.tableData, id);
    }

    viewProject(id: string) {
        this.changeUrl('projects', id);
        this.viewRegister(this.tableData, id);
    }

    deleteProject(id: string, index: number): void {
        this.$store.dispatch('setDeleteRegisterItem', { registerId: id, deleteEndpoint: this.endpoint, index } as DeleteRegisterItem);
        this.$store.dispatch('actionSetSelectedListFromFilter', this.$store.state.filterLists);
    }

    open(id: string): boolean | null {
        this.editRecord = this.getRegister(this.$store.state.projects, id);
        if (this.editRecord) {
            if (this.editRecord.archived) {
                this.$store.dispatch('setViewRegiterItem', this.editRecord);
                return true;
            }
            else {
                this.$store.dispatch('setEditRegisterItem', this.editRecord);
                this.$store.dispatch('setEdit', true);
                return false;
            }
        }
        else {
            console.log('Item is not found');
        }
        return null;
    }
}
</script>

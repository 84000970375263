
declare let UserTimezoneOffset: number;

export class DateTimeHelper {
    public static readonly monthNames = [
        _ls('MonthShort:Jan', 'Jan'),
        _ls('MonthShort:Feb', 'Feb'),
        _ls('MonthShort:Mar', 'Mar'),
        _ls('MonthShort:Apr', 'Apr'),
        _ls('MonthShort:May', 'May'),
        _ls('MonthShort:Jun', 'Jun'),
        _ls('MonthShort:Jul', 'Jul'),
        _ls('MonthShort:Aug', 'Aug'),
        _ls('MonthShort:Sep', 'Sep'),
        _ls('MonthShort:Oct', 'Oct'),
        _ls('MonthShort:Nov', 'Nov'),
        _ls('MonthShort:Dec', 'Dec')
    ];
    public static readonly monthNamesFull = [
        _ls('Month:January', 'January'),
        _ls('Month:February', 'February'),
        _ls('Month:March', 'March'),
        _ls('Month:April', 'April'),
        _ls('Month:May', 'May'),
        _ls('Month:June', 'June'),
        _ls('Month:July', 'July'),
        _ls('Month:August', 'August'),
        _ls('Month:September', 'September'),
        _ls('Month:October', 'October'),
        _ls('Month:November', 'November'),
        _ls('Month:December', 'December'),
    ];
    public static readonly weekDays = [
        _ls('Weekday:Sun', 'Sun'),
        _ls('Weekday:Mon', 'Mon'),
        _ls('Weekday:Tue', 'Tue'),
        _ls('Weekday:Wed', 'Wed'),
        _ls('Weekday:Thu', 'Thu'),
        _ls('Weekday:Fri', 'Fri'),
        _ls('Weekday:Sat', 'Sat'),
    ];
    public static readonly weekDaysFull = [
        _ls('Weekday:Sunday', 'Sunday'),
        _ls('Weekday:Monday', 'Monday'),
        _ls('Weekday:Tuesday', 'Tuesday'),
        _ls('Weekday:Wednesday', 'Wednesday'),
        _ls('Weekday:Thursday', 'Thursday'),
        _ls('Weekday:Friday', 'Friday'),
        _ls('Weekday:Saturday', 'Saturday'),
    ];
    public static readonly weekDaysShort = [
        _ls('Weekday:Su', 'Su'),
        _ls('Weekday:Mo', 'Mo'),
        _ls('Weekday:Tu', 'Tu'),
        _ls('Weekday:We', 'We'),
        _ls('Weekday:Th', 'Th'),
        _ls('Weekday:Fr', 'Fr'),
        _ls('Weekday:Sa', 'Sa'),
    ];
    public static readonly weekdayIds = [1, 2, 3, 4, 5, 6, 0]; // weekday numbers (Mon-Sat) as defined in JS
    public static readonly mondayId = 1;

    // format of time values expected from back-end as string to be parsed by moment.js
    public static readonly momentTimeFormat = 'HH:mm';

    // TODO: DateOnly class could be useful for parsed date strings without time part
    public static parseDate(isoDate: string): Date {
        // Zero time part is needed, because otherwise
        // date string without the time part automatically gets converted from UTC to current timezone, thus changing date
        return new Date(isoDate.includes('T') ? isoDate : isoDate + 'T00:00');
    }

    // the method automtically adjusts supplied UTC date/time accodring to user's configured timezone or browser's timezone
    // timezone offset still exists in date object after the conversion and must be ignored in calculations
    public static isoDateToCurTimezone(utcDateIsoStr: string): Date {
        const tzOffsetMinutes = UserTimezoneOffset ? -UserTimezoneOffset : (new Date()).getTimezoneOffset();
        const tzOffset = tzOffsetMinutes * 60 * 1000;
        const date = DateTimeHelper.parseDate(utcDateIsoStr);
        return new Date(date.getTime() - tzOffset);
    }

    // this method performs reverse timezone conversion compared to isoDateToCurTimezone()
    public static curTimezoneDateToUtc(dateIsoStr: string) {
        const tzOffsetMinutes = UserTimezoneOffset ? -UserTimezoneOffset : (new Date()).getTimezoneOffset();
        const tzOffset = tzOffsetMinutes * 60 * 1000;
        const date = DateTimeHelper.parseDate(dateIsoStr);
        return new Date(date.getTime() + tzOffset);
    }

    public static isoTimeToCurTimezone(isoTime: string): Date {
        return DateTimeHelper.isoDateToCurTimezone('2020-01-01T' + isoTime);
    }

    public static formatDateUk(date: Date, withYear = true): string {
        return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + (withYear ? '/' + date.getFullYear() : '');
    }

    public static formatDateShort(date: Date): string {
        return ('0' + date.getDate()).slice(-2) + '-' + DateTimeHelper.monthNames[date.getMonth()] + '-' + date.getFullYear().toString().substr(-2);
    }

    public static formatDateIso(date: Date) {
        return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
    }

    public static formatTimeAmPm(date: Date): string {
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        return hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + ampm;
    }

    public static formatTimeHHmm(date: Date): string {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
    }

    public static timeAmPmTo24Hr(timePickerVal: string): string {
        const [time, modifier] = timePickerVal.split(' ');
        const [hours12, minutes] = time.split(':');
        let hours = hours12;
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = (parseInt(hours, 10) + 12).toString();
        }

        return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2);
    }

    public static formatDateTimeUk(date: Date): string {

        return DateTimeHelper.formatDateUk(date) + ' ' + DateTimeHelper.formatTimeAmPm(date);
    }

    public static isoToUkDate(isoDate: string) {
        if (isoDate == null) {
            return '';
        }
        else {
            const date: Date = DateTimeHelper.isoDateToCurTimezone(isoDate);
            return DateTimeHelper.formatDateUk(date);
        }
    }

    public static isoToUkDateTime(isoDate: string) {
        if (isoDate == null) {
            return '';
        }
        else {
            const date: Date = DateTimeHelper.isoDateToCurTimezone(isoDate);
            return DateTimeHelper.formatDateTimeUk(date);
        }
    }

    public static isoTimeToAmPm(isoTime: string) {
        const date: Date = DateTimeHelper.parseDate('2020-01-01T' + isoTime);
        return DateTimeHelper.formatTimeAmPm(date);
    }

    public static ukToIsoDate(ukDate: string): string {
        const dateParts = ukDate.split('/');
        if (dateParts.length < 3) {
            return '';
        }
        return dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
    }

    public static curDateAsIsoString() {
        // removing Z because all date/time strings are considered UTC in calculations
        return (new Date()).toISOString().replace('Z', '');
    }

    public static getTimeWoSeconds(time: string) {
        const timeLength = '00:00'.length;
        return time.substr(0, timeLength);
    }

    // TODO: this is not used anymore
    public static nthDayString(date = new Date()) {
        const dayname = DateTimeHelper.weekDaysFull[date.getDay()];
        const numberOfWeekday = Math.ceil(date.getDate() / 7);
        const nth = numberOfWeekday + (['st', 'nd', 'rd'][((numberOfWeekday + 90) % 100 - 10) % 10 - 1] || 'th');
        return `${nth} ${dayname}`;
    }
}

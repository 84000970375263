
import { AssignType } from './Enums';
import {TableItem} from './TableItem';
import { User } from './User';

export interface BaseListEntity extends TableItem {
    id: string;
    name: string;
    colour: string;
    createdBy?: string;
    createdOn?: Date;
    modifiedBy?: string;
    modifiedOn?: Date;
    expireToday?: number;
    onTrack?: number;
    delayed?: number;
    isPrivate: boolean;
    isOwner?: boolean;
    members: User[];
    isAvailableForCurrentUser?: boolean;
    isView?: boolean;
    ownerId: string;
    ownerName?: string;
    ownerType?: AssignType;
    isOwnerImageUploaded?: boolean;
}

export const BaseListEntityDefault: BaseListEntity = {
    id: '',
    name: '',
    colour: '',
    isPrivate: false,
    members: [],
    ownerId: ''
};

export interface ListMembersDetails {
    owners: User[];
    members: MemberAssignmentDetails[];
}

export interface MemberAssignmentDetails extends User {
    hasAssignedItems: boolean;
}

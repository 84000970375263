<template>
    <div>
        <table class="medmouds-table actions-table">
            <tbody>
                <tr>
                    <th>
                        <a href="#" class="table-title" :class="{
        active: order.columnName === 'Actions',
        asc: order.direction === 'asc',
        desc: order.direction === 'desc',
        }" @click.prevent="sortTable(['name'], 'string', 'Actions', '')">
                            <span>Actions</span>
                            <i class="fas fa-chevron-down"></i>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="table-title" :class="{
        active: order.columnName === 'Comments',
        asc: order.direction === 'asc',
        desc: order.direction === 'desc',
        }" @click.prevent="
        sortTable(['comments', 0, 'text'], 'string', 'Comments', '')
        "><span>Comments</span> <i class="fas fa-chevron-down"></i></a>
                    </th>
                    <th>
                        <a href="#" class="table-title" :class="{
        active: order.columnName === 'Responsibility',
        asc: order.direction === 'asc',
        desc: order.direction === 'desc',
        }" @click.prevent="
        sortTable(['ownerName'], 'string', 'Responsibility','')
        ">
                            <span>Responsibility</span>
                            <i class="fas fa-chevron-down"></i>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="table-title" :class="{
        active: order.columnName === 'List',
        asc: order.direction === 'asc',
        desc: order.direction === 'desc',
        }" @click.prevent="sortTable(['list', 'name'], 'string', 'List','')">
                            <span>List</span>
                            <i class="fas fa-chevron-down"></i>
                        </a>
                    </th>
                    <th v-if="!isArchivedTable">
                        <a href="#" class="table-title" :class="{
        active: order.columnName === 'Target date',
        asc: order.direction === 'asc',
        desc: order.direction === 'desc',
        }" @click.prevent="
        sortTable(['targetDate'], 'date', 'Target date', '')
        "><span>Target date</span> <i class="fas fa-chevron-down"></i></a>
                    </th>

                    <th v-if="isArchivedTable">
                        <a href="#" class="table-title" :class="{
        active: order.columnName === 'Close date',
        asc: order.direction === 'asc',
        desc: order.direction === 'desc',
        }" @click.prevent="
        sortTable(['modifiedOn'], 'date', 'Close date','')
        "><span>Close date</span> <i class="fas fa-chevron-down"></i></a>
                    </th>
                    <th v-if="!isArchivedTable"></th>
                </tr>

                <tr v-for="(action, index) in tableData" :key="'action-' + index">
                    <td style="width: 300px;">
                        <div class="check-item">
                            <a href="#" class="jdi-tooltip"
                               @click.prevent="archiveAction(action.id, !isArchivedTable)">
                                <i class="far fa-check-circle" v-if="!isArchivedTable"></i>
                                <span class="jdi-tooltiptext" v-if="!isArchivedTable">Archive this action</span>

                                <i class="far fa-check-circle complete" v-if="isArchivedTable"></i>
                                <span class="jdi-tooltiptext" v-if="isArchivedTable">Unarchive this action</span>
                            </a>
                            <span>{{ action.name }}</span>
                        </div>
                    </td>
                    <td>
                        <template v-if="action.comments.length">
                            <span class="actions-comment">
                                {{ action.comments[0].text }} <br>
                                <bs-button toggle-target="'commentsList'+index" v-if="action.comments.length > 1">More comments ({{ action.comments.length - 1 }})</bs-button>
                            </span>
                            <bs-collapse :id="'commentsList'+index">
                                <ul class="actions-comments">
                                    <li v-for="(comment, i) in action.comments.slice(1)" :key="'comment-' + i">
                                        <p> {{ comment.text }}</p>
                                        <span>{{ comment.createdBy }} - {{ $moment(comment.createdOn).format("DD-MMM-YY")}}</span>

                                    </li>
                                </ul>
                            </bs-collapse>
                        </template>
                    </td>
                    <td>
                        <user-picture :user-id="action.ownerId" :user-name="action.ownerName" :is-profile-image-uploaded="action.isOwnerImageUploaded"
                                      :user-type="action.ownerType"></user-picture>
                    </td>
                    <td style="min-width: 170px;">
                        <div class="category-list jdi-tooltip" :style="'background: #' + action.list.colour">
                            {{truncate(action.list.name )}}
                            <span class="jdi-tooltiptext">{{action.list.name}}</span>
                        </div>
                    </td>
                    <td v-if="!isArchivedTable">
                        <span :class="{ 'date-passed': checkIfDatePassed(action.targetDate)}">{{ $moment(action.targetDate).format("DD-MMM-YY") }}</span>
                    </td>
                    <td v-if="isArchivedTable">
                        <span>{{ $moment(action.modifiedOn).format("DD-MMM-YY") }}</span>
                    </td>
                    <!-- start - delete and edit row: -->
                    <td v-if="!isArchivedTable">
                        <div class="options-item">
                            <a href="#addActionModal" @click="editAction(action.id)"
                               data-bs-toggle="modal"><i class="far fa-edit fontawesome-icon"></i></a>
                            <a href="#deleteItemModal" data-bs-toggle="modal"
                               @click="deleteAction(action.id, index)">
                                <i class="far fa-trash-alt fontawesome-icon"></i>
                            </a>
                        </div>
                    </td>
                    <td v-else>
                        <div class="options-item">
                            <a href="#viewActionModal" @click="viewAction(action.id)" data-bs-toggle="modal"><i class="far fa-edit fontawesome-icon"></i></a>
                        </div>
                    </td>
                    <!-- end - delete and edit row: -->
                </tr>
            </tbody>
        </table>

        <reassign-contact-modal @on-save="confirm" :ok-callback="reassignAction" modal-title="Reassign Record" modal-body="This archived action was asigned to a user who is no longer a member of this list. Please reassign this action to another person before proceeding." ref="reassignModal" :assign-to-me="assignToMe"></reassign-contact-modal>
    </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { BaseEntity } from '../../Models/BaseEntity';
import { DeleteRegisterItem } from '../../Models/BaseEntity';
import BaseTable from './BaseTable';
import UserPicture from '../UserPicture/UserPicture.vue';
import ReassignContactModal from '../Modals/ReassignContacts/ReassignContactModal.vue';
import { User } from '../../Models/User';
import { EntityService } from '../../Services/EntityService';
import { ReassignContactModel } from '../../Models/Contact';
import BsButton from '../BsButton';
import BsCollapse from '../BsCollapse';
import moment from 'moment';

declare const CurrentUserId: string;

@Component({
    name: 'actions-table',
    template: '#actions-table-id',
    components: {
        'user-picture': UserPicture,
        'reassign-contact-modal': ReassignContactModal,
        'bs-button': BsButton,
        'bs-collapse': BsCollapse,
    }
})

export default class ActionsTable extends BaseTable {
    @Prop({ default: true, type: Boolean }) isArchivedTable!: boolean;
    @Prop() tableData!: BaseEntity[];
    private endpoint = 'Action';
    private ActionIdToUnarchive = '';
    private assignToMe = true;

    async archiveAction(id: string, isArchived: boolean) {
        try {
            this.ActionIdToUnarchive = id;

            const item: BaseEntity = this.tableData.find((item) => item.id == id) as BaseEntity;
            const request = { ...item };
            request.archived = isArchived;
            const result = await EntityService.Edit(this.endpoint, request);

            if (result.data.success) {
                item.archived = isArchived;
                this.$store.dispatch(`setRefreshActions`, true);
                this.$store.dispatch('actionSetSelectedListFromFilter', this.$store.state.filterLists);
            }
            else if (!result.data.success && result.data.code == 1 && !isArchived) {
                const user: User = {
                    id: request.ownerId,
                    ownerName: request.ownerName,
                };
                await this.$store.dispatch('setOwners', result.data.data);
                await this.$store.dispatch('setReassignContact', user);
                this.assignToMe = CurrentUserId == user.id ? false : (result.data.data && result.data.data.some((t: User) => t.id == CurrentUserId));
                const element = this.$refs && (<Vue>this.$refs.reassignModal).$el;
                $(element).modal('show');
            }
            else {
                alert(result.data.message);
            }
        }
        catch (e) {
            alert('Something went wrong. Please try later.');
        }
    }

    async reassignAction(model: ReassignContactModel) {
        return await EntityService.ReassignActionAndUnarchive(model, this.ActionIdToUnarchive);
    }

    async confirm() {
        this.$store.dispatch(`setRefreshActions`, true);
    }

    editAction(id: string) {
        this.changeUrl('actions', id);
        this.editRegister(this.tableData, id);
    }

    viewAction(id: string) {
        this.changeUrl('actions', id);
        this.viewRegister(this.tableData, id);
    }

    open(id: string) {
        this.editRecord = this.getRegister(this.$store.state.actions, id);
        if (this.editRecord) {
            if (this.editRecord.archived) {
                this.$store.dispatch('setViewRegiterItem', this.editRecord);
                return true;
            }
            else {
                this.$store.dispatch('setEditRegisterItem', this.editRecord);
                this.$store.dispatch('setEdit', true);
                return false;
            }
        }
        else {
            console.log('Item is not found');
        }
        return null;
    }

    deleteAction(id: string, index: number): void {
        this.$store.dispatch('setDeleteRegisterItem', { registerId: id, deleteEndpoint: this.endpoint, index } as DeleteRegisterItem);
    }

    checkIfDatePassed(date: Date) {
        return moment(new Date()).isAfter(date, 'day');
    }
}
</script>

<template>
    <footer class="footer" :class="{'minimized': minimized === true}">
        <div class="container-fluid position-relative">
            <div class="row">
                <div class="col">
                    <a href="#" @click.prevent="minimized = !minimized" class="minimize-button">
                        <template v-if="minimized === false">
                            Minimize
                            <i style="margin-left:5px" class="fas fa-chevron-down" />
                        </template>
                        <template v-else>
                            Maximize
                            <i style="margin-left:5px" class="fas fa-chevron-up" />
                        </template>
                    </a>
                    <h5>Keyboard shortcuts</h5>
                    <div class="footer-instruction">
                        <p><strong>Go to actions:</strong> Alt + A</p>
                        <p><strong>Go to projects:</strong> Alt + P</p>
                        <p v-if="title"><strong>Add New {{ title }}:</strong> Alt + N</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'main-footer',
    data() {
        return {
            minimized: false
        };
    }
})
export default class MainFooter extends Vue {
    @Prop({ default: '' }) title!: string;
    @Prop({ default: true }) tabsInHeader!: boolean;

    private minimized!: boolean; // TODO: this was added to prevent warnings in template
}
</script>

<style lang="less">
.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #6F8294;
    color: #ffffff;
    padding: 23px 60px;
    transition: all .5s ease;

    &.minimized {
        bottom: -90px;
    }

    a {
        color: #ffffff;
    }

    .minimize-button {
        position: absolute;
        left: 15px;
        top: -41px;
        font-size: 12px;
        line-height: 16px;
        background: #6F8294;
        padding: 3px 12px;
        border-radius: 7px 7px 0 0;
        display: flex;
        align-items: center;

        img {
            margin-left: 7px;
        }

        &:hover {
            color: #fff;
        }
    }

    h5 {
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 6px;
    }

    .footer-instruction {
        display: flex;
        align-items: center;

        p {
            margin-bottom: 0;
            padding-right: 17px;
            border-right: 1px solid;
            font-size: 14px;
            line-height: 19px;
            margin-right: 17px;

            &:last-child {
                padding-right: 0;
                margin-right: 0;
                border: none;
            }
        }
    }
}</style>

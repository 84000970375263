
import { ActionContext } from 'vuex';
import { Contact } from '../Models/Contact';
import { User } from '../Models/User';
import { ContactsState } from '../Store/Types';

export class ContactsStore {
    static mutations = {
        setRefreshContacts(state: ContactsState, val: boolean) {
            state.hasContactBeenCreated = val;
        },

        setEditContact(state: ContactsState, val: Contact) {
            state.editContact = val;
        },

        setReassignContact(state: ContactsState, val: Contact) {
            state.reassignContact = val;
        },

        setDeleteContact(state: ContactsState, val: Contact) {
            state.deleteContact = val;
        },

        setOwners(state: ContactsState, val: User[]) {
            state.owners = val;
        },
    };

    static actions = {
        setRefreshContacts(context: ActionContext<ContactsState, ContactsState>, val: boolean) {
            context.commit('setRefreshContacts', val);
        },

        setEditContact(context: ActionContext<ContactsState, ContactsState>, val: Contact) {
            context.commit('setEditContact', val);
        },

        setReassignContact(context: ActionContext<ContactsState, ContactsState>, val: Contact) {
            context.commit('setDeleteContact', {} as Contact);
            context.commit('setReassignContact', val);
        },

        setDeleteContact(context: ActionContext<ContactsState, ContactsState>, val: Contact) {
            context.commit('setReassignContact', {} as Contact);
            context.commit('setDeleteContact', val);
        },

        setOwners(context: ActionContext<ContactsState, ContactsState>, val: User[]) {
            context.commit('setOwners', val);
        },
    };
}

<template>
    <div class="modal addContactsModal addItemModal fade add-item-modal add-contact-modal" ref="contactModal" id="addContactModal" tabindex="-1"
         aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog custom-xl-modal">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="title">
                        <template v-if="isEditing">
                            <h5 class="modal-title">Edit Contact</h5>
                            <p>Use the input fields below to edit the existing contact</p>
                        </template>

                        <template v-else>
                            <h5 class="modal-title">Add New Contact</h5>
                            <p>Use the input fields below to track a new contact</p>
                        </template>
                    </div>
                    <a href="#" ref="closeBtn" @click="cleanForm()" class="close-modal" data-bs-dismiss="modal"
                       aria-label="Close">Close <i class="fas fa-times"></i></a>
                </div>

                <div class="modal-body">
                    <!-----------FORM----------------->
                    <form contact="" class="add-item-form" @submit.prevent="">
                        <!-- Name -->
                        <div class="form-group" :class="{ 'form-group--error': $v.name.$error }"
                             style="max-width: 294px" @click="focusOnClick">
                            <label for="contactName" class="required-field">Name</label>
                            <input v-model="name" name="contactName" type="text" id="contactName" autocomplete="off"
                                   placeholder="Write the name of the contact"
                                   @focusout="validInputSet('name', 'contactNameSet')" :disabled="isEditingDisabled"
                                   :class="{ 'field-set': name }" maxlength="160" v-on:keydown.enter="onEnter($event)">
                            <div class="msg-error" v-if="!$v.name.required &amp;&amp; $v.name.$dirty">
                                Field is required
                            </div>
                        </div>

                        <!-- Email -->
                        <div class="form-group" style="max-width: 294px" @click="focusOnClick">
                            <label for="contactEmail">Email</label>
                            <input v-model="email" name="contact" type="email" id="contactEmail" autocomplete="off" :disabled="isEditingDisabled"
                                   placeholder="Write the email of the contact" maxlength="256" v-on:keydown.enter="onEnter($event, '#saveContact')">
                            <div class="msg-error" v-if="$v.email.$error &amp;&amp; $v.email.$dirty">
                                Please enter a valid email address
                            </div>
                        </div>

                        <!-- is archived -->
                        <div class="form-group" style="max-width: 170px" id="isArchivedContainerId">
                            <label for="isArchived" class="required-field">Is Archived</label>
                            <div class="form-check form-switch">
                                <input type="checkbox" class="form-check-input" v-model="isArchived" name="isArchived" id="isArchived" data-toggle="toggle" :disabled="isArchiveDisabled" v-on:change="onArchiveChanged">
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <div class="modal-footer-left-container">
                        <div v-if="editRecord.createdBy" class="jdi-label">Created by {{editRecord.createdBy}} on {{$moment(editRecord.createdOn).format("DD-MMM-YY")}} at {{$moment(editRecord.createdOn).format("HH:mm")}} </div>
                        <div v-if="editRecord.modifiedBy" class="jdi-label">Modified by {{editRecord.modifiedBy}} on {{$moment(editRecord.modifiedOn).format("DD-MMM-YY")}} at {{$moment(editRecord.modifiedOn).format("HH:mm")}} </div>
                    </div>
                    <div class="modal-footer-rigt-container">
                        <a href="#" class="save-modal-button jdi-green-button" id="saveContact" v-on:click.prevent="onAddContact()">Save to contact list</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Contact, ContactToCreateFromEntity } from '../../Models/Contact';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { JdiState } from '../../Store/Types';
import { ContactsService } from '../../Services/ContactsService';
import componentNavigationMixins from '../../mixins/componentNavigationMixins';
import _ from 'lodash';

@Component({
    mixins: [validationMixin, componentNavigationMixins],
    name: 'add-contact-modal',
    template: '#add-contact-modal-id',
    validations: {
        name: { required },
        email: { email }
    }
})

export default class AddContactModal extends Vue {
    @Prop() cancel!: Function;
    private isOkClicked = false;

    @Watch('$store.state.editContact') onEditingChanged(newVal: boolean, oldVal: boolean) {
        this.setContactData();
    }

    @Watch('$store.state.contactToCreateFromEntity') onCreateChanged(newVal: ContactToCreateFromEntity, oldVal: ContactToCreateFromEntity) {
        if (newVal && newVal.shouldBeCreated) {
            this.name = newVal.name;
            this.isArchiveDisabled = true;
        }
    }

    onArchiveChanged() {
        if (this.isEditingDisabled) {
            this.isEditingDisabled = false;
        }
    }

    private editRecord: Contact = {} as Contact;
    private name = '';
    private email = '';
    private isArchived = false;
    private isArchiveDisabled = false;
    private isEditingDisabled = false;

    get getState(): JdiState {
        return this.$store.state as JdiState;
    }

    get isEditing() {
        return this.getState.editContact != null && this.getState.editContact.id;
    }

    validInputSet(value: string, inputNameSet: string): void {
        const modal: any = this;
        if (!_.isEmpty(modal[value])) {
            modal[inputNameSet] = true;
        }
    }

    onAddContact(): void {
        const request: Contact = {
            name: this.name,
            email: this.email,
            archived: this.isArchived,
        };
        if (this.isEditing) {
            request.id = this.getState.editContact.id;
        }

        this.$v.$touch();
        const isValid = !this.$v.$error;
        if (isValid) {
            this.saveContact(request);
        }
    }

    async saveContact(request: Contact) {
        let res;
        if (this.isEditing) {
            res = await ContactsService.Edit(request);

        }
        else {
            res = await ContactsService.Add(request);
        }
        this.isArchiveDisabled = false;
        const contactToCreate: ContactToCreateFromEntity = this.$store.state.contactToCreateFromEntity;
        if (res && res.data && res.data.success) {
            (this.$refs.closeBtn as HTMLElement).click();
            if (contactToCreate && contactToCreate.shouldBeCreated) {
                contactToCreate.shouldBeCreated = false;
                contactToCreate.id = res.data.id;
                this.$store.dispatch('setNewContactCreation', contactToCreate);
            }
            this.isOkClicked = true;
            this.$router.push({ name: <string>this.$route.name }).catch((er) => er);
        }
        else {
            alert(res.data.message);

        }
    }

    setContactData(): void {
        if (this.isEditing) {
            this.editRecord = this.getState.editContact;
            this.name = this.editRecord.name;
            this.email = this.editRecord.email;
            this.isArchived = this.editRecord.archived as boolean;
            this.isEditingDisabled = this.isArchived;
        }
        else {
            this.cleanForm();
        }
    }

    cleanForm(): void {
        this.name = '';
        this.email = '';
        this.$store.dispatch('setEditContact', null);
        this.isArchived = false;
        this.$v.$reset();
        this.isEditingDisabled = false;
        this.$router.push({ name: <string>this.$route.name }).catch((er) => er);
    }

    mounted() {
        const me = this;

        $(<Element>this.$refs.contactModal).on('hidden.bs.modal', function () {
            me.cleanForm();

            if (me.isOkClicked) {
                me.$store.dispatch('setRefreshContacts', true);
                me.isOkClicked = false;
                return;
            }

            me.cancel && me.cancel();
            me.isOkClicked = false;
        });
    }
}
</script>

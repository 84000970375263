<template>
    <layout-default :page-title="pageTitle">
        <div class="main-table-page">
            <div class="main">
                <div class="ajax-loading" v-if="loading"></div>
                <div v-else class="table-container">
                    <entity-table :table-data="actions" :lists="actionLists"
                                  table-titles="actions"></entity-table>
                </div>
            </div>
        </div>
    </layout-default>
</template>

<script lang="ts">

import { Vue, Component, Watch } from 'vue-property-decorator';

import { ActionList } from '../Models/ActionList';
import { Action } from '../Models/Action';

import LayoutDefault from '../Layouts/LayoutDefault.vue';
import EntityTable from '../Components/EntityTable.vue';
import { TabNames } from '../Models/Enums';
import ApiRequestor from '../Services/ApiRequestor';

@Component({
    name: 'actions',
    template: '#actions-id',
    components: {
        'layout-default': LayoutDefault,
        'entity-table': EntityTable,
    },
    async created() {
        await this.$store.commit('setLoading', true);
    }
})

export default class Actions extends Vue {
    private pageTitle = 'Actions';
    private actions: Action[] = [];
    private actionLists: ActionList[] = [];

    @Watch('$store.state.isRefreshingActions') onIsRefreshingChanged(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.getActions();
        }
    }

    getActions(): void {
        this.$store.dispatch('setLoading', true);

        ApiRequestor.get('GetActions')
            .then((res) => {
                this.actions = res.data as Action[];
                this.$store.dispatch('setEntityLength', res.data.length);
                this.$store.dispatch('setActions', res.data);
                this.getActionsLists();
            })
            .catch((e) => {
                this.$store.dispatch('setRefreshActions', false);
            });
    }

    async getActionsLists(): Promise<void> {
        await ApiRequestor.get('GetMemberActionLists')
            .then((res) => {
                this.actionLists = res.data as ActionList[];
                this.$store.dispatch('setRefreshActions', false);
            })
            .catch((e) => {
                this.$store.dispatch('setRefreshActions', false);
            });
        await this.$store.commit('setLoading', false);
    }

    mounted(): void {
        this.$store.dispatch('actionSetSelectedListFromFilter', '');
        this.getActions();
    }

    beforeCreate() {
        this.$store.dispatch('setActiveTab', TabNames.activeList);
    }

    get loading(): boolean {
        return this.$store.state.loading;
    }
}
</script>

<template>
    <div class="action-header">
        <div class="title-bar">
            <div class="main-title-container">
                <h1>
                    {{ title }} ({{entityLength}})
                    <a href="#" class="change-list-button" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-chevron-down"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-lists-menu" aria-labelledby="dropdownMenuLink">
                        <li><router-link to="/actions" class="dropdown-item">Actions</router-link></li>
                        <li><router-link to="/projects" class="dropdown-item">Projects</router-link></li>
                        <li><router-link to="/action-lists" class="dropdown-item">Action Lists</router-link></li>
                        <li><router-link to="/project-lists" class="dropdown-item">Project Lists</router-link></li>
                        <li v-if="isContactEnabled"><router-link to="/contacts" class="dropdown-item">Contacts</router-link></li>
                    </ul>
                </h1>

                <div class="search-container">
                    <div class="search-input" v-if="showSearch">

                        <input @input="updateSearch" id="searchData" type="text" v-model="search" :placeholder="getSearchPlaceholder">
                        <label for="searchData"> <i class="fas fa-search"></i></label>
                    </div>

                    <a ref="addNew" v-if="showActiveData && showAddNewItem" :href="getHref" class="add-button jdi-green-button ms-2" @click="setEditFlag(false)" data-bs-toggle="modal">
                        <span class="add-icon-container"><i class="fas fa-plus"></i></span>New {{ pageTitle }}
                    </a>
                </div>
            </div>

            <ul class="lists-menu nav nav-tabs" role="tablist" v-if="tabsInHeader">
                <li>
                    <a :href="'#active'+title" @click="showTab(true)" class="active" data-bs-toggle="tab" role="tab" aria-selected="true">Active {{ title }}</a>
                </li>
                <li>
                    <a :href="'#archived'+ title" @click="showTab(false)" data-bs-toggle="tab" role="tab" aria-selected="false">Archived {{ title }}</a>
                </li>
            </ul>

            <div class="date" v-if="!tabsInHeader">
                <span>Active {{title}} to {{$moment(date).format("DD-MMM-YY")}}</span>

                <i class="far fa-calendar fontawesome-icon"></i>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { TabNames } from '../Models/Enums';

declare const IsContactsDisabled: boolean;

@Component({
    name: 'main-header',
    template: '#main-header-id'
})

export default class MainHeader extends Vue {
    @Prop({ default: '', type: String }) page!: string;
    @Prop({ default: '', type: String }) title!: string;
    @Prop({ default: true, type: Boolean }) tabsInHeader!: boolean;
    @Prop({ default: true, type: Boolean }) showAddNewItem!: boolean;
    @Prop({ default: true, type: Boolean }) showSearch!: boolean;

    private date: Date = new Date();
    private search = '';

    // TODO: PointerEvent was used in original code
    updateSearch(e: Event): void {
        this.$store.dispatch('ActionUpdateSearch', (e.target as HTMLInputElement).value);
    }

    setEditFlag(value: boolean): void {
        this.$store.dispatch('setEdit', value);
    }

    get isContactEnabled(): boolean {
        return !IsContactsDisabled;
    }

    get entityLength(): number {
        return this.$store.state.entityLength;
    }

    get showActiveData(): boolean {
        return <number>this.$store.state.activeTab == <number>TabNames.activeList;
    }

    get getSearchPlaceholder(): string {
        return `Search by ${this.pageTitle.toLowerCase()} name`;
    }

    get pageTitle(): string {
        return this.title.toLowerCase()[this.title.length - 1] == 's'  ? this.title.slice(0, -1) : this.title;
    }

    get getHref(): string {
        return `.add${this.title.replace(' ', '')}Modal`;
    }

    showTab(isActiveList: boolean): void {
        this.search = '';
        this.$store.dispatch('ActionUpdateSearch', '');
        this.$store.dispatch('setActiveTab', isActiveList ? TabNames.activeList : TabNames.archivedList);
    }

    mounted(): void {
        document.addEventListener('keydown', (e: KeyboardEvent) => {

            if (e.altKey && e.code === 'KeyN') {
                e.preventDefault();
                if (this.$refs.addNew) {
                    (this.$refs.addNew as HTMLElement).click();
                }
            }
        });
    }
}
</script>

<style lang="less">

.action-header {
    background: #fff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.07);
    position: fixed;
    width: 100%;
    left: 0;
    top: 62px;
    z-index: 11;
    height: 152px;

    .search-input {
        position: relative;

        label {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;

            i {
                color: #6F8294;
            }
        }

        input {
            position: relative;
            z-index: 1;
            border: 1px solid #79789D;
            box-sizing: border-box;
            border-radius: 6px;
            width: 330px;
            padding: 14px 15px;
            font-size: 16px;
            line-height: 20px;
            color: #6F8294;
        }
    }

    .title-bar {
        max-width: 1215px;
        margin: 0 auto;

        .main-title-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 39px 0 29px;

            .change-list-button {
                i {
                    font-size: 14px;
                }

                color: #1055A1;
            }
        }

        h1 {
            font-weight: normal;
            font-size: 32px;
            line-height: 32px;
            color: #1055A1;
            display: flex;
            align-items: center;

            a {
                display: inline-flex;
                background: #F2F2F2;
                width: 28px;
                height: 28px;
                border-radius: 8px;
                position: relative;
                align-items: center;
                justify-content: center;
                margin-left: 15px;
            }

            .dropdown-lists-menu {
                padding: 18px 0;

                a {
                    display: block;
                    background: transparent;
                    text-align: left;
                    margin: 0;
                    padding: 2px 29px;
                    height: auto;
                    width: auto;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 33px;
                    color: #6F8294;
                }
            }
        }

        .lists-menu {
            padding: 0;
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 0;

            li {
                margin-right: 20px;

                a {
                    font-size: 16px;
                    line-height: 22px;
                    color: #54A4FC;
                    padding-bottom: 8px;
                    display: block;

                    &:hover {
                        color: #1055a1;
                    }

                    &.active {
                        color: #1055A1;
                        font-weight: bold;
                        border-bottom: 3px solid #1055A1;

                        &:hover {
                            color: #1055A1;
                        }
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .date {
            margin-top: -25px;
            padding-bottom: 29px;
            display: flex;
            align-items: center;

            span {
                font-weight: normal;
                font-size: 15px;
                line-height: 20px;
                color: #6F8294;
            }

            .fontawesome-icon {
                display: inline-block;
                margin-left: 10px;
            }
        }
    }

    .search-container {
        display: flex;
    }
}
</style>

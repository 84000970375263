
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
    name: 'bs-collapse',
    render: function (this: BsCollapse, h) {
        return h(
            'div',
            {
                attrs: { id: this.id },
                staticClass: 'collapse',
                class: {
                    'show': this.show,
                },
            },
            this.$slots.default
        );
    },
    mounted(this: BsCollapse) {
        this.$root.$on('bs-toggle', this.handleToggleEvt);
    }
})

export default class BsCollapse extends Vue {
    @Prop({ type: String, required: true }) private id!: string;

    private show = false;

    private handleToggleEvt(id: string) {
        this.show = !this.show;
    }
}

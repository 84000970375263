<template>
    <div class="input-group">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component({
    name: 'bs-input-group',
})

export default class BsInputGroup extends Vue {

}
</script>

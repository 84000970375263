
import { BaseListEntity } from '../Models/BaseListEntity';
import ApiRequestor from './ApiRequestor';

export class ListsService {
    public static async EditList(endpoint: string, request: BaseListEntity): Promise<any> {
        return await ApiRequestor.put(`Edit${endpoint}/${request.id}`, request);
    }

    public static async CreateList(endpoint: string, request: BaseListEntity): Promise<any> {
        return await ApiRequestor.post(`Add${endpoint}`, request);
    }

    public static async GetListDetails(projectListId?: string, actionListId?: string): Promise<any> {
        const params = projectListId && projectListId.length > 0 ? `?projectListId=${projectListId}` : actionListId && actionListId.length > 0 ? `?actionListId=${actionListId}` : '';
        return await ApiRequestor.get(`GetListDetails${params}`);
    }

    public static async CheckForExistingListMembers(projectListId?: string, actionListId?: string): Promise<any> {
        let url;
        if (actionListId && actionListId.length > 0) {
            url = `CheckForExistingActionListMembers?id=${actionListId}`;
        }
        else {
            url = `CheckForExistingProjectListMembers?id=${projectListId}`;
        }
        return await ApiRequestor.get(url);
    }
}

<template>
    <div class="modal fade delete-item-modal" id="deleteItemModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <a href="#" class="close-modal" data-bs-dismiss="modal" aria-label="Close">
                        Close <i class="fas fa-times"></i>
                    </a>
                    <div class="main-content">
                        <h3>Are you sure you want to remove this {{deleteEndpoint}}?</h3>
                        <p>
                            If you remove the {{deleteEndpoint}}, to get it active again you have to add
                            a new one.
                        </p>
                    </div>

                    <div class="buttons-container">
                        <a href="#" data-bs-dismiss="modal" class="outline-button">Not now</a>
                        <a href="#" data-bs-dismiss="modal" @click.prevent="onDeleteAction()">Delete {{deleteEndpoint}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { Vue, Component } from 'vue-property-decorator';
import ApiRequestor from '../../Services/ApiRequestor';

@Component({
    name: 'delete-modal',
    template: '#delete-modal-id',
})

export default class DeleteModal extends Vue {
    get deleteEndpoint() {
        return this.$store.state.deleteRegisterItem.deleteEndpoint;
    }

    get registerId() {
        return this.$store.state.deleteRegisterItem.registerId;
    }

    onDeleteAction() {
        this.$store.dispatch('actionSetSelectedListFromFilter', this.$store.state.filterLists);
        return ApiRequestor.delete(`Delete${this.deleteEndpoint}/${this.registerId}`)
            .then((res) => {
                if (res.data.success) {
                    this.$store.dispatch(`setRefresh${this.deleteEndpoint}s`, true);
                }
                else {
                    alert('The register could not be removed.');
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    alert(error.response.data.message);
                }
                alert('The register could not be removed.');
                return false;
            });
    }
}
</script>


import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import { BaseEntity, DeleteRegisterItem } from '../Models/BaseEntity';
import { BaseListEntity } from '../Models/BaseListEntity';
import { Order } from '../Models/Order';
import { User } from '../Models/User';
import { JdiState } from '../Store/Types';
import { ContactsStore } from '../Store/ContactsStore';
import { Contact, ContactToCreateFromEntity } from '../Models/Contact';
import { TabNames } from '../Models/Enums';
import { Action } from '../Models/Action';
import { Project } from '../Models/Project';
import { ProjectList } from '../Models/ProjectList';
import { ActionList } from '../Models/ActionList';
import { TableItem } from '../Models/TableItem';

Vue.use(Vuex);

export default new Vuex.Store<JdiState>({
    state: {
        isEditing: false,
        isRefreshingActions: false,
        isRefreshingProjects: false,
        isRefreshingProjectLists: false,
        isRefreshingActionLists: false,
        deleteRegisterItem: {} as DeleteRegisterItem,
        editRegisterItem: {} as BaseEntity,
        entityLength: 0,
        loading: false,
        search: '',
        selectedListFromFilter: {} as BaseListEntity,
        sortOrder: {} as Order,
        selectedResponsibleUser: {} as User,
        editContact: {} as Contact,
        hasContactBeenCreated: false,
        activeTab: TabNames.activeList,
        reassignContact: {} as Contact,
        owners: [] as User[],
        deleteContact: {} as Contact,
        contactToCreateFromEntity: {} as ContactToCreateFromEntity,
        actions: [] as Action[],
        projects: [] as Project[],
        actionLists: [] as ActionList[],
        projectLists: [] as ProjectList[],
        viewRegisterItem: {} as BaseEntity,
        contactCreationNotification: false,
        filterLists: [] as TableItem[],
    },
    mutations: {
        setSortOrder(state: JdiState, val: Order) {
            state.sortOrder = val;
        },
        setFilterLists(state: JdiState, val: TableItem[]) {
            state.filterLists = val;
        },
        setDeleteRegisterItem(state: JdiState, val: DeleteRegisterItem) {
            state.deleteRegisterItem = val;
        },
        setRefreshActions(state: JdiState, val: boolean) {
            state.isRefreshingActions = val;
        },
        setRefreshProjects(state: JdiState, val: boolean) {
            state.isRefreshingProjects = val;
        },
        setRefreshProjectLists(state: JdiState, val: boolean) {
            state.isRefreshingProjectLists = val;
        },
        setRefreshActionLists(state: JdiState, val: boolean) {
            state.isRefreshingActionLists = val;
        },
        setEdit(state: JdiState, val: boolean) {
            state.isEditing = val;
        },
        setEditRegisterItem(state: JdiState, val: BaseEntity) {
            state.editRegisterItem = val;
        },
        setViewRegisterItem(state: JdiState, val: BaseEntity) {
            state.viewRegisterItem = val;
        },
        setSelectedListFromFilter(state: JdiState, val: BaseListEntity) {
            state.selectedListFromFilter = val;
        },
        setEntityLength(state: JdiState, val: number) {
            state.entityLength = val;
        },
        setLoading(state: JdiState, val: boolean) {
            state.loading = val;
        },
        updateSearch(state: JdiState, payload: string) {
            state.search = payload;
        },
        setSelectedResponsibleUser(state: JdiState, val: User) {
            state.selectedResponsibleUser = val;
        },
        setActiveTab(state: JdiState, val: TabNames) {
            state.activeTab = val;
        },
        setNewContactCreation(state: JdiState, val: ContactToCreateFromEntity) {
            state.contactToCreateFromEntity = val;
        },
        setContactCreationNotification(state: JdiState, val: boolean) {
            state.contactCreationNotification = val;
        },

        setActions(state: JdiState, val: Action[]) {
            state.actions = val;
        },
        setProjects(state: JdiState, val: Project[]) {
            state.projects = val;
        },
        setActionLists(state: JdiState, val: ActionList[]) {
            state.actionLists = val;
        },
        setProjectLists(state: JdiState, val: ProjectList[]) {
            state.projectLists = val;
        },
        setViewRegiterItem(state: JdiState, val: BaseEntity) {
            state.viewRegisterItem = val;
        },
        ...ContactsStore.mutations,
    },
    actions: {
        setSortOrder(context: ActionContext<JdiState, JdiState>, val: Order) {
            context.commit('setSortOrder', val);
        },
        setFilterLists(context: ActionContext<JdiState, JdiState>, val: TableItem[]) {
            context.commit('setFilterLists', val);
        },
        setDeleteRegisterItem(context: ActionContext<JdiState, JdiState>, val: DeleteRegisterItem) {
            context.commit('setDeleteRegisterItem', val);
        },
        setEdit(context: ActionContext<JdiState, JdiState>, val: boolean) {
            context.commit('setEdit', val);
        },
        setRefreshActions(context: ActionContext<JdiState, JdiState>, val: boolean) {
            context.commit('setRefreshActions', val);
        },
        setRefreshProjects(context: ActionContext<JdiState, JdiState>, val: boolean) {
            context.commit('setRefreshProjects', val);
        },
        setRefreshProjectLists(context: ActionContext<JdiState, JdiState>, val: boolean) {
            context.commit('setRefreshProjectLists', val);
        },
        setRefreshActionLists(context: ActionContext<JdiState, JdiState>, val: boolean) {
            context.commit('setRefreshActionLists', val);
        },
        setEditRegisterItem(context: ActionContext<JdiState, JdiState>, val: BaseEntity) {
            context.commit('setEditRegisterItem', val);
        },
        setViewRegisterItem(context: ActionContext<JdiState, JdiState>, val: BaseEntity) {
            context.commit('setViewRegisterItem', val);
        },
        setLoading(context: ActionContext<JdiState, JdiState>, val: boolean) {
            context.commit('setLoading', val);
        },
        setEntityLength(context: ActionContext<JdiState, JdiState>, val: number) {
            context.commit('setEntityLength', val);
        },
        ActionUpdateSearch(context: ActionContext<JdiState, JdiState>, payload: string) {
            context.commit('updateSearch', payload);
        },
        actionSetSelectedListFromFilter(context: ActionContext<JdiState, JdiState>, payload: BaseListEntity) {
            context.commit('setSelectedListFromFilter', payload);
        },
        setSelectedResponsibleUser(context: ActionContext<JdiState, JdiState>, payload: User) {
            context.commit('setSelectedResponsibleUser', payload);
        },
        setActiveTab(context: ActionContext<JdiState, JdiState>, payload: TabNames) {
            context.commit('setActiveTab', payload);
        },
        setNewContactCreation(context: ActionContext<JdiState, JdiState>, payload: ContactToCreateFromEntity) {
            context.commit('setNewContactCreation', payload);
        },
        setActions(context: ActionContext<JdiState, JdiState>, payload: Action[]) {
            context.commit('setActions', payload);
        },
        setProjects(context: ActionContext<JdiState, JdiState>, payload: Project[]) {
            context.commit('setProjects', payload);
        },
        setProjectLists(context: ActionContext<JdiState, JdiState>, payload: ProjectList[]) {
            context.commit('setProjectLists', payload);
        },
        setActionLists(context: ActionContext<JdiState, JdiState>, payload: ActionList[]) {
            context.commit('setActionLists', payload);
        },
        setViewRegiterItem(context: ActionContext<JdiState, JdiState>, payload: BaseEntity) {
            context.commit('setViewRegiterItem', payload);
        },
        setContactCreationNotification(context: ActionContext<JdiState, JdiState>, payload: boolean) {
            context.commit('setContactCreationNotification', payload);
        },

        /* Contacts */
        ...ContactsStore.actions,
    }
});

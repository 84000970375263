<template>
    <div class="modal fade confirmation-item-modal" id="confirmationModal" tabindex="-1" aria-hidden="true" style="background-color: rgb(0, 0, 0, 0.6);" ref="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="title">
                        <h5 class="modal-title" v-if="!modalTitle || modalTitle.length ==0">Confirmation</h5>
                        <h5 class="modal-title" v-else>
                            {{modalTitle}}
                        </h5>
                    </div>
                    <a href="#" class="close-modal" data-bs-dismiss="modal" aria-label="Close" @click.prevent="onCancelAction()" v-if="visibleCloseButton">
                        Close <i class="fas fa-times"></i>
                    </a>
                </div>

                <div class="modal-body">
                    <div class="main-content">
                        <h2>{{title}}</h2>
                        <p class="confirmation-message">
                            {{message}}
                        </p>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="modal-footer-left-container">
                    </div>
                    <div class="modal-footer-rigt-container">
                        <a href="#" data-bs-dismiss="modal" class="save-modal-button jdi-green-button" @click.prevent="onOkAction()"  v-if="!disabledConfirmButton">OK</a>
                        <a href="#" data-bs-dismiss="modal" class="save-modal-button jdi-green-button disabled" v-else disabled>OK</a>

                        <a href="#" data-bs-dismiss="modal" class="btn" @click.prevent="onCancelAction()" v-if="visibleCancelButton">Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'confirmation-modal',
    template: '#confirmation-modal-id',
})

export default class ConfirmationModal extends Vue {
    @Prop({ type: String, required: false }) title!: string;
    @Prop({ type: String, required: false }) message!: string;
    @Prop({ type: String, required: false }) modalTitle!: string;
    @Prop() confirm!: Function;
    @Prop() cancel!: Function;
    @Prop({ type: Boolean, required: false }) visibleCloseButton!: boolean;
    @Prop({ type: Boolean, required: false }) disabledConfirmButton!: boolean;
    @Prop({ type: Boolean, required: false, default: true }) visibleCancelButton!: boolean;

    private IsOkClicked = false;
    onOkAction() {
        this.IsOkClicked = true;
        this.confirm();
    }

    onCancelAction() {
        this.cancel();
    }

    mounted() {
        const me = this;
        me.IsOkClicked = false;

        $(<Element>this.$refs.modal).on('hidden.bs.modal', function () {
            if (!me.IsOkClicked) {
                me.onCancelAction();
            }
            me.IsOkClicked = false;
        });
    }
}
</script>

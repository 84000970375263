import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import type { AirDatepickerDate, AirDatepickerOptions } from 'air-datepicker';
import { AdpDefaultSettings } from './DatePicker';
import { DateTimeHelper } from '../Utils/DateTimeHelper';

// TODO: is it possible to reuse the declaration from DatePicker.ts?
declare class AirDatepicker<E extends HTMLElement = HTMLInputElement> {
    constructor(el: string | E, opts?: Partial<AirDatepickerOptions>);
    selectDate: (
        date: AirDatepickerDate | AirDatepickerDate[],
        opts?: { updateTime?: boolean; silent?: boolean }
    ) => void;
}


@Component({
    name: 'bs-form-datepicker',

    render: function (this: BsFormDatepicker, h) {
        const outlinePath = 'M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z';
        const solidPath = 'M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5z';

        const g = h(
            'g',
            {},
            [
                h('path', { attrs: { d: this.isHovered ? solidPath : outlinePath } })
            ]
        );

        const svg = h(
            'svg',
            {
                attrs: {
                    viewBox: '0 0 16 16', width: '1em', height: '1em', focusable: 'false', role: 'img', 'aria-label': 'calendar',
                    'aria-hidden': 'true', xmlns: 'http://www.w3.org/2000/svg', fill: 'currentColor'
                },
            },
            [g]
        );

        return h(
            'button',
            {
                staticClass: 'btn',
                attrs: { type: 'button' },
                class: { 'btn-secondary': 'btn-secondary', 'dropdown-toggle': 'dropdown-toggle' },
                style: { 'border-top-left-radius': '0.3rem', 'border-bottom-left-radius': '0.3rem', },
                on: {
                    // TODO: is this handler a better way to open the datepicker?
                    //click: () => {
                    //},
                    mouseover: () => {
                        this.isHovered = true;
                    },
                    mouseout: () => {
                        this.isHovered = false;
                    },
                },
            },
            [svg]
        );
    },

    mounted(this: BsFormDatepicker) {
        // create fake input element to be used by the datepicker
        const $btn = $(<HTMLElement>this.$el);
        const $input = $(`<input style="width: 0; height: 0; outline: 0; border: none; padding: 0; position: absolute; left: 3rem; top: 2.25rem; z-index: -1;"/>`).appendTo($btn);

        // TODO: show datepicker explicitly instead of relying on the default behaviour?
        $btn.on('click', function () {
            $input.trigger('focus');
        });

        const adpSettings = AdpDefaultSettings;
        adpSettings.onSelect = ({ date }) => {
            const dateStr = DateTimeHelper.formatDateIso(<Date>date);

            // pass selected date to the model
            this.$emit('change', dateStr);
        };

        // set parent modal as a container for the datepicker
        adpSettings.container = <HTMLElement>this.$el.closest('.modal');

        // TODO: maybe better positioning using popper.js is needed
        adpSettings.position = 'bottom right';

        this.datePicker = new AirDatepicker($input.get(0), adpSettings);
        if (this.value) {
            this.datePicker.selectDate(new Date(this.value));
        }
    },

    model: {
        prop: 'value',
        event: 'change',
    },

})

export default class BsFormDatepicker extends Vue {
    @Prop() private value!: string;

    private isHovered = false;
    private datePicker!: AirDatepicker<HTMLElement>;

    @Watch('value') onValueChanged(newVal: string, oldVal: string) {
        const date = new Date(newVal);
        this.datePicker.selectDate(date);
    }
}


import moment from 'moment';
import { TableItem } from '../Models/TableItem';
import _ from 'lodash';

export class TableUtils {

    public static sortTableItemsByNumber(a: TableItem, column: string[], b: TableItem, dir: number) {
        if (_.get(a, [...column]) < _.get(b, [...column])) {
            return -1 * dir;
        }
        if (_.get(a, [...column]) > _.get(b, [...column])) {
            return dir;
        }
        return 0;
    }

    public static sortTableItemsByDate(a: TableItem, column: string[], b: TableItem, dir: number) {
        if (moment(_.get(a, [...column])) < moment(_.get(b, [...column]))) {
            return -1 * dir;
        }
        if (moment(_.get(a, [...column])) > moment(_.get(b, [...column]))) {
            return dir;
        }
        return 0;
    }

    public static sortTableItemsByName(a: TableItem, column: string[], b: TableItem, dir: number) {
        if (_.get(a, [...column])?.toLowerCase() < _.get(b, [...column])?.toLowerCase()) {
            return -1 * dir;
        }
        if (_.get(a, [...column])?.toLowerCase() > _.get(b, [...column])?.toLowerCase()) {
            return dir;
        }
        return 0;
    }

}

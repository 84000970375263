
import { ProjectStatus } from './Enums';
import { BaseEntity } from './BaseEntity';
import { BaseListEntityDefault } from './BaseListEntity';

export interface Project extends BaseEntity {
    measure: string;
    status: ProjectStatus;
    issue: string;
    nextSteps: string;
    projectListId: string;
    issues?: string;
}

export const ProjectDefault: Project = {
    id: '',
    name: '',
    measure: '',
    status: ProjectStatus.Green,
    issue: '',
    nextSteps: '',
    projectListId: '',
    ownerId: '',
    ownerName: '',
    createdBy: '',
    createdOn: new Date(),
    modifiedBy: '',
    modifiedOn: new Date(),

    list: BaseListEntityDefault,
};

<template>
    <div class="modal addItemModal addActionsModal fade add-item-modal add-action-modal" ref="actionModal" id="addActionModal" tabindex="-1"
         aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog custom-xl-modal">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="title">
                        <template v-if="isEditing || recordId.length > 0">
                            <h5 class="modal-title">Edit Action</h5>
                            <p>Use the input fields below to edit the existing action</p>
                        </template>

                        <template v-else>
                            <h5 class="modal-title">Add New Action</h5>
                            <p>Use the input fields below to track a new action</p>
                        </template>
                    </div>
                    <a href="#" ref="closeBtn" class="close-modal" data-bs-dismiss="modal"
                       aria-label="Close">Close <i class="fas fa-times"></i></a>
                </div>

                <div class="modal-body">
                    <!-----------FORM----------------->
                    <form action="" class="add-item-form" @submit.prevent="" v-if="!isLoading">
                        <!--            Name-->
                        <div class="form-group" :class="{ 'form-group--error': $v.actionValue.$error }"
                             style="max-width: 294px" @click="focusOnClick">
                            <label for="actionName" class="required-field">Actions</label>
                            <input v-model="actionValue" name="action" type="text" id="actionName" autocomplete="off"
                                   placeholder="Write the name of the action"
                                   @focusout="validInputSet('actionValue', 'actionNameSet')"
                                   :class="{ 'field-set': actionValue }" maxlength="160" v-on:keydown.enter="onEnter($event, '.add-more-comment label')">
                            <div class="msg-error" v-if="!$v.actionValue.required &amp;&amp; $v.actionValue.$dirty">
                                Field is required
                            </div>
                        </div>

                        <!--            Comments-->
                        <div class="form-group" style="max-width: 294px" @click="focusOnClick">
                            <label for="comment">Comments</label>
                            <template v-if="commentValueFromEdit">
                                <p v-if="recordId.length > 0"
                                   style="font-size: 14px; padding: 11px 0;">{{ commentValueFromEdit }}</p>
                            </template>
                            <input v-if="recordId.length == 0" autocomplete="off"
                                   :class="{'field-set': commentValue}"
                                   v-model="commentValue" type="text" id="comment"
                                   @focusout="validInputSet('commentValue', 'commentsSet')" maxlength="200" v-on:keydown.enter="onEnter($event)">

                            <div class="add-more-comment" v-else>
                                <label for="newComment" class="add-new-item-link mb-3" :class="{'d-none': addNewComment}"
                                       @click="startAddNewComment" href="#" v-on:keydown.enter="addMoreEnter($event)">
                                    <span class="add-icon-container">
                                        <i class="fas fa-plus"></i>
                                    </span> Add new Comment
                                </label>

                                <bs-collapse id="add-more-comments">
                                    <input :class="{ 'field-set': commentValue }"
                                           v-model="commentValue" type="text" id="newComment" v-on:keydown.enter="onEnter($event)">
                                </bs-collapse>
                            </div>

                        </div>

                        <!--            Responsible User-->
                        <div class="form-group" :class="{ 'form-group--error': $v.responsibleUserSelected.$error }"
                             style="max-width: 170px" @click="focusOnClick" v-on:keydown.enter="onEnter($event, '#listId a')">
                            <label for="responsibleUser" class="required-field">Responsibility</label>

                            <div class="add-user">
                                <people-picker :options="filteredUsers" :user="responsibleUserSelected" ref="peoplePicker"></people-picker>
                            </div>
                            <div class="msg-error"
                                 v-if="$v.responsibleUserSelected.$invalid &amp;&amp; $v.responsibleUserSelected.$dirty">
                                Field is required
                            </div>
                        </div>

                        <!--            Select List-->
                        <div class="form-group" :class="{ 'form-group--error': $v.selectedList.$error }"
                             style="max-width: 135px" @click="focusOnClick" v-on:keydown.enter="onEnter($event, '#targetDateId input')">
                            <label class="required-field">List</label>

                            <div class="jdi-search-select" id="listId">
                                <div class="select-list-container" v-if="!(selectedList && selectedList.id)">
                                    <v-select class="list-select" v-model="selectedList" label="name"
                                              :options="actionsListsCom" placeholder="Select"
                                              @input="validInputSet('selectedList', 'listSelectSet')"></v-select>
                                </div>
                                <div v-else class="result-select" :style="'background: #' + selectedList.colour">
                                    <span>{{ selectedList.name }}</span>
                                    <a href="#" @click.prevent="deselectList()"><i class="fas fa-times"></i></a>
                                </div>
                            </div>

                            <div class="msg-error"
                                 v-if="!$v.selectedList.required &amp;&amp; $v.selectedList.$dirty">
                                Field is
                                required
                            </div>
                        </div>

                        <!--            Date Picker-->
                        <div class="form-group" style="max-width: 155px" @click="focusOnClick" id="targetDateId">
                            <label class="required-field">Target date</label>
                            <bs-input-group>
                                <bs-form-input id="targetDate" class="date" :value="dateFormat(date)" type="text" readonly
                                              placeholder="DD-MMM-YY" autocomplete="off" v-on:keydown.enter="onEnter($event)"></bs-form-input>
                                <bs-form-datepicker v-model="date" aria-controls="targetDate"></bs-form-datepicker>
                            </bs-input-group>
                        </div>
                    </form>
                    <div v-else class="text-center">
                        <div class="spinner-border text-secondary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="modal-footer-left-container">
                        <div v-if="editRecord.createdBy" class="jdi-label">Created by {{editRecord.createdBy}} on {{$moment(editRecord.createdOn).format("DD-MMM-YY")}} at {{$moment(editRecord.createdOn).format("HH:mm")}} </div>
                        <div v-if="editRecord.modifiedBy" class="jdi-label">Modified by {{editRecord.modifiedBy}} on {{$moment(editRecord.modifiedOn).format("DD-MMM-YY")}} at {{$moment(editRecord.modifiedOn).format("HH:mm")}} </div>
                    </div>
                    <div class="modal-footer-rigt-container">
                        <a href="#" class="save-modal-button jdi-green-button" v-on:click.prevent="onAddAction()">Save to action list</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';

import { Comment } from '../../Models/Comment';
import { Action } from '../../Models/Action';
import { BaseListEntity } from '../../Models/BaseListEntity';
import { ActionList } from '../../Models/ActionList';
import { User } from '../../Models/User';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import VueSimpleSuggest from 'vue-simple-suggest';
import { VueSelect } from 'vue-select';
import componentNavigationMixins from '../../mixins/componentNavigationMixins';

import PeoplePicker from '../PeoplePicker/PeoplePicker.vue';
import { ContactToCreateFromEntity } from '../../Models/Contact';
import { AssignType } from '../../Models/Enums';
import BsCollapse from '../BsCollapse';
import BsInputGroup from '../BsInputGroup.vue';
import BsTextInput from '../BsTextInput';
import BsFormDatepicker from '../BsFormDatepicker';
import ApiRequestor from '../../Services/ApiRequestor';
import moment from 'moment';
import _ from 'lodash';

@Component({
    mixins: [validationMixin, componentNavigationMixins],
    name: 'add-action-modal',
    template: '#add-action-modal-id',
    components: {
        'vue-simple-suggest': VueSimpleSuggest,
        'v-select': VueSelect,
        'people-picker': PeoplePicker,
        'bs-collapse': BsCollapse,
        'bs-input-group': BsInputGroup,
        'bs-form-input': BsTextInput,
        'bs-form-datepicker': BsFormDatepicker,
    },
    validations: {
        actionValue: { required },
        responsibleUserSelected: { required },
        selectedList: { required },
    }
})

export default class AddActionModal extends Vue {

    @Watch('selectedListFromFilter') onListChanged(newVal: any, oldVal: any) {
        this.setListFromFilter();
    }

    @Watch('isEditing') onIsEditingChanged(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.setActionData();
        }
        else {
            this.cleanForm();
        }
    }

    @Watch('$store.state.hasContactBeenCreated') onContactHasBeenCreatedChanged(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.isLoading = true;
            const me = this;
            ApiRequestor.get('GetOwners')
                .then((res) => {

                    this.responsibleUsers = res.data as User[];

                    const contactDetails: ContactToCreateFromEntity = this.$store.state.contactToCreateFromEntity;
                    if (contactDetails != null && contactDetails.action != null) {
                        this.actionValue = contactDetails.action.name;
                        const comment = contactDetails.action.comments?.length ? contactDetails.action.comments[0].text : '';

                        this.commentValueFromEdit = comment;
                        this.commentValue = comment;
                        this.recordId = contactDetails.action.id;

                        this.date = contactDetails.action.targetDate;
                        this.archived = contactDetails.action.archived as boolean;
                        this.selectedList = contactDetails.action.list as BaseListEntity;
                        this.responsibleUserSelected = {} as User;

                        if (contactDetails.id) {
                            this.responsibleUserSelected = {
                                id: contactDetails.id,
                                ownerName: contactDetails.name,
                                type: AssignType.Contact,
                                isOwnerImageUploaded: false,
                            } as User;
                        }
                    }

                    this.$store.dispatch('setNewContactCreation', {});
                    this.$store.dispatch('setRefreshContacts', false);
                    this.isLoading = false;
                    setTimeout(() => {
                        (<PeoplePicker>me.$refs.peoplePicker).selectUser(me.responsibleUserSelected);
                        if (me.recordId.length > 0) {
                            me.$router.push({ path: 'actions', query: { id: me.recordId } }).catch((er) => er);
                        }
                    }, 0);
                })
                .catch((e) => {
                    console.log(e.message);
                    this.responsibleUsers = [];
                });

        }
    }

    @Watch('$store.state.contactToCreateFromEntity') onContactToCreateChanged(newVal: ContactToCreateFromEntity, oldVal: ContactToCreateFromEntity) {
        if (newVal && newVal.shouldBeCreated && newVal.shouldBeCreated != oldVal.shouldBeCreated) {
            const comment = this.commentValue;
            newVal.action = {
                name: this.actionValue,
                comments: [{ text: comment } as Comment],
                targetDate: this.date,
                archived: this.archived,
                list: this.selectedList,
                id: this.recordId,
            } as Action;
            this.$store.dispatch('setNewContactCreation', newVal);
        }
    }

    @Watch('$store.state.selectedResponsibleUser') onSelectedResponsibleUserChanged(newVal: User, oldVal: User) {
        if (!newVal) {
            this.responsibleUserSelected = {} as User;
        }
        else {
            this.responsibleUserSelected = newVal;
        }

        this.validInputSet('responsibleUserSelected', 'addUserSet');
    }

    private isLoading = false;
    private editRecord: Action = {} as Action;
    private date?: string = new Date().toISOString().slice(0, 10);
    private actionNameSet = false;
    private commentsSet = false;
    private addUserSet = false;
    private listSelectSet = false;
    private commentValueFromEdit = '';
    private addNewComment = false;

    private autoCompleteStyle = {
        vueSimpleSuggest: '',
        inputWrapper: '',
        defaultInput: 'users-select',
        suggestions: 'add-user-dropdown-menu',
        suggestItem: 'add-user-dropdown-list-item',
    };
    private responsibleUsers: User[] = [];
    private responsibleUserSelected: User = {} as User;

    private recordId = '';
    private actionValue = '';
    private commentValue = '';
    private archived = false;

    private actionsLists: ActionList[] = [];
    //private listSelectOpen = false;
    //private listOptionSelected = 'Select';
    private selectedList: BaseListEntity = {} as BaseListEntity;

    get isEditing() {
        return this.$store.state.isEditing;
    }

    get selectedListFromFilter() {
        return this.$store.state.selectedListFromFilter;
    }

    get actionsListsCom(): ActionList[] {
        if (this.responsibleUserSelected && this.responsibleUserSelected.id && this.responsibleUserSelected.type == 0) {
            const res = this.actionsLists.filter((l: BaseListEntity) => !l.isPrivate || l.members.some((m: User) => m.id == this.responsibleUserSelected.id));
            return res;
        }

        return this.actionsLists;
    }

    get filteredUsers() {
        if (this.selectedList && this.selectedList.id && this.selectedList.isPrivate) {
            return this.responsibleUsers.filter((u: User) => u.type == 1 || (this.selectedList.members && this.selectedList.members.some((m) => m.id == u.id)) || u.id == this.editRecord.ownerId);
        }
        return this.responsibleUsers;
    }

    private startAddNewComment(): void {
        this.$root.$emit('bs-toggle', 'add-more-comments');

        this.addNewComment = true;
    }

    private deselectList() {
        this.selectedList = {} as BaseListEntity;
    }

    addMoreEnter($event: JQuery): void {
        this.addNewComment = true;
        setTimeout(() => {
            componentNavigationMixins.methods.onEnter($event, '#newComment');
        }, 0);
    }

    setListFromFilter(): void {
        if (this.selectedListFromFilter) {
            this.selectedList = this.selectedListFromFilter as BaseListEntity;
            this.addUserSet = true;
            this.listSelectSet = true;
        }
        else {
            this.selectedList = {} as BaseListEntity;
            this.addUserSet = false;
            this.listSelectSet = false;
        }
    }

    getDropdownLists(): void {
        ApiRequestor.get('GetOwners')
            .then((res) => this.responsibleUsers = res.data as User[])
            .catch((e) => {
                console.log(e.message);
                this.responsibleUsers = [];
            });
        ApiRequestor.get('GetMemberActionLists')
            .then((res) => this.actionsLists = res.data as ActionList[])
            .catch((e) => this.actionsLists = []);
    }

    validInputSet(value: string, inputNameSet: string): void {
        const modal: any = this;
        if (!_.isEmpty(modal[value])) {
            modal[inputNameSet] = true;
        }
    }

    onAddAction(): void {
        const request = {
            Name: this.actionValue,
            OwnerId: this.responsibleUserSelected && this.responsibleUserSelected.id,
            OwnerName: this.responsibleUserSelected && this.responsibleUserSelected.ownerName,
            ActionListId: this.selectedList && this.selectedList.id,
            TargetDate: this.date,
            Archived: this.archived,
            Comment: {
                text: this.commentValue,
            }
        };
        this.$v.$touch();
        if (!this.$v.$invalid) {
            this.getOwnerId(request);
            this.$store.dispatch('actionSetSelectedListFromFilter', this.$store.state.filterLists);
        }
    }

    saveAction(request: any): void {
        if (this.isEditing || <number>this.recordId.length > 0) {
            request.id = this.recordId;

            ApiRequestor.put(`EditAction/${request.id}`, request)
                .then((res) => {
                    if (res.data.success) {
                        (this.$refs.closeBtn as HTMLElement).click();
                        this.$store.dispatch('setRefreshActions', true);
                    }
                    else {
                        // alert(res.data.message);
                    }
                })
                .catch((e) => console.log(e.message));
        }
        else {
            ApiRequestor.post(`AddAction`, request)
                .then((res) => {
                    if (res.data.success) {
                        (this.$refs.closeBtn as HTMLElement).click();
                        this.$store.dispatch('setRefreshActions', true);

                    }
                    else {
                        // alert(res.data.message);
                    }
                })
                .catch((e) => console.log(e.message));
        }
    }

    getOwnerId(requestSave: any): void {
        const owner: User = this.responsibleUsers.find((x) => x.id == requestSave.OwnerId) as User;

        if (owner) {
            requestSave.OwnerId = owner.id;
            requestSave.OwnerName = owner.ownerName;
            this.saveAction(requestSave);
        }
    }

    setActionData(): void {
        this.editRecord = this.$store.state.editRegisterItem;
        this.recordId = this.editRecord.id;
        this.actionValue = this.editRecord.name;
        this.commentValueFromEdit = this.editRecord.comments?.length ? this.editRecord.comments[0].text : '';
        this.responsibleUserSelected = {
            id: this.editRecord.ownerId,
            ownerName: this.editRecord.ownerName,
            type: this.editRecord.ownerType,
            isOwnerImageUploaded: this.editRecord.isOwnerImageUploaded,
        } as User;
        this.date = this.editRecord.targetDate;
        this.archived = this.editRecord.archived as boolean;
        this.selectedList = this.editRecord.list as BaseListEntity;
        if (!this.selectedList.members) {
            const list = this.actionsLists.find((l) => l.id == this.selectedList.id);
            if (list && list.members && list.members.length > 0) {
                this.selectedList.members = list.members;
            }
        }

        this.$refs.peoplePicker && (<PeoplePicker>this.$refs.peoplePicker).selectUser({
            id: this.editRecord.ownerId,
            ownerName: this.editRecord.ownerName,
            type: this.editRecord.ownerType,
            isOwnerImageUploaded: this.editRecord.isOwnerImageUploaded,
        } as User);

    }

    cleanForm(): void {
        this.editRecord = {} as Action;
        this.recordId = '';
        this.actionValue = '';
        this.commentValue = '';
        this.responsibleUserSelected = {} as User;
        //this.listOptionSelected = 'Select';
        this.selectedList = this.selectedListFromFilter?.length === 1 ? this.selectedListFromFilter[0] : 
            this.selectedListFromFilter?.id ? this.selectedListFromFilter : {} as BaseListEntity;    
        this.date = new Date().toISOString().slice(0, 10);
        this.$store.dispatch('setEdit', false);
        this.archived = false;
        this.actionNameSet = false;
        this.commentsSet = false;
        this.addUserSet = !!this.selectedListFromFilter?.length;
        this.listSelectSet = !!this.selectedListFromFilter?.length;
        this.addNewComment = false;
        this.$v.$reset();
    }

    dateFormat(value: string) {
        return moment(value).format('DD-MMM-YY');
    }

    mounted(): void {
        this.getDropdownLists();
        this.cleanForm();
        $(<Element>this.$refs.actionModal).on('hidden.bs.modal', () => {
            this.cleanForm();
            // navigate to projects/actions page after closing the modal
            this.$router.push({ name: 'actions' }).catch((er) => er);
        });
    }
}
</script>

<template>
    <div class="modal fade addItemModal addProjectsModal add-item-modal add-project-modal" data-bs-backdrop="static" id="viewProjectModal" name="viewProjectModal"
         tabindex="-1" aria-hidden="true" ref="viewProjectModal">
        <div class="modal-dialog custom-xl-modal">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="title">
                        <h5 class="modal-title">View Project</h5>
                    </div>
                    <a href="#" ref="closeBtn" class="close-modal" data-bs-dismiss="modal"
                       aria-label="Close">Close <i class="fas fa-times"></i></a>
                </div>

                <div class="modal-body">
                    <form action="" class="add-item-form">
                        <!--            Title-->
                        <div class="form-group" style="max-width: 210px">
                            <label for="actionName">Title</label>
                            <div> {{record.name}}</div>
                        </div>

                        <!--            Responsible user-->
                        <div class="form-group" style="max-width: 180px">
                            <label for="responsibleUser">Responsibility</label>
                            <div class="add-user">
                                <user-picture :user-id="record.ownerId" :user-name="record.ownerName" :is-profile-image-uploaded="record.isOwnerImageUploaded"
                                              :user-type="record.ownerType"></user-picture>
                            </div>

                        </div>

                        <!--            Measure-->
                        <div class="form-group measure-text-area position-relative" style="max-width: 130px" id="measureContainerId">
                            <label for="measure" class="required-field">Measure</label>
                            <div>
                                {{record.measure}}
                            </div>
                        </div>

                        <!--            Status-->
                        <div class="form-group" style="max-width: 82px">
                            <label>Status</label>
                            <div class="work-status" :class="{active: record.status === 1, delayed: record.status === 2, stopped: record.status === 3}">
                                <span v-if="record.status === 1"><i class="fas fa-circle"></i></span>
                                <span v-else-if="record.status === 2"><i class="fas fa-triangle"></i></span>
                                <span v-else-if="record.status === 3"><i class="fas fa-diamond"></i></span>
                            </div>
                        </div>

                        <!--            Issues-->
                        <div class="form-group issues-text-area" style="max-width: 140px">
                            <label for="issues">Issues</label>

                            <template v-if="record.issues">
                                <read-more more-str="View more" :text="record.issues" link="#" less-str="View less"
                                           :max-chars="50"></read-more>
                            </template>

                        </div>

                        <!--            List-->
                        <div class="form-group" style="max-width: 90px">
                            <label>List</label>

                            <div class="category-list jdi-tooltip" :style="'background: #' + record.list.colour">
                                {{truncate(record.list.name )}}
                                <span class="jdi-tooltiptext">{{record.list.name}}</span>
                            </div>
                        </div>

                        <!--            NextSteps-->
                        <div class="form-group nextSteps-text-area position-relative" style="max-width: 130px" id="nextStepsContainerId">
                            <label for="nextSteps">Next Steps</label>
                            <read-more more-str="View more" :text="record.nextSteps || ''" link="#" less-str="View less"
                                       :max-chars="50"></read-more>
                        </div>

                        <div class="form-group" style="max-width: 185px">
                            <label>Close Date</label>
                            <span>{{ $moment(record.modifiedOn).format("DD-MMM-YY") }}</span>

                        </div>
                    </form>

                </div>
                <div class="modal-footer">
                    <div class="modal-footer-left-container">
                        <div v-if="record.createdBy" class="jdi-label">Created by {{record.createdBy}} on {{$moment(record.createdOn).format("DD-MMM-YY")}} at {{$moment(record.createdOn).format("HH:mm")}} </div>
                        <div v-if="record.modifiedBy" class="jdi-label">Modified by {{record.modifiedBy}} on {{$moment(record.modifiedOn).format("DD-MMM-YY")}} at {{$moment(record.modifiedOn).format("HH:mm")}} </div>
                    </div>
                    <div class="modal-footer-rigt-container">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';

import { Project, ProjectDefault } from '../../Models/Project';
import UserPicture from '../UserPicture/UserPicture.vue';
import ReadMore from '../ReadMore.vue';

@Component({
    name: 'view-project-modal',
    template: '#view-project-modal-id',
    components: {
        'user-picture': UserPicture,
        'read-more': ReadMore,
    },
})

export default class ViewProjectModal extends Vue {
    @Watch('$store.state.viewRegisterItem') onProjectChanged(newVal: Project, oldVal: Project) {
        if (newVal && newVal.id) {
            this.record = this.$store.state.viewRegisterItem;
        }
    }

    private record: Project = ProjectDefault;

    truncate(str: string): string {
        if (!str) return '';

        if (str.length > 8) {
            return str.substring(0, 8) + '...';
        }
        return str;
    }

    mounted(): void {
        const me = this;
        $(<Element>this.$refs.viewProjectModal).on('hidden.bs.modal', function () {
            if (me.$route.query.id) {
                me.$router.push({ name: 'projects' }).catch((er) => er);
            }
        });
    }
}
</script>

<template>
    <div class="responsible-user user-picture-container">
        <span v-if="userPictureType == 1" class="name-acronym">
            {{ userNameAcronym(userName) }}
        </span>

        <span class="name-acronym user-picture" v-if="userPictureType == 3" :style="'background-image: url(' + getUserImageStyle(userId) + ')' ">
        </span>

        <span class="name-acronym user-picture" v-if="userPictureType == 2" :style="'background-image: url(' + getUserImageStyle(userId) + ')' ">
            {{ userNameAcronym(userName) }}
        </span>

        <span class="user-name" v-if="isNameVisible">{{ userName }}</span>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { UserUtils } from '../../Utils/UserUtils';
declare const AzureStorageUrl: string;

@Component({
    name: 'user-picture',
    template: '#user-picture-id'
})

export default class UserPicture extends Vue {
    @Prop({ default: '', type: String }) userId!: string;
    @Prop({ default: '', type: String }) userName!: string;
    @Prop({ default: -1, type: Number }) userType!: number;
    @Prop({ default: false, type: Boolean }) isProfileImageUploaded!: boolean;
    @Prop({ default: true, type: Boolean }) isNameVisible!: string;

    get userPictureType(): UserPictureType {
        if (this.userType == 0) {
            return this.isProfileImageUploaded ? UserPictureType.CustomImage : UserPictureType.Colored;
        }

        return UserPictureType.Gray;
    }

    userNameAcronym(userName: string): string {
        return UserUtils.userNameAcronym(userName);
    }

    getUserImageStyle(userId: string) {
        return `${AzureStorageUrl}/users/${userId}/avatars/avatar.png?${(Math.random() + 1).toString(36).substring(7)}`;
    }
}

enum UserPictureType {
    Gray = 1,
    Colored = 2,
    CustomImage = 3
}
</script>

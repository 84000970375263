import type { AxiosResponse, AxiosStatic } from 'axios';

declare const JdiServiceUrl: string;
declare const JwtToken: string;
declare const axios: AxiosStatic;

export default class ApiRequestor {
    public static async get(method: string): Promise<AxiosResponse<any>> {
        return await axios.get(`${JdiServiceUrl}${method}`, {
            headers: {
                'Authorization': 'Bearer ' + JwtToken
            }
        });
    }

    public static async put(endpoint: string, request: any): Promise<any> {
        return await axios.put(`${JdiServiceUrl}${endpoint}`, request, {
            headers: {
                'Authorization': 'Bearer ' + JwtToken
            }
        });
    }

    public static async post(endpoint: string, request: any) {
        return await axios.post(`${JdiServiceUrl}${endpoint}`, request, {
            headers: {
                'Authorization': 'Bearer ' + JwtToken
            }
        });
    }

    public static async delete(endpoint: string) {
        return await axios.delete(`${JdiServiceUrl}${endpoint}`, {
            headers: {
                'Authorization': 'Bearer ' + JwtToken
            }
        });
    }
}

<template>
    <div class="modal fade add-item-modal reassign-contact-modal" ref="reassignContactModal" id="reassignContactModal" tabindex="-1"
         aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog custom-xl-modal">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="title" v-if="modalTitle.length == 0">
                        <template v-if="isDeleting">
                            <h5 class="modal-title">Delete a Contact</h5>
                        </template>

                        <template v-else>
                            <h5 class="modal-title">Reassign Records</h5>
                        </template>
                    </div>
                    <div class="title" v-else>
                        <h5 class="modal-title">{{modalTitle}}</h5>
                    </div>
                    <a href="#" ref="closeBtn" class="close-modal" data-bs-dismiss="modal"
                       aria-label="Close">Close <i class="fas fa-times"></i></a>
                </div>

                <div class="modal-body pt-5">
                    <h3 v-if="contactName && contactName.length > 0">{{contactName}}</h3>
                    <div v-if="modalBody.length!=0">{{modalBody}}</div>
                    <div v-else-if="isDeleting">
                        You are about to delete a contact from the JDI application.
                        Please choose what action to take regarding all records currently assigned to this person.
                    </div>
                    <div v-else>
                        Please choose what action to take regarding all records currently assigned to this person.
                    </div>

                    <!-----------FORM----------------->
                    <form contact="" class="reassign-contact-form mt-4" @submit.prevent="">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="assignContact" id="assignContact_me"
                                   value="1" v-model="assignmentType" :disabled="assignToMeDisabled" />
                            <label class="form-check-label" for="assignContact_me"> Assign to me </label>
                        </div>
                        <div class="details">Assign all the records that this user owns to myself.</div>

                        <div class="form-check mt-4">
                            <input class="form-check-input" type="radio" name="assignContact" id="assignContact_another"
                                   value="2" v-model="assignmentType" />
                            <label class="form-check-label" for="assignContact_another"> Assign to another person </label>
                        </div>
                        <div class="details">Assign all the records that this person owns to another person:</div>
                        <div class="add-item-form mt-2" v-if="assignmentType == 2">
                            <div class="form-group">
                                <div class="add-user">
                                    <people-picker :options="ownersList" label="ownerName" id="id" ref="peoplePicker"></people-picker>
                                </div>
                                <div class="msg-error" v-if="isPersonRequired && isPersonDirty">Please select a person</div>
                            </div>
                        </div>
                        <template v-if="isDeleting">
                            <div class="form-check text-danger mt-4">
                                <input class="form-check-input" type="radio" name="assignContact" id="assignContact_delete" value="3" v-model="assignmentType" />
                                <label class="form-check-label" for="assignContact_delete"> Do not reassign records - Delete them permanently instead </label>
                            </div>
                            <div class="details text-danger">WARNING: This action is irreversible!</div>
                        </template>
                    </form>
                </div>
                <div class="modal-footer">
                    <div class="modal-footer-left-container">
                    </div>
                    <div class="modal-footer-rigt-container">
                        <a href="#" class="save-modal-button jdi-green-button" v-if="!isDeleting && !isLoading" v-on:click.prevent="onSave()">OK</a>
                        <a href="#" class="save-modal-button jdi-green-button" v-if="isDeleting && !isLoading" v-on:click.prevent="onDelete()">OK</a>
                        <a href="#" class="cancel-modal-button ms-3" v-on:click.prevent="onCancel()" v-if="!isLoading">Cancel</a>
                        <div class="spinner-border text-secondary" role="status" v-if="isLoading">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>

                   
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Contact, ReassignContactModel } from '../../../Models/Contact';
import { JdiState } from '../../../Store/Types';
import { ContactsService } from '../../../Services/ContactsService';
import { User } from '../../../Models/User';
import PeoplePicker from '../../PeoplePicker/PeoplePicker.vue';

declare const IsJdiUser: boolean;
declare const CurrentUserId: string;

@Component({
    name: 'reassign-contact-modal',
    template: '#reassign-contact-modal-id',
    components: {
        'people-picker': PeoplePicker
    },
})

export default class ReassignContactModal extends Vue {
    @Prop({ default: '', type: String }) modalTitle!: string;
    @Prop({ default: '', type: String }) modalBody!: string;
    @Prop() okCallback!: (model: ReassignContactModel) => any;
    @Prop({ default: '', type: String }) contactName!: string;
    @Prop({ default: true, type: Boolean }) assignToMe!: boolean;

    private selectedUser: User = {} as User;
    private reassignRecord: Contact = {} as Contact;
    private assignmentType = 1;
    private owners: User[] = [] as User[];
    private isLoading = false;
    private isDeleting = true;
    private canBeAssignToMe = false;
    private isPersonDirty = false;

    @Watch('$store.state.reassignContact') onReassignChanged(newVal: Contact, oldVal: Contact) {
        if (newVal && newVal.id) {
            this.setContactData(newVal);
            this.isDeleting = false;
        }
    }

    @Watch('$store.state.deleteContact') onDeletingChanged(newVal: Contact, oldVal: Contact) {
        if (newVal && newVal.id) {
            this.setContactData(newVal);
            this.isDeleting = true;
        }
    }

    get getState(): JdiState {
        return this.$store.state as JdiState;
    }

    get isPersonRequired(): boolean {
        return this.assignmentType == 2 && (!this.$store.state.selectedResponsibleUser || !this.$store.state.selectedResponsibleUser.id);
    }

    async onSave() {
        await this.sendCall(false);
    }

    async onDelete() {
        await this.sendCall(true);
    }

    get ownersList() {
        if (this.assignToMeDisabled) {
            return this.owners.filter((t: User) => t.id != CurrentUserId && t.id != this.reassignRecord.id);
        }

        return this.owners.filter((t: User) => t.id != this.reassignRecord.id);
    }

    get assignToMeDisabled() {
        return !this.canBeAssignToMe || !this.assignToMe;
    }

    async sendCall(isDelete: boolean) {
        const model = { userFromId: this.reassignRecord.id } as ReassignContactModel;
        if (this.assignmentType == 1) {
            model.shouldBeAssignedToMe = true;
        }
        else if (this.assignmentType == 2) {
            if (!this.$store.state.selectedResponsibleUser || !this.$store.state.selectedResponsibleUser.id) {
                this.isPersonDirty = true;
                return;
            }

            model.userToId = this.$store.state.selectedResponsibleUser.id;
            model.userToName = this.$store.state.selectedResponsibleUser.ownerName;
        }
        else {
            model.shouldBeDeleted = true;
        }

        try {
            this.isLoading = true;
            let res;
            if (this.okCallback != null) {
                res = await this.okCallback(model);
                if (res && res.data && res.data.success) {
                    (this.$refs.closeBtn as HTMLElement).click();
                }
                else {
                    alert('Something went wrong. Try later!');
                }
            }
            else {
                res = isDelete ? await ContactsService.DeleteContact(model) : await ContactsService.ReassignContact(model);
                if (res && res.data && res.data.success) {
                    (this.$refs.closeBtn as HTMLElement).click();
                    this.$store.dispatch('setRefreshContacts', true);
                }
                else {
                    alert('Something went wrong. Try later!');
                }
            }
            this.isLoading = false;
            this.$emit('on-save');
        }
        catch (e) {
            this.isLoading = false;
            alert('Something went wrong. Try later!');
            console.log(e);
        }
    }

    onCancel(): void {
        this.cleanForm();
        (this.$refs.closeBtn as HTMLElement).click();
    }

    cleanForm() {
        (<PeoplePicker>this.$refs.peoplePicker)?.selectUser({} as User);
        this.assignmentType = 1;
    }

    setContactData(contact: Contact): void {
        this.reassignRecord.id = contact.id;
        this.reassignRecord.name = contact.name;

        this.owners = this.$store.state.owners;
    }

    beforeUpdate() {
        if (!this.assignToMe) {
            this.assignmentType = 2;
        }
    }

    mounted() {
        this.canBeAssignToMe = IsJdiUser;
        if (!IsJdiUser || !this.assignToMe) {
            this.assignmentType = 2;
        }

        const me = this;

        $(<Element>this.$refs.reassignContactModal).on('hidden.bs.modal', function () {
            me.cleanForm();
        });
    }
}
</script>

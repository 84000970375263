import type { AirDatepickerLocale, AirDatepickerOptions } from 'air-datepicker';
import { DateTimeHelper } from '../Utils/DateTimeHelper';
import { createPopper } from '@popperjs/core';
import AirDatepicker from 'air-datepicker';

// TODO: allow translation of "Today" and "Clear" buttons
const localeEn: Partial<AirDatepickerLocale> = {
    days: DateTimeHelper.weekDaysFull,
    daysShort: DateTimeHelper.weekDays,
    daysMin: DateTimeHelper.weekDaysShort,
    months: DateTimeHelper.monthNamesFull,
    monthsShort: DateTimeHelper.monthNames,
    today: 'Today',
    clear: 'Clear',
    dateFormat: 'dd/MM/yyyy',
    timeFormat: 'hh:mm aa',
    firstDay: 0,
};

export const AdpDefaultSettings: Partial<AirDatepickerOptions> = {
    locale: localeEn,
    autoClose: true,
    dateFormat: 'dd/MM/yyyy',
    firstDay: 1,
    navTitles: {
        days: 'MMMM yyyy',
    },
    prevHtml: '<span>«</span>',
    nextHtml: '<span>»</span>',
    position({ $datepicker, $target, $pointer, done }) {
        //const { createPopper } = Popper;
        const popper = createPopper($target, $datepicker, {
            placement: 'top-start',
            modifiers: [
                {
                    name: 'flip',
                    options: {
                        padding: {
                            top: 64
                        }
                    }
                },
                {
                    name: 'offset',
                    options: {
                        offset: [0, 3]
                    }
                },
                {
                    name: 'arrow',
                    options: {
                        element: $pointer
                    }
                }
            ]
        });

        /* Return function which will be called when `hide()` method is triggered.
        It must necessarily call the `done()` function to complete hiding process */
        return function completeHide() {
            popper.destroy();
            done();
        };
    }
};

export default class DatePicker {
    private datePicker: AirDatepicker<HTMLElement>;
    private afterFirstUpdate = false;

    // works for input groups as well as for single inputs
    public getValue(): string | null {
        if (!this.datePicker.selectedDates.length) { return null; }

        const date = this.datePicker.selectedDates[0];
        return DateTimeHelper.formatDateIso(date);
    }

    public setValue(datePickerVal: string | null) {
        if (datePickerVal) {
            const date = DatePicker.parseDate(datePickerVal);
            if (date) {
                this.datePicker.selectDate(date);
            }
        }
        else {
            this.datePicker.clear();
        }
    }

    public clearDateRange() {
        this.datePicker.update({ minDate: false, maxDate: false });
    }

    private static parseDate(datePickerVal: string) {
        const dateStr = datePickerVal.split('T')[0]; // if DateTime string is supplied
        if (dateStr) {
            return new Date(dateStr);
        }
        return null;
    }

    public constructor($picker: JQuery, settings?: DatepickerSettings) {
        const adpSettings = { ...AdpDefaultSettings };

        // handle extra fields used in bootstrap-datepicker
        if (settings?.daysOfWeekDisabled) {
            adpSettings.onRenderCell = ({date, cellType}) => {
                if (cellType === 'day') {
                    if (settings.daysOfWeekDisabled?.split(',').includes(String(date.getDay()))) {
                        return {
                            disabled: true,
                            classes: 'disabled-class'
                        };
                    }
                }
            };
        }

        if (settings) {
            if (settings.clearBtn) {
                adpSettings.buttons = 'clear';
            }
            if (settings.autoclose != undefined) adpSettings.autoClose = settings.autoclose;
            if (settings.weekStart != undefined) adpSettings.firstDay = settings.weekStart;
            adpSettings.minDate = settings.startDate ? DateTimeHelper.ukToIsoDate(settings.startDate) : undefined;
            adpSettings.maxDate = settings.endDate ? DateTimeHelper.ukToIsoDate(settings.endDate) : undefined;
            if (settings.startView != undefined) adpSettings.view = settings.startView;
            if (settings.minView != undefined) adpSettings.minView = settings.minView;

            if (settings.selectedDate != undefined) {
                const selectedDate = DatePicker.parseDate(settings.selectedDate);
                if (selectedDate) adpSettings.selectedDates = [selectedDate];
            }
        }

        let $toggle: JQuery, $input: JQuery;
        if ($picker.is('input')) {
            $toggle = $picker;
            $input = $picker;

            $input.addClass('with-calendar-icon');
            const $icon = $('<i class="far fa-calendar-check text-black-50 calendar-icon"></i>');
            if ($input.closest('.input-group').length > 0) {
                $icon.insertBefore($input);
            }
            else {
                const $container = $('<div style="position: relative"></div>').appendTo($input.parent());
                $input.detach().appendTo($container);
                $icon.appendTo($container);
            }
        }
        else if ($picker.is('.input-group')) {
            $toggle = $picker.children('.input-group-text, BUTTON.btn');
            $input = $picker.children('input');
        }
        else {
            $toggle = $picker;
            const $icon = $picker.children('i');
            const { left: offsetLeft, top: offsetTop } = $icon.offset() ?? {};
            const inputWidth = ($icon.width() ?? 0) + 20;
            $input = $(`<input style="width: ${inputWidth}px; border: none; padding: 0; position: absolute; left: ${(offsetLeft ?? 0) - 10}px; top: ${offsetTop}px; z-index: -1;"/>`).appendTo($picker);
            $(window).resize(function () {
                const { left: newOffsetLeft } = $icon.offset() ?? {};
                $input.css('left', `${(newOffsetLeft ?? 0) - 10}px`);
            });
        }

        adpSettings.onSelect = ({ date }) => {
            $input.trigger('change');
            if (settings?.onSelect) {
                settings.onSelect({ date });
            }
        };

        // if datepicker is not a single input element
        if (!$toggle.is($input)) {
            // Control toggle/button active state based on datepicker state
            adpSettings.onShow = () => {
                $picker.addClass('active');
            };
            adpSettings.onHide = () => {
                $picker.removeClass('active');
            };

            // when clicking the toggle, focus on the input to show the datepicker
            $toggle.on('click', function () {
                $input.trigger('focus');
            });
        }

        this.datePicker = new AirDatepicker(<HTMLInputElement>$input.get(0), adpSettings);
        $input.data('datePickerActive', false);

        $(document).on('click', function (e) {
            const $target = $(e.target);
            // mark datepicker as deactivated if clicked outside of the datepicker input or toggle
            if (!$target.is($input)
                && !($target.closest($toggle).length || $target.is($toggle)) // not toggle or its inner element
            ) {
                $input.data('datePickerActive', false);
            }
        });

        // track active state of the datepicker after focusing the input
        $input.on('focus', function () {
            $input.data('datePickerActive', true);
        });

        // track switching browser tab to keep the datepicker open
        $(document).on('visibilitychange', function () {
            if (document.visibilityState === 'visible' && $input.data('datePickerActive')) {
                $input.trigger('focus');
            }
        });

        $picker.data('datepicker', this);
    }

    public static getInstance($picker: JQuery) {
        return <DatePicker>$picker.data('datepicker');
    }

    public static enablePeriodPicking(startPicker: DatePicker, endPicker: DatePicker) {
        // if there is a value in start date, disallow selection of earlier end date
        if (!startPicker || !endPicker) return;
        const selectedDates = startPicker.datePicker.selectedDates;
        if (selectedDates.length) {
            endPicker.datePicker.update({
                minDate: selectedDates[0],
            });
        }

        startPicker.datePicker.update({
            onSelect: ({ date }: { date: Date | Date[] }) => {
                if (!date) return;
                // disallowing selection of end date before start date:
                let startDate: Date;
                if (Array.isArray(date)) startDate = date[0];
                else startDate = date;

                endPicker.datePicker.update({ minDate: startDate });
                if (!endPicker.getValue()) {
                    // set end date equal to start if end is empty
                    endPicker.datePicker.selectDate(startDate);
                }
                else {
                    // set end date equal to start if end is less than start
                    const endDates = endPicker.datePicker.selectedDates;
                    if (endDates.length) {
                        if (endDates[0].getTime() < startDate.getTime()) {
                            endPicker.datePicker.selectDate(startDate);
                        }
                    }
                }
            },
        });

        endPicker.datePicker.update({
            onShow: () => {
                // set end date equal to start if end is empty
                if (!endPicker.getValue() && startPicker.getValue()) {
                    endPicker.datePicker.selectDate(new Date(startPicker.getValue() || ''));
                }
            },
        });
    }

    public onChangeDate(callback: ({ date }: { date: Date | Date[] }) => void) {
        this.datePicker.update({
            onSelect: (date) => {
                if (this.afterFirstUpdate) {
                    callback(date);
                }
                this.afterFirstUpdate = true;
            }
        });
    }
}

<template>
    <div class="list-table">
        <div class="filter-bar-fixed">
            <div class="filter-bar">
                <div class="table-filter">
                    <div class="jdi-custom-select me-2">
                        <button class="btn exportBtn jdi-green-button" title="Export to PDF" @click.prevent="generatePdf">
                            <i class="fa fa-file-pdf"></i>
                        </button>
                    </div>

                    <div class="jdi-custom-select show-more-link">
                        <template v-if="filterSelected.length === 0">
                            <button class="btn filterBtn jdi-green-button" title="Filter" ref="filterLink" @click.prevent="listSelectOpen = !listSelectOpen">
                                <i class="fa fa-filter"></i>
                            </button>
                        </template>
                        <template v-else>
                            <button class="btn filterBtn jdi-green-button active" title="Filter" ref="filterLink" @click.prevent="listSelectOpen = !listSelectOpen">
                                <i class="fa fa-filter"></i>
                            </button>
                        </template>

                        <ul ref="filterList" class="options-list filter-option-list" v-show="listSelectOpen"
                            v-closable="{exclude: ['filterLink'], handler: 'onClose'}">
                            <li v-for="(list, i) in lists" :key="'list-' + i">
                                <a href="#"
                                   :class="{ 'filter-used': filterSelected.find( item => item.name === list.name ), }"
                                   class="list-item" @click.prevent="addListFilter(list.name, list.colour, list.id)">
                                    {{ list.name }}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <ul class="selected-lists-badges ms-2" v-if="filterSelected.length">
                        <li v-for="(list, index) in filterSelected" :key="'list-' + index">
                            <span class="filter-button" :style="'background: #' + (list && list.colour)">
                                {{ list.name }}
                                <a href="#" class="filter-quit" @click.prevent="deleteListFilter(list.id)">
                                    <i class="fas fa-times"></i>
                                </a>
                            </span>
                        </li>
                    </ul>

                </div>
            </div>
        </div>

        <!-- Actions -->
        <div v-if="tableTitles === 'actions'" class="medmodus-table-container">
            <div class="tab-content" id="actionsTabContent" ref="actionsTabContent">
                <div class="tab-pane fade active-table" :class="{'active show': $store.state.activeTab == 1}" id="activeActions" role="tabpanel"
                     aria-labelledby="activeActions">
                    <div class="empty-data" v-if="paginatedActiveResults.length < 1">
                        <i class="far fa-clipboard"></i>
                        <h3>No actions found</h3>
                        <a href="#addActionModal" class="add-button jdi-green-button" data-bs-toggle="modal">
                            <span class="add-icon-container">
                                <i class="fas fa-plus"></i>
                            </span>New Action
                        </a>
                    </div>
                    <div v-else>
                        <actions-table :table-data="paginatedActiveResults" :is-archived-table="false" ref="actionTable"></actions-table>
                        <div class="load-more" v-if="paginatedActiveResults.length < filteredActiveData.length">
                            <a href="" @click.prevent="loadMore">Load more {{tableTitles}}</a>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade archive-table" :class="{'active show': $store.state.activeTab == 2}" id="archivedActions" role="tabpanel" aria-labelledby="archivedActions" ref="archiveActionsTab">
                    <div class="empty-data" v-if="paginatedArchivedResults.length < 1">
                        <i class="far fa-clipboard"></i>
                        <h3>No actions found</h3>
                    </div>
                    <div v-else>
                        <actions-table :table-data="paginatedArchivedResults" :is-archived-table="true" @editRegister="editRegister" ref="actionTable"></actions-table>
                        <div class="load-more" v-if="paginatedArchivedResults.length < filteredArchivedData.length">
                            <a href="" @click.prevent="loadMore">Load more {{tableTitles}}</a>
                        </div>
                    </div>
                </div>
            </div>

            <add-action-modal :edit-record="editRecord" ref="actionModal"></add-action-modal>
            <view-action-modal ref="viewActionModal"></view-action-modal>
        </div>

        <!-- Projects -->
        <div v-if="tableTitles === 'projects'" class="medmodus-table-container">
            <div class="tab-content" id="projectsTabContent">
                <div class="tab-pane fade" :class="{'active show': $store.state.activeTab == 1}" id="activeProjects" role="tabpanel">
                    <div class="empty-data" v-if="paginatedActiveResults.length < 1">
                        <i class="far fa-clipboard"></i>
                        <h3>
                            No projects found
                        </h3>
                        <a href="#addProjectModal" class="add-button jdi-green-button" data-bs-toggle="modal">
                            <span class="add-icon-container">
                                <i class="fas fa-plus"></i>
                            </span>New Project
                        </a>
                    </div>
                    <div v-else>
                        <projects-table :table-data="paginatedActiveResults" :is-archived-table="false" ref="projectTable"></projects-table>

                        <div class="load-more" v-if="paginatedActiveResults.length < filteredActiveData.length">
                            <a href="" @click.prevent="loadMore">Load more {{tableTitles}}</a>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" :class="{'active show': $store.state.activeTab == 2}" id="archivedProjects" role="tabpanel">
                    <div class="empty-data" v-if="paginatedArchivedResults.length < 1">
                        <i class="far fa-clipboard"></i>
                        <h3>No projects found</h3>
                    </div>
                    <div v-else>
                        <projects-table :table-data="paginatedArchivedResults" :is-archived-table="true" ref="projectTable"></projects-table>
                        <div class="load-more" v-if="paginatedArchivedResults.length < filteredArchivedData.length">
                            <a href="" @click.prevent="loadMore">Load more {{tableTitles}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <add-project-modal :edit-record="editRecord" ref="projectModal"></add-project-modal>
            <view-project-modal ref="viewProjectModal"></view-project-modal>
        </div>

        <!-- Modals-->
        <delete-modal></delete-modal>

        <add-contact-modal ref="contactModal" :cancel="cancelCreationContact"></add-contact-modal>
        <confirmation-modal :title="contactName" message="This person could not be found. Do you want to create a new contact?" :confirm="confirmCreationContact" :cancel="cancelCreationContact" modal-title="Person not found" :visible-close-button="false" ref="confirmationModal"></confirmation-modal>
        <confirmation-modal title="" :message="contactWorkflowMessage" :confirm="closeModal" :visible-cancel-button="false" ref="contactWorkflowModal" modal-title="Person not found"></confirmation-modal>
        <notification v-if="isNotificationDisplayed" message="The record you tried to access could not be found. It may have been deleted, or you might not have access to it."></notification>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { TableItem } from '../Models/TableItem';
import { Order } from '../Models/Order';
import { Pagination } from '../Models/Pagination';
import { BaseEntity } from '../Models/BaseEntity';

import { TableUtils } from '../Utils/TableUtils';

import ReadMore from './ReadMore.vue';
import DeleteModal from './Modals/DeleteModal.vue';
import AddActionModal from './Modals/AddActionModal.vue';
import ViewActionModal from './Modals/ViewActionModal.vue';
import AddProjectModal from './Modals/AddProjectModal.vue';
import UserPicture from './UserPicture/UserPicture.vue';
import ActionsTable from './Tables/ActionsTable.vue';
import ProjectsTable from './Tables/ProjectsTable.vue';
import ConfirmationModal from './Modals/Confirmation/ConfirmationModal.vue';
import AddContactModal from './Modals/AddContactModal.vue';
import Notification from '../Components/Notification/Notification.vue';
import ViewProjectModal from '../Components/Modals/ViewProjectModal.vue';

import { ContactToCreateFromEntity } from '../Models/Contact';

declare const IsCustomMessageEnabled: boolean;
declare const ContactCreationCustomMessage: string;
declare const JdiPortalUrl: string;

@Component({
    name: 'entity-table',
    template: '#entity-table-id',
    components: {
        'read-more': ReadMore,
        'delete-modal': DeleteModal,
        'add-action-modal': AddActionModal,
        'add-project-modal': AddProjectModal,
        'user-picture': UserPicture,
        'actions-table': ActionsTable,
        'projects-table': ProjectsTable,
        'confirmation-modal': ConfirmationModal,
        'add-contact-modal': AddContactModal,
        'notification': Notification,
        'view-action-modal': ViewActionModal,
        'view-project-modal': ViewProjectModal,
    }
})

export default class EntityTable extends Vue {
    @Prop() tableData!: BaseEntity[];
    @Prop() tableTitles!: string;
    @Prop({ default: true }) showListFilters!: boolean;
    @Prop() lists!: any[];

    private order: Order = {
        direction: 'asc',
        type: 'string',
        column: ['title'],
        columnName: '',
    };

    private editRecord: BaseEntity = {} as BaseEntity;
    private isEditing = false;
    private commentsToShow = 1;
    private listSelectOpen = false;
    private listOptionSelected = 'View more lists';
    private filterSelected: TableItem[] = [];
    private isNotificationDisplayed = false;
    private contactName = '';

    private pagination: Pagination = {
        pageNumber: 1,
        pageSize: 5,
    };

    @Watch('$store.state.sortOrder') onSortOrderChanged(newVal: Order, oldVal: Order) {
        if (newVal.id && newVal.id != oldVal.id) {
            this.sortTable(newVal);
        }
    }

    @Watch('$store.state.contactCreationNotification') oncontactCreationNotificationChanged(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            if (IsCustomMessageEnabled) {
                const element = this.$refs && (<Vue>this.$refs.contactWorkflowModal).$el;
                $(element).modal('show');
            }
            this.$store.dispatch('setContactCreationNotification', false);
        }
    }

    @Watch('$store.state.contactToCreateFromEntity') onContactCreationChanged(newVal: ContactToCreateFromEntity, oldVal: ContactToCreateFromEntity) {
        if (newVal && newVal.shouldBeCreated && newVal.shouldBeCreated != oldVal.shouldBeCreated) {
            this.showConfirmation();
        }
    }

    @Watch('$store.state.hasContactBeenCreated') onContactCreatedChanged(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.$store.dispatch('setRefreshContacts', true);

            this.$refs.actionModal && $((<Vue>this.$refs.actionModal).$el).modal('show');
            this.$refs.projectModal && $((<Vue>this.$refs.projectModal).$el).modal('show');
        }
    }

    get contactWorkflowMessage(): string {
        return ContactCreationCustomMessage;
    }

    get tableDataFilteredByList(): TableItem[] {
        if (!this.tableData) {
            return [];
        }
        if (this.filterSelected && this.filterSelected.length > 0) {
            const listIds = this.filterSelected.map((l) => l.id);
            return this.tableData.filter((item) => listIds.indexOf(<string>item.list?.id) != -1);
        }

        return this.tableData;
    }

    get searchAndFilterTableData(): TableItem[] {
        if (this.$store.state.search && this.$store.state.search.length > 0) {
            return this.tableDataFilteredByList.filter((item) => {
                const searchFilter = this.$store.state.search;
                return item.name.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1;
            });
        }
        else {
            return this.tableDataFilteredByList;
        }
    }

    get filteredActiveData(): TableItem[] {
        return this.searchAndFilterTableData.filter((item) => !item.archived);
    }

    get filteredArchivedData(): TableItem[] {
        return this.searchAndFilterTableData.filter((item) => (item.archived));
    }

    get paginatedActiveResults(): TableItem[] {
        return this.filteredActiveData.slice(0, this.pagination.pageNumber * this.pagination.pageSize);
    }
    get paginatedArchivedResults(): TableItem[] {
        return this.filteredArchivedData.slice(0, this.pagination.pageNumber * this.pagination.pageSize);
    }

    onClose(): void {
        this.listSelectOpen = false;
    }


    closeModal() {
        const element = this.$refs && (<Vue>this.$refs.contactWorkflowModal).$el;
        $(element).modal('hide');
    }

    loadMore(): void {
        this.pagination.pageNumber++;
    }

    generatePdf(): void {
        const listIds = this.filterSelected.length > 0 ? this.filterSelected.map((l: TableItem) => l.id) : [];
        const isActions = this.tableTitles === 'actions';
        const url = JdiPortalUrl + (isActions ? 'GenerateActionsPdf' : 'GenerateProjectsPdf');
        const isActive = this.$store.state.activeTab == 1;

        window.open(url + '?isActive=' + isActive + (listIds.length > 0 ? '&ids=' + listIds.join('&ids=') : ''), '_blank');
    }

    showConfirmation(): void {
        this.contactName = this.$store.state.contactToCreateFromEntity ? this.$store.state.contactToCreateFromEntity.name : '';
        const element = this.$refs && (<Vue>this.$refs.confirmationModal).$el;
        $(element).modal('show');
    }

    sortTable(order: Order): void {
        const dir: number = order.direction === 'desc' ? -1 : 1;
        const column: string[] = order.column;
        switch (order.type) {
            case 'string':
                this.tableData.sort((a, b) => TableUtils.sortTableItemsByName(a, column, b, dir));
                this.tableDataFilteredByList.sort((a, b) => TableUtils.sortTableItemsByName(a, column, b, dir));
                break;

            case 'date':
                this.tableData.sort((a, b) => TableUtils.sortTableItemsByDate(a, column, b, dir));
                this.tableDataFilteredByList.sort((a, b) => TableUtils.sortTableItemsByDate(a, column, b, dir));
                break;

            case 'number':
                this.tableData.sort((a, b) => TableUtils.sortTableItemsByNumber(a, column, b, dir));
                this.tableDataFilteredByList.sort((a, b) => TableUtils.sortTableItemsByNumber(a, column, b, dir));
                break;

            default:
        }
        this.order = {
            direction: order.direction,
            type: order.type,
            column: order.column,
            columnName: order.columnName,
        };
    }

    addListToModal(): void {
        if (this.filterSelected.length === 1) {
            this.$store.dispatch('actionSetSelectedListFromFilter', this.filterSelected[0]);
        }
        else {
            this.$store.state.selectedListFromFilter = '';
        }

        this.$store.dispatch('setFilterLists', this.filterSelected);
    }

    confirmCreationContact(): void {
        if (this.$refs) {
            this.$refs.actionModal && $((<Vue>this.$refs.actionModal).$el).modal('hide');
            this.$refs.projectModal && $((<Vue>this.$refs.projectModal).$el).modal('hide');

            $((<Vue>this.$refs.contactModal).$el).modal('show');
        }
    }

    cancelCreationContact(): void {
        this.$store.dispatch('setNewContactCreation', {});
    }

    addListFilter(list: string, color: string, id: string): void {
        const itemInArray: TableItem = this.filterSelected.find((item) => item.id === id) as TableItem;
        if (!itemInArray) {
            this.filterSelected.push({
                name: list,
                colour: color,
                id: id,
            });
        }
        this.$store.dispatch('actionSetSelectedListFromFilter', this.filterSelected);
        this.addListToModal();
        this.sortTable(this.order);
        this.listSelectOpen = false;
    }

    deleteListFilter(id: string): void {
        const removeIndex: number = this.filterSelected.findIndex((item) => item.id == id);
        this.filterSelected.splice(removeIndex, 1);
        this.$store.dispatch('actionSetSelectedListFromFilter', this.filterSelected);
        this.addListToModal();
    }

    open(id: string) {
        const item: BaseEntity = this.tableData.find((t) => t.id == id) as BaseEntity;
        if (item) {
            this.editRecord = item;

            if (this.tableTitles == 'actions') {
                const isArchived = (<ActionsTable>this.$refs.actionTable).open(id);
                if (isArchived) {
                    $((<Vue>this.$refs.viewActionModal).$el).modal('show');
                }
                else {
                    $((<Vue>this.$refs.actionModal).$el).modal('show');
                }
            }
            else {
                const isArchived = (<ProjectsTable>this.$refs.projectTable).open(id);
                if (isArchived) {
                    $((<Vue>this.$refs.viewProjectModal).$el).modal('show');
                }
                else {
                    $((<Vue>this.$refs.projectModal).$el).modal('show');
                }
            }
        }
        else {
            this.isNotificationDisplayed = true;
        }
    }

    editRegister(item: BaseEntity) {
        this.editRecord = item;
    }

    getFilteredList() {
        return this.filterSelected;
    }

    mounted(): void {
        if (this.$store.state.selectedListFromFilter && this.$store.state.selectedListFromFilter.length) {
            this.filterSelected = this.$store.state.selectedListFromFilter;
        }
        document.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                e.preventDefault();
                this.listSelectOpen = false;
            }
        });

        if (this.$route.query.id) {
            this.open(<string>this.$route.query.id);
        }
    }
}
</script>


import { BaseListEntity, BaseListEntityDefault } from './BaseListEntity';
import { AssignType } from './Enums';
import {TableItem} from './TableItem';

export interface BaseEntity extends TableItem {
    ownerId: string;
    ownerName: string;
    createdBy: string;
    createdOn: Date;
    modifiedBy: string;
    modifiedOn: Date;
    ownerType?: AssignType;
    isOwnerImageUploaded?: boolean;
    list?: BaseListEntity;
    targetDate?: string;
    nextStepDate?: string;
}

export const BaseEntityDefault: BaseEntity = {
    id: '',
    name: '',
    ownerId: '',
    ownerName: '',
    createdBy: '',
    createdOn: new Date(),
    modifiedBy: '',
    modifiedOn: new Date(),
    list: BaseListEntityDefault,
};

export interface DeleteRegisterItem {
    registerId: string;
    deleteEndpoint: string;
    index: number;
}

<template>
    <layout-default :page-title="pageTitle" :tabs-in-header="false" :show-search="false">
        <div class="main-table-page">
            <div class="main">
                <div class="ajax-loading" v-if="loading"></div>
                <div v-else class="table-container">
                    <entity-list-table table-titles="action list"
                                       :table-data="actionLists"></entity-list-table>
                </div>
            </div>
        </div>
    </layout-default>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';

import { ActionList } from '../Models/ActionList';

import LayoutDefault from '../Layouts/LayoutDefault.vue';
import EntityListTable from '../Components/EntityListTable.vue';
import { TabNames } from '../Models/Enums';
import ApiRequestor from '../Services/ApiRequestor';

@Component({
    name: 'action-lists',
    template: '#action-lists-id',
    components: {
        'layout-default': LayoutDefault,
        'entity-list-table': EntityListTable,
    },
    async created() {
        await this.$store.commit('setLoading', true);
    }
})

export default class ActionLists extends Vue {
    private pageTitle = 'Action List';
    private actionLists: ActionList[] = [];

    @Watch('$store.state.isRefreshingActionLists') onIsRefreshingChanged(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.getActionLists();
        }
    }

    async getActionLists(): Promise<void> {
        this.$store.dispatch('setLoading', true);

        await ApiRequestor.get('GetOwnerActionLists')
            .then((res) => {
                this.actionLists = res.data;
                this.$store.dispatch('setActionLists', res.data);
                this.$store.dispatch('setRefreshActionLists', false);
                this.$store.dispatch('setEntityLength', res.data.length);
            })
            .catch((e) => {
                console.log(e.message);
                this.$store.dispatch('setRefreshActionLists', false);
            });

        await this.$store.commit('setLoading', false);
    }

    mounted(): void {
        this.getActionLists();
    }

    beforeCreate() {
        this.$store.dispatch('setActiveTab', TabNames.activeList);
    }

    get loading(): boolean {
        return this.$store.state.loading;
    }
}
</script>

<template>
    <div>
        <p>{{formattedString()}}</p>
        <span v-show="text.length > maxChars">
            <a :href="link" id="readmore" v-show="!isReadMore"
               v-on:click="triggerReadMore(true)">{{moreStr}}</a>
            <a :href="link" id="readmore" v-show="isReadMore"
               v-on:click="triggerReadMore(false)">{{lessStr}}</a>
        </span>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'read-more',
    template: '#read-more-id'
})

export default class ReadMore extends Vue {
    @Prop({default: 'read more', type: String}) moreStr!: string;
    @Prop({default: '', type: String}) lessStr!: string;
    @Prop({type: String, required: true}) text!: string;
    @Prop({default:'#', type: String}) link!: string;
    @Prop({default: 100, type: Number}) maxChars!: number;

    private isReadMore = false;

    formattedString(): string {
        if (!this.isReadMore && this.text.length > this.maxChars) {
            return this.text.substring(0, this.maxChars) + '...';
        }
        return this.text;
    }

    triggerReadMore(b: boolean, e?: Event): void {
        if (this.link == '#') {
            e && e.preventDefault();
        }
        if (this.lessStr?.length)
            this.isReadMore = b;
    }
}
</script>

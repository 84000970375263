
export class UserUtils {

    public static userNameAcronym(userName: string): string {
        if (!userName) return '';

        const userNameArray = userName.trim().split(' ');
        let acronym = '';
        if (userNameArray.length > 1) {
            const joinFirstAndLast = [userNameArray[0], userNameArray[userNameArray.length - 1]];
            acronym = [joinFirstAndLast[0][0], joinFirstAndLast[1][0]].join('');
        }
        else {
            acronym = userNameArray[0][0];
        }
        return acronym;
    }
}

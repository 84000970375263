<template>
    <div :class="containerClass" id="notification">
        <div class="alert" :class="'alert-' + variant" v-if="show">
            <button type="button" aria-label="Close" class="btn-close me-2" @click="onDismissClick"></button>
            {{message}}
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
    name: 'notification',
    template: '#notification-id',
})

export default class Notification extends Vue {
    @Prop({ type: String, required: false }) message!: string;
    @Prop({ type: Number, default: 5 }) dismissCount!: number;
    @Prop({ type: String, default: 'danger' }) variant!: string;
    @Prop({ type: String, default: 'notification-container' }) containerClass!: string;

    private secsToDismiss = 5;
    private timeoutId = 0;

    @Watch('secsToDismiss') onDismissChanged(newVal: number) {
        if (this.timeoutId > 0) {
            clearTimeout(this.timeoutId);
        }
        this.onDismissCountDownChanged(newVal);
    }

    onDismissCountDownChanged(newVal: number) {
        this.secsToDismiss = newVal;

        if (newVal > 0) {
            this.timeoutId = setTimeout(() => {
                this.$emit('dismiss-count-down', newVal);
                this.onDismissCountDownChanged(newVal - 1);
            }, 1000);
        }
        else {
            this.$emit('dismissed');
        }
    }

    onDismissClick(event: UIEvent) {
        this.onDismissCountDownChanged(0);
        event.stopPropagation();
    }

    private get show(): boolean {
        return this.secsToDismiss > 0;
    }

    showNotification(dismissTime = 0) {
        this.secsToDismiss = dismissTime == 0 ? this.dismissCount : dismissTime;
    }

    mounted() {
        this.secsToDismiss = this.dismissCount;
        this.onDismissCountDownChanged(this.secsToDismiss);
    }
}
</script>

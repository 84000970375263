
import Vue, { VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';
import { AxiosResponse, AxiosStatic } from "axios";
import { VueSelect } from 'vue-select';
import VueMoment from 'vue-moment';
import Vuelidate from 'vuelidate';

import App from "./App.vue";
import router from "./Router/Index";
import store from "./Store/Index";
import ApiRequestor from "./Services/ApiRequestor";

Vue.config.productionTip = false;


declare const PortalUrl: string;
declare const JdiTenantName: string;
declare const JwtToken: string;
declare const axios: AxiosStatic;

Vue.component('v-select', VueSelect);
Vue.use(VueMoment);
Vue.use(Vuelidate);

let handleOutsideClick: (e: Event) => void;

interface VNodeValue {
    exclude: string[];
    handler: string;
}

Vue.directive('closable', {
    bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode): void {
        handleOutsideClick = (e: Event) => {
            e.stopPropagation();
            const vNodeValue: VNodeValue = binding.value;
            let clickedOnExcludedEl = false;
            vNodeValue.exclude.forEach((refName: string) => {
                if (!clickedOnExcludedEl) {
                    const excludedEl = vnode.context?.$refs[refName] as HTMLAnchorElement;
                    clickedOnExcludedEl = excludedEl && excludedEl.contains(e.target as HTMLElement);
                }
            });
            if (!el.contains(e.target as HTMLElement) && !clickedOnExcludedEl) {
                const context: any = vnode.context;
                context[vNodeValue.handler]();
            }
        };

        document.addEventListener('click', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);
    },

    unbind() {
        document.removeEventListener('click', handleOutsideClick);
        document.removeEventListener('touchstart', handleOutsideClick);
    }
});

if (location.pathname.startsWith('/login')) {
    // this branch is for local development only
    const PortalUrl = "https://localhost:44318";
    const ApiUrl = "https://localhost:7184";

    // parse the login parameters from query string
    const args = new URLSearchParams(location.search).get('args');
    const appArgs = JSON.parse(<string>args);
    if (appArgs == null) {
        alert("Invalid login parameters");
    }
    
    // populate the settings
    const page = {
        JwtToken: appArgs.JwtToken,
        PortalUrl: PortalUrl,
        JdiTenantName: appArgs.SiteName,
        JdiPortalUrl: PortalUrl + "/Customers/" + appArgs.SiteName + "/Jdi/",
        JdiServiceUrl: ApiUrl + "/org/" + appArgs.SiteName + "/",
        AzureStorageUrl: appArgs.AzureStorageUrl,
        CurrentUserId: appArgs.CurrentUserId,
        IsJdiUser: true,
        IsContactsDisabled: appArgs.IsContactsDisabled,
        IsCustomMessageEnabled: appArgs.IsCustomMessageEnabled,
        ContactCreationWorkflowId: appArgs.ContactCreationWorkflow,
        ContactCreationCustomMessage: appArgs.CustomMessage,
    };
    
    // store the settings in cookie
    document.cookie = 'JdiPage=' + JSON.stringify(page) + '; expires=' + new Date(new Date().getTime() + 1000 * 86400 * 30).toUTCString() + '; path=/' + appArgs.SiteName;
    
    // Redirect the client to corresponding tenant URL
    window.location.href = '/' + appArgs.SiteName;
}
else {
    // if URL path does not contain JdiTenantName, show invalid tenant message
    if (!location.pathname.startsWith('/' + JdiTenantName)) {
        const container = document.getElementById('app-container');
        if (container) {
            container.innerHTML = `<h2 class="text-danger">Unauthorized</h2>
                <p>The URL you are requesting does not match JDI application instance you are allowed to access. Please log into JDI again.</p>`;
            container.classList.add('m-3');
        }
    }
    else {
        showHeader();
    }
}

async function showHeader() {
    let response: AxiosResponse | undefined;
    try {
        const jdiServiceUrl = PortalUrl + "/Customers/" + JdiTenantName + "/JdiApi/";
        const method = 'Header';
        response = await axios.get(`${jdiServiceUrl}${method}`, {
            headers: {
                'Authorization': 'Bearer ' + JwtToken
            }
        });
    }
    catch (e: any) {
        console.error(e.message);
        startVueApp();
    }

    const container = document.getElementById('globalHeader');
    if (container && response) {
        let html = response.data;

        // make all links absolute
        html = html.replace(/href="\//g, `href="${PortalUrl}/`);
        html = html.replace(/src="\//g, `src="${PortalUrl}/`);
        html = html.replace(/action="\//g, `action="${PortalUrl}/`);

        container.outerHTML = html;

        // run JS code required by common header
        const script = document.createElement('script');
        script.setAttribute('src', '/js2/main.js');
        script.setAttribute('type', 'text/javascript')
        document.body.appendChild(script);

        // activate logout button
        const logoutButton = document.getElementById('logoutBtn');
        if (logoutButton) {
            logoutButton.addEventListener('click', () => {
                // remove cookie containing JWT token and other page properties
                eraseCookie('JdiPage');
            });
        }

        let widgetResponse: AxiosResponse | undefined;
        try {
            widgetResponse = await ApiRequestor.get('SupportWidgetCode');
            const widgetHtml: string = widgetResponse.data;

            if (widgetHtml) {
                // trim <script> tags from the response
                //widgetHtml = widgetHtml.replace(/<script[^>]*>/gm, '');
                //widgetHtml = widgetHtml.replace(/<\/[\s]*script>/gm, '');

                // append the widget HTML to the body
                const widget = document.createElement('div');
                widget.innerHTML = widgetHtml;
                document.body.appendChild(widget);
                activateScriptTags(widget);

                const els = document.getElementsByClassName('ticketSubmissionWidgetItem');
                if (els.length > 0) {
                    els[0].setAttribute('style', '');
                }
            }
        }
        catch (e: any) {
            console.error(e.message);
        }

        startVueApp();
    }
}

function startVueApp() {
    new Vue({
        router,
        store,
        render: (h) => h(App)
    }).$mount('#app-container');
}

// activate script tags which were recently dynamically created in the given node
function activateScriptTags(node: HTMLElement) {
    if (node.tagName === 'SCRIPT') {
        if (node.parentNode) {
            node.parentNode.replaceChild(nodeScriptClone(node), node);
        }
    }
    else {
        let i = -1;
        const children = node.childNodes;
        while (++i < children.length) {
            activateScriptTags(<HTMLElement>children[i]);
        }
    }

    return node;
}
function nodeScriptClone(node: HTMLElement) {
    const script = document.createElement("script");
    script.text = node.innerHTML;

    let i = -1, attr;
    const attrs = node.attributes;
    while (++i < attrs.length) {
        script.setAttribute((attr = attrs[i]).name, attr.value);
    }
    return script;
}

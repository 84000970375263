<template>
    <div class="people-picker-container" :class="{'new-user': isNewUser}" :title="userTitle" @focusout="handleFocusout">
        <template v-if="isUserSelected">
            <user-picture :user-id="selectedItem.id" :user-name="selectedItem[label]" :is-profile-image-uploaded="selectedItem.isOwnerImageUploaded"
                          :user-type="selectedItem.type" :is-name-visible="false"></user-picture>
        </template>

        <div :class="{'select-list-container': true, 'no-selected-item': !isUserSelected}" ref="selectContainer">
            <v-select class="list-select" v-model="selectedItem" :label="label" taggable :options="getOptions" placeholder="Select"
                      autoscroll ref="select" :filterable="true" @search="fetchOptions" :append-to-body="appendToBody">
                <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                        <label class="add-icon-container" v-if="!isUserSelected">
                            <i class="fas fa-plus"></i>
                        </label>
                        <span v-else></span>
                    </span>
                </template>
                <template v-slot:option="option">
                    <span class="people-picker-icon">
                        <i v-if="option.type == 1" class="fa fa-regular fa-address-card"></i>
                        <i v-if="option.type == 0" class="fa fa-regular fa-user"></i>
                    </span>

                    {{ option[label] }}
                </template>
            </v-select>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { VueSelect } from 'vue-select';
import { ContactToCreateFromEntity } from '../../Models/Contact';
import { ContactWorkflow } from '../../Models/Enums';
import { User } from '../../Models/User';
import UserPicture from '../UserPicture/UserPicture.vue';

declare let IsContactsDisabled: boolean;
declare let ContactCreationWorkflowId: number;

@Component({
    name: 'people-picker',
    template: '#people-picker-id',
    components: {
        'v-select': VueSelect,
        'user-picture': UserPicture,
    }
})

export default class PeoplePicker extends Vue {
    @Prop({ default: (): User[] => [], type: Array }) options!: User[];
    @Prop({ default: () => { }, type: Object }) user!: User;
    @Prop({ default: 'ownerName', type: String }) label!: string;
    @Prop({ default: 'id', type: String }) id!: string;
    @Prop({ default: false, type: Boolean }) appendToBody!: boolean;
    @Prop({ default: false, type: Boolean}) ignoreContactWorkflow!: boolean;


    private selectedItem: User = {} as User;
    private userTitle = '';
    private isNewUser = false;
    private autoscroll = true;
    private text = '';
    private prevText = ''; // workaround to handle focus out.

    @Watch('selectedItem') onSelectItemChanged(newVal: User, oldVal: User) {
        this.$store.dispatch('setSelectedResponsibleUser', newVal);
        this.$emit('change-model', newVal);
    }

    @Watch('user') onUserChanged(newVal: User, oldVal: User) {
        if (newVal == null && (this.selectedItem == null || Object.keys(this.selectedItem).length != 0)) {
            this.selectedItem = {} as User;
        }
        else if (this.selectedItem && newVal && this.selectedItem.id != newVal.id) {
            this.selectedItem = this.options.find((u: User) => u.id == newVal.id) as User;
        }
    }
    get isUserSelected(): boolean {
        return <boolean>(this.selectedItem && this.selectedItem.id && this.selectedItem.id.length > 0);
    }

    get getOptions(): SelectOption[] {
        return this.options.filter((c: User) => !c.isArchived || (this.user != null && c.id == this.user.id)).sort((a, b) => {
            if ((a as any)[this.label].toLowerCase() < (b as any)[this.label].toLowerCase()) {
                return -1;
            }
            else if ((a as any)[this.label].toLowerCase() > (b as any)[this.label].toLowerCase()) {
                return 1;
            }
            return 0;
        });
    }

    fetchOptions(text: string): SelectOption[] {
        const options = this.options.filter((t) => t.ownerName.toLocaleLowerCase().includes(text.toLocaleLowerCase()));
        const $container = $(<Vue>this.$refs.selectContainer);
        if (text.length > 0 && this.selectedItem && this.selectedItem.id && this.selectedItem.ownerName.indexOf(this.text) == -1) {
            this.selectedItem = {} as User;
        }
        // workaround to save text because on blur the component clears the value and we do not have it to pass forward.
        if (text.length > 0 || this.prevText.length == 0) {
            this.text = text;
        }
        this.prevText = text;
        if (options.length == 0) {
            if (!$container.hasClass('no-items')) {
                $container.addClass('no-items');
            }
        }
        else {
            $container.removeClass('no-items');
        }
        return options;
    }

    beforeUpdate() {
        if (this.user) {
            this.selectedItem = this.user;
        }
    }

    selectUser(user: User) {
        this.selectedItem = user;
    }

    handleFocusout() {
        if (this.selectedItem && this.selectedItem.id) {
            return;
        }

        if (!this.ignoreContactWorkflow) {
            if (IsContactsDisabled || ContactCreationWorkflowId == ContactWorkflow.disabled) {
                this.$store.dispatch('setContactCreationNotification', true);
                return;
            }
        }

        if (this.text.length > 0) {
            const modal = $(<Element>this.$refs.selectContainer).closest('.modal').get(0);
            if (bootstrap.Modal.getInstance(modal)?._isShown) {
                this.$store.dispatch('setNewContactCreation', { shouldBeCreated: true, name: this.text } as ContactToCreateFromEntity);
            }
        }
    }
}

export type SelectOption = string | object;
</script>

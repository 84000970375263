
export default {
    methods: {
        onEnter: function ($event: any, selector: string) {
            if ($event.shiftKey === true && $event.key === 'Enter') {
                return;
            }

            $event.preventDefault();
            const $e = $($event.currentTarget);
            if (selector && $(selector).length == 1) {
                $(selector).focus();
            }
            else {
                const $elements = $('input, button, textarea, select');
                const currentIndex: number = $elements.index($e);
                $($elements[currentIndex + 1]).focus();
            }
        },

        focusOnClick: function ($event: any, selector: string) {
            $event.preventDefault();
            const $e = $($event.currentTarget);
            const $target = $($event.target);

            if (!selector) {
                selector = 'input, textarea, select';
            }

            if (!$target.is(selector)) return;

            if (selector.includes($e.prop('nodeName').toLowerCase())) {
                return;
            }

            const $elementToFocus = $($e.find(selector)[0]);
            $elementToFocus.focus();
        }
    }
};


import { Vue } from 'vue-property-decorator';
import { Order } from '../../Models/Order';
import { BaseEntity } from '../../Models/BaseEntity';
import { DirectionReference } from '../../Models/Enums';

export default class BaseTable extends Vue {
    protected editRecord: BaseEntity = {} as BaseEntity;

    public order: Order = {
        direction: 'asc',
        type: 'string',
        column: ['title'],
        columnName: '',
    };

    truncate(str: string): string {
        if (!str) return '';

        if (str.length > 8) {
            return str.substring(0, 8) + '...';
        }
        return str;
    }

    editRegister(items: BaseEntity[], id: string): void {
        this.editRecord = this.getRegister(items, id);
        this.$store.dispatch('setEditRegisterItem', this.editRecord);
        this.$store.dispatch('setEdit', true);
    }

    viewRegister(items: BaseEntity[], id: string): void {
        const viewRecord = this.getRegister(items, id);
        this.$store.dispatch('setViewRegiterItem', viewRecord);
    }

    getRegister(items: BaseEntity[], id: string): BaseEntity {
        const result = items.find((item) => item.id == id) as BaseEntity;
        if (result && result.targetDate) {
            result.targetDate = result.targetDate.split('T')[0];
        }
        if (result && result.nextStepDate) {
            result.nextStepDate = result.nextStepDate.split('T')[0];
        }
        return result;
    }


    sortTable(column: string[], type: string, columnName: string, staticDirection: string): void {
        const sameColumnName = this.order.columnName === columnName;
        const currentDirection = this.order.direction as keyof typeof DirectionReference;
        const direction: string = staticDirection?.length ?
            staticDirection :
            sameColumnName ? DirectionReference[currentDirection] : 'asc';

        this.order = {
            direction: direction ? direction : 'asc',
            type,
            column,
            columnName,
            id: (Math.random() + 1).toString(36).substring(7)
        };

        this.$store.dispatch('setSortOrder', this.order);
    }

    changeUrl(path: string, id: string) {
        if (!this.$route.query.id || id != this.$route.query.id) {
            this.$router.push({ path: path, query: { id } });
        }
    }
}
